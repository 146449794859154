<template>
  <div>
    <loader-simple :flag="flag.loader" v-if="flag.loader"/>
    <div v-if="!flag.loader">
      <contents-box>
        <div :class="$style.graph_title">継続</div>
        <div v-if="!flag.notFound">
          <div v-if="countAnalytics" :class="$style.row">
              <Line
                :chart-options="chartOptions"
                :chart-data="countAnalytics"
                chart-id="line-text-chart"
                css-classes="graphStyle"
                width="400"
                height="200"
                :class="$style['col-9']"
              />
            </div>
        </div>
        <spacer :y="2"/>
        <div :class="$style.graph_title">間隔</div>
        <div
          :class="[$style.row, $style.count_graph]" v-if="!flag.notFound">
          <div v-if="intervalAnalytics && intervalAnalytics.totalData">
            <max-bar-graph
              :datasets-data="intervalAnalytics"
              :labels-data="countAnalytics.labels"
              :class="$style['col-9']" />
          </div>
          <div>
            <spacer :y="4" />
            <div v-if="intervalAnalytics && intervalAnalytics.data"
              :class="$style.interval_table_outer">
              <table border="1" :class="$style.interval_table" v-if="intervalAnalytics.data">
                <tr v-for="week, j of intervalAnalytics.maxWeek + 1"
                :key="week">
                  <td
                    v-for="(countWeekData, i) of intervalAnalytics.data"
                    v-if="intervalAnalytics.maxWeek === j"
                    :key="countWeekData"
                    :class="Boolean(typeof countWeekData[week - 1] === 'string') && Boolean(countWeekData[week - 1].indexOf('w') >= 0) ? $style.interval_table_sticky : $style.interval_table_relative"
                    :style="(week !== intervalAnalytics.maxWeek + 1) && (i !== 0) && (countWeekData[week - 1] !== null) ? `background-color: rgba(26, 115, 232, ${(countWeekData[week - 1] / 25) + 0.08})` : ''">
                    <div v-if="(typeof countWeekData[week - 1]) === 'string'" :class="countWeekData[week - 1].indexOf('w') >= 0 ? $style.sticky_flame : ''">{{ countWeekData[week - 1] }}</div>
                    <div v-else
                      :class="$style.hide_column"
                      v-on:mouseover="toggleShow(j, i)"
                      v-on:mouseleave="toggleHide(j, i)">　
                      <div v-if="flag.showFlag
                        && flag.showFlag[j]
                        && flag.showFlag[j][i]
                        && countWeekData[week - 1] !== null"
                        :class="(intervalAnalytics.data.length - (i + 2) > 0) ? $style.balloon : $style.balloon_right_alg">
                        ココモニチェック(ave):{{ countWeekData[week - 1] }}
                      </div>
                    </div>
                    </td>
                </tr>
                <tr v-if="countData && countData.text">
                  <td :class="$style.interval_table_wide">相談件数</td>
                  <td
                    v-for="index of countData.text.length"
                    :key="index"
                    :class="$style.data_cel">
                    {{ countData.text[index - 1] + countData.online[index - 1] }}
                  </td>
                </tr>
                <tr v-if="targetRange === 'common'">
                  <td :class="$style.interval_table_wide">テキスト</td>
                  <td
                    v-for="index of countData.text.length"
                    :key="index">
                    {{ countData.text[index - 1] || 0 }}
                  </td>
                </tr>
                <tr v-if="targetRange === 'common'">
                  <td :class="$style.interval_table_wide">オンライン</td>
                  <td
                    v-for="index of countData.text.length"
                    :key="index">
                    {{ countData.online[index - 1] || 0 }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <spacer :y="2" />
          <div :class="$style['col-3']">
            <div>
              <p :class="$style.filter_title">グラフタイプ</p>
              <div :class="$style.filter_contents">
                <ul :class="$style.btns">
                  <li
                    v-for="row in targetTypes"
                    :key="row.name">
                    <input
                      type="radio"
                      name="graph-type"
                      :id="row.name"
                      :value="row.name"
                      v-model="targetType"
                      :checked="targetType === row.name"
                      @change="getAnalyticsCount">
                    <label
                      :for="row.name"
                      :class="$style.btn">{{ row.label }}</label>
                  </li>
                </ul>
              </div>
            </div>

            <div :class="$style.filter_flex">
              <div>
                <p :class="$style.filter_title">範囲</p>
                <div :class="$style.filter_contents">
                  <select
                    :class="$style.select_box"
                    name="targetRange"
                    id="targetRange"
                    v-model="targetRange"
                    @change="getAnalyticsCount">
                    <option
                      v-for="row in targetRanges"
                      :key="row.name"
                      :value="row.name"
                      :selected="targetRange === row.name">{{ row.label }}</option>
                  </select>
                </div>
              </div>

              <spacer :x="2" />
              <div>
                <p :class="$style.filter_title">学校・企業</p>
                <div :class="$style.filter_contents">
                  <select
                    :class="$style.select_box"
                    name="schoolFilter"
                    id="schoolFilter"
                    v-model="targetSchool"
                    @change="getAnalyticsCount">
                    <option
                      :value="0"
                      :selected="targetSchool === 0">すべて</option>
                    <option
                      v-for="school of filters.schools"
                      :key="school"
                      :selected="school.id === targetSchool">{{ school.name }}</option>
                  </select>
                </div>
              </div>

              <spacer v-if="targetType === 'customer' && (targetSchool && filters.users)" :x="2" />
              <div v-if="targetType === 'customer'
                && (targetSchool && filters.users)">
                <p :class="$style.filter_title">利用者</p>
                <div :class="$style.filter_contents">
                  <select
                    :class="$style.select_box"
                    name="userFilter"
                    id="userFilter"
                    v-model="targetUser"
                    @change="getAnalyticsCount">
                    <option
                      :value="0"
                      :selected="targetUser === 0">すべて</option>
                    <option
                      v-for="user of filters.users"
                      :key="user"
                      :selected="user === targetUser">{{ user }}</option>
                  </select>
                </div>
              </div>

              <spacer :x="2" />
              <div>
                <p :class="$style.filter_title">回数</p>
                <div :class="$style.filter_contents">
                  <select
                    :class="$style.select_box"
                    name="countFilter"
                    id="countFilter"
                    v-model="targetCount"
                    @change="getAnalyticsCount">
                    <option
                      :value="0"
                      :selected="targetCount === 0">すべて</option>
                    <option
                      v-show="count >= 2"
                      v-for="count of filters.counts"
                      :key="count"
                      :selected="count === targetCount">{{ count }}</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
        </div>
        <p v-else>表示できるデータが有りませんでした。</p>
      </contents-box>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import MaxBarGraph from '@/views/components/MaxBarGraph.vue';
import { Line } from 'vue-chartjs';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { cloneDeep } from 'lodash';
import Spacer from '@/views/components/Spacer.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, annotationPlugin);

export default {
  name: 'OperatorAnalyticsGraph',
  components: {
    LoaderSimple,
    Line,
    ContentsBox,
    MaxBarGraph,
    Spacer,
  },
  props: {
    backendData: {
      type: Object,
    },
    allFilter: {
      type: Object,
    },
    schools: {
      type: Object,
    },
  },
  data() {
    return {
      countAnalytics: null,
      intervalAnalytics: null,
      countData: null,
      targetCommonData: null,
      allTarget: null,
      flag: {
        loader: false,
        notFound: false,
        initial: true,
        showFlag: [],
      },
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            scaleLabel: {
              display: true,
              labelString: '回数',
            },
            ticks: {
              stepSize: 1,
            },
          },
          y: {
            max: 30, // who-5の最大値
            min: 0,
            scaleLabel: {
              display: true,
              labelString: 'WHO5スコア',
              fontColor: 'gray',
              fontSize: 16,
            },
            grid: {
              drawBorder: false,
              display: true,
              tickColor: 'rgba(255, 255, 255, 0)',
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: () => null,
              label: (item) => {
                let string = '';
                if (this.targetType === 'average') {
                  // 平均値
                  string = [
                    ` ${item.raw.meta.type}`,
                    `平均スコア: ${item.raw.meta.score}`,
                    `相談件数: ${item.raw.meta.total}`,
                  ];
                } else if (this.targetType === 'customer') {
                  // 利用者別
                  string = [
                    ` ユーザID: ${item.raw.meta.user_id}`,
                    `スコア: ${item.raw.meta.score}`,
                    `相談日: ${item.raw.meta.created_at}`,
                  ];
                }
                return string;
              },
            },
          },
          legend: {
            display: false,
          },
        },
      },
      ctx: null,
      annotationHover: false,
      userIds: [],
      targetUser: 0,
      targetSchool: 0,
      targetCount: 0,
      targetType: 'average',
      targetTypes: [
        { name: 'average', label: '平均値' },
        { name: 'customer', label: '利用者別' },
      ],
      targetRange: 'common',
      targetRanges: [
        { name: 'common', label: 'オンライン＋テキスト' },
        { name: 'text', label: 'テキスト' },
        { name: 'reserve', label: 'オンライン' },
      ],
      filters: {
        schools: [],
        users: [],
        counts: 0,
      },
      graphSettings: {
        baseColor: {
          r: '26',
          g: '115',
          b: '232',
        },
        types: {
          common: {
            label: 'オンライン＋テキスト',
            averageColorAlpha: 1,
          },
          reserve: {
            label: 'オンライン',
            averageColorAlpha: 0.65,
          },
          text: {
            label: 'テキスト',
            averageColorAlpha: 0.3,
          },
        },
      },
    };
  },
  async created() {
    this.readParameter();
    this.getAnalyticsCount();
  },
  watch: {
    backendData: {
      handler() {
        this.getAnalyticsCount();
      },
      deep: true,
    },
    // グラフタイプ変更時のリセット
    targetType: {
      handler(after, before) {
        if (after !== before) {
          if (!this.flag.initial) {
            this.targetSchool = 0;
            this.targetUser = 0;
            this.targetCount = 0;
            this.targetRange = 'common';
          }
          const query = { ...this.$route.query };
          query.targetRange = this.targetRange;
          query.targetType = after;
          this.$router.push({ query });
          this.readParameter();
          if (this.flag.initial) {
            this.flag.initial = false;
          }
        }
      },
      deep: true,
    },
    schools: {
      handler() {
        this.readParameter();
        this.filters.schools = this.schools;
      },
      deep: true,
    },
    // 利用者別「範囲」変更時のリセット
    targetRange: {
      handler(after, before) {
        if (after !== before) {
          this.targetSchool = 0;
          this.targetUser = 0;
          this.targetCount = 0;
          const targetRange = this.targetRange;
          const query = { ...this.$route.query };
          query.targetRange = targetRange;
          this.$router.push({ query });
          this.readParameter();
        }
      },
      deep: true,
    },
    targetSchool: {
      handler(newVal) {
        if (newVal !== 0) {
          const query = { ...this.$route.query };
          this.schools.forEach((row) => {
            if (row.name === this.targetSchool) {
              query.targetSchool = row.id;
              this.$router.push({ query });
            }
          });
        } else if (newVal === 0) {
          const query = { ...this.$route.query };
          if (query.targetSchool) {
            delete query.targetSchool;
            this.$router.push({ query });
          }
        }
      },
      deep: true,
    },
    targetCount: {
      handler(newVal) {
        if (newVal !== 0) {
          const targetCount = newVal;
          const query = { ...this.$route.query };
          if (query.targetCount !== targetCount) {
            query.targetCount = targetCount;
            this.$router.push({ query });
          }
        } else {
          const query = { ...this.$route.query };
          if (query.targetCount) {
            delete query.targetCount;
            this.$router.push({ query });
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['helper']),
  },
  methods: {

    // 回数別アナリティクスデータの取得
    getAnalyticsCount() {
      this.flag.initial = false;
      this.readParameter();
      // 特定の利用者で絞り込んだ時にIDを取得
      let targetUser;
      let targetSchool;
      let targetSchoolID;
      let targetRange;
      if (this.targetUser) targetUser = this.targetUser.substring(7);
      if (this.targetSchool) {
        targetSchool = this.schools.find((data) => data.name === this.targetSchool);
        targetSchoolID = targetSchool.id;
      }
      if (this.targetRange === 'reserve') { //　表記をbackendではonlineで統一
        targetRange = 'online';
      } else {
        targetRange = this.targetRange;
      }

      const params = {
        start: this.allFilter.start,
        end: this.allFilter.end,
        account_types: this.allFilter.account_types,
        school_types: this.allFilter.school_types,
        targetSchool: this.allFilter.targetSchool,
        targetArea: this.allFilter.targetArea,
        graphType: this.targetType,
        graphRange: targetRange,
        graphCount: this.targetCount,
        countTargetSchool: targetSchoolID,
        targetUser,
      };

      this.axios({
        method: 'GET',
        url: '/v1/analytics/get/countData',
        params,
      })
        .then((response) => {
          this.countAnalytics = response.data.data;
          this.intervalAnalytics = response.data.intervalData;
          this.countData = response.data.countData;
          this.filters.counts = response.data.data.labels.length;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /**
     * 平均値
     *   学校
     *   回数
     *
     * 利用者別
     *   範囲（すべて・オンライン・テキスト）
     *   学校
     *     利用者
     *   回数
     */
    readParameter() {
      // 現在のクエリを取得する
      const query = this.$route.query;
      if (query.tab === 'analyticsselect') {
        if (query.targetType && this.flag.initial) {
          this.targetType = query.targetType;
        }
        if (query.targetRange && this.flag.initial) {
          this.targetRange = query.targetRange;
        }
        if (query.targetSchool) {
          const schoolQuery = Number(query.targetSchool);
          if (schoolQuery && this.schools) {
            this.schools.forEach((row) => {
              if (row.id === schoolQuery) {
                this.targetSchool = row.name;
              }
            });
          }
        }
        if (query.targetCount && this.flag.initial) {
          const countQuery = Number(query.targetCount);
          this.targetCount = countQuery;
        }
      }
    },
    initialize() {
      this.getAnalyticsCount();
      this.flag.loader = true;
      this.chartData = { labels: [], datasets: [] };

      let max = 1;
      if (this.targetType === 'average') {
        // 平均値の計算
        const averageValues = this.getAverageValues();
        // 取得した平均値からグラフを描画
        const types = Object.keys(averageValues);

        types.forEach((type) => {
          const data = [];
          const values = averageValues[type];
          const counts = Object.keys(values);
          if (counts.length > max) max = counts.length;
          counts.forEach((count) => {
            const average = values[count].average;
            data.push({
              x: Number(count),
              y: average,
              meta: {
                type: this.graphSettings.types[type].label,
                score: average,
                total: values[count].total,
              },
            });
          });
          const baseColor = this.graphSettings.baseColor;
          const color = `${baseColor.r}, ${baseColor.g}, ${baseColor.b}`;
          this.chartData.datasets.push({
            borderColor: `rgba(${color}, ${this.graphSettings.types[type].averageColorAlpha})`,
            borderWidth: 3,
            radius: 2,
            data,
          });
        });
      } else if (this.targetType === 'customer') {
        const customerValues = this.getCustomerValues();

        const keys = Object.keys(customerValues);
        const length = keys.length;
        keys.forEach((id, i) => {
          if (customerValues[id].length > max) max = customerValues[id].length;
          const data = customerValues[id];
          const rgb = this.generateRGB(length, i);
          this.chartData.datasets.push({
            borderColor: rgb,
            borderWidth: 3,
            radius: 2,
            data,
          });
          // 凡例用にIDとカラーを保存
          this.userIds.push({
            id: customerValues[id][0].meta.user_name,
            color: rgb,
          });
        });
      }

      this.chartData.labels = [...Array(max)].map((_, i) => i + 1);
      this.flag.loader = false;
    },

    /**
     * 各タイプの平均値取得
     */
    getAverageValues() {
      const ls = cf.getLocalStorage('wtt');
      const original = cloneDeep(ls.analytics);

      // 選択可能な学校の洗い出し
      this.setFilterdSchools(original.common);

      const types = Object.keys(original);
      // 回数毎のデータに形成しなおす
      const countValues = {};
      types.forEach((type) => {
        countValues[type] = {};
        const data = original[type];
        const ids = Object.keys(data);
        ids.forEach((id) => {
          const values = data[id];
          values.forEach((value) => {
            if (!countValues[type][value.x]) countValues[type][value.x] = [];
            // 学校で絞り込み（対象の学校でなければ除外）
            if (this.targetSchool && value.meta.school !== this.targetSchool) return false;
            // 回数で絞り込み（除外処理）
            if (Number(this.targetCount) && value.x > this.targetCount) return false;
            countValues[type][value.x].push(value.y);
          });
        });
      });

      // 回数毎の平均値を計算して返却
      const result = {};
      types.forEach((type) => {
        result[type] = {};
        const data = countValues[type];
        const counts = Object.keys(data);
        counts.forEach((count) => {
          const values = data[count];
          if (values.length) {
            const average = values.reduce((a, b) => a + b) / values.length;
            // 平均値は小数点第三位を四捨五入して返却
            result[type][count] = {
              total: values.length,
              average: cf.methods.roundN(average, 2),
            };
          }
        });
      });

      return result;
    },

    /**
     * 利用者別のスコア形成
     */
    getCustomerValues() {
      const ls = cf.getLocalStorage('wtt');
      const original = cloneDeep(ls.analytics);
      const datas = original[this.targetRange];

      // 選択可能な学校の洗い出し
      this.setFilterdSchools(datas);
      // 選択可能な（学校に紐づく）利用者
      if (this.targetSchool) this.setFilteredUsers(datas);

      const result = {};
      const ids = Object.keys(datas);
      ids.forEach((id) => {
        result[id] = [];
        const values = datas[id];
        values.forEach((value) => {
          // 学校で絞り込み（対象の学校でなければ除外）
          if (this.targetSchool && value.meta.school !== this.targetSchool) return false;
          // ユーザーで絞り込み（除外処理）
          if (this.targetUser && value.meta.user_id !== this.targetUser) return false;
          // 回数で絞り込み（除外処理）
          if (Number(this.targetCount) && value.x > this.targetCount) return false;

          result[id].push(value);
        });
      });

      // ソート後に空配列のidは削除
      ids.forEach((id) => {
        const values = result[id];
        if (!values.length) delete result[id];
      });

      return result;
    },


    // 選択項目の学校
    setFilterdSchools(datas) {
      const schools = [];
      const ids = Object.keys(datas);
      ids.forEach((id) => {
        const values = datas[id];
        values.forEach((value) => {
          if (!schools.includes(value.meta.school)) {
            schools.push(value.meta.school);
          }
        });
      });
      this.filters.schools = schools;
    },

    // 選択した学校に紐づくユーザ配列
    setFilteredUsers(datas) {
      const users = [];
      const ids = Object.keys(datas);
      ids.forEach((id) => {
        const values = datas[id];
        values.forEach((value) => {
          if ((value.meta.school === this.targetSchool)
            && !users.includes(value.meta.user_id)) {
            users.push(value.meta.user_id);
          }
        });
      });
      this.filters.users = users;
    },


    /**
     * 利用者別の折れ線色生成
     */
    generateRGB(length, index) {
      // const r = Math.floor(Math.random() * 256);
      // const g = Math.floor(Math.random() * 256);
      // const b = Math.floor(Math.random() * 256);
      // return `rgb(${r}, ${g}, ${b})`;
      const baseColor = this.graphSettings.baseColor;
      const min = 3;
      const max = 10;
      const diff = max - min;
      const unit = diff / length;
      const value = (unit * (index + 1) + min);
      const alpha = value / 10;
      return `rgba(${baseColor.r}, ${baseColor.g}, ${baseColor.b}, ${alpha})`;
    },

    toggleShow(j, i) {
      this.flag.showFlag.length = j;
      this.flag.showFlag[j] = [];
      this.flag.showFlag[j].length = i;
      this.flag.showFlag[j][i] = true;
    },
    toggleHide(j, i) {
      this.flag.showFlag[j][i] = false;
    },
  },
};
</script>

<style lang="scss" module>
.graph_title {
  padding: 3px;
  font-size: 1.1em;
  font-weight: 500;
}
.guide {
  display: block;
  // text-align: right;
  float: right;
  >div {
    display: flex;
  }
  &_who5 {
    display: inline;
  }
  &_who5:before {
    font-size: 1em;
    content: '●';
    position: relative;
    left: -5px;
  }
}
.filter {
  &_container {
    // display: flex;
    // @include md-view {
    //   display: block;
    // }
  }
  &_title {
    padding-top: 8px;
  }
}
.select {
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
  &_elem {
    margin-left: 10px;
  }
  &_box {
    max-width: 220px;
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.btns {
  display: flex;
  // width: 102%;
  width: 100%;
  li {
    margin-right: 1em;
  }
  input[type=radio] {
    display: none;
    &:checked {
      + label {
        background-color: var(--orange-main);
      }
    }
  }
}

.polygonal {
  width: 85%;
  @media screen and (max-width: 1420px) {
    max-width: 470px;
  }
  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
}

.btn {
  padding: 6px 20px;
  font-size: 14px;
  text-align: center;
  border: none;
  background-color: #ababab;
  font-weight: bold;
  color: #fff;
  transition: 0.4s all;
  display: inline-block;
  border-radius: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  &:not(:disabled) {
    &:hover {
      background-color: var(--orange-main);
    }
  }
  &:disabled {
    opacity: .5;
    cursor: default;
  }
}

.row {
  // display: flex;
  margin-left:  - 1em;
  width: 100%;
    .col {
      flex: 1;
      @for $num from 1 through 12 {
        &-#{$num} {
          flex: $num;
          padding: 0 1em;
        }
      }
    }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    .col {
      max-width: 100%;
      @for $num from 1 through 12 {
        &-#{$num} {
          flex: 1;
          padding: 1em 0;
        }
      }
    }
  }
}
.count_graph {
  padding-left: .5em;
}

.filter_flex {
  display: flex;
}

.interval_table {
  // table-layout: fixed;
  // width: 100%;
  white-space: pre;
  border: 1px solid var(--gray);
  border-collapse: collapse;
  text-align: center;
  &_outer {
    overflow: scroll;
  }
  &_wide {
    width: 100px;
  }
  &_sticky {
    position: sticky;
    background-color: white;
    left: 0;
  }
  &_relative {
    width: 50px;
  }
}
.data_cel {
  width: 60px;
}

.sticky_flame{
  margin: -1px;
  border:  1px solid silver;
}

.hide_column {
  position: relative;
  width: 50px;
}

.balloon {
  position: absolute;
  border-radius: 5px;
  text-align: left;
  width: 150px;
  padding: 5px;
  background-color: #DDDDDD;
  &_right_alg {
    position: absolute;
    left: -100px;
    border-radius: 5px;
    text-align: left;
    width: 150px;
    padding: 5px;
    background-color: #DDDDDD;
  }
}

.balloon:before {
  content: "";
  position: absolute;
  top: -20px;
  left: 10%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-bottom: 15px solid #DDDDDD;
}

.balloon_right_alg::before{
  content: "";
  position: absolute;
  top: -20px;
  left: 90%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-bottom: 15px solid #DDDDDD;
}
</style>
