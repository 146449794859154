<template>
  <div>
    <h2>モニタリングスケジュール（{{ data.school.name }}）</h2>
    <spacer :y="3"/>
    <div>
      <calendar
        :key="calendar.key"
        :information="true"
        :schedule="calendar.schedule"
        :selectableSunSat="true"
        :selectableHoliday="true"
        :initialDate="calendar.initialDate"
        :specifiedMinDate="new Date()"
        mode="range"
        columns="2"
        :optionalAttrs="optionalAttrs"
        :specifiedMaxDate="specifiedMaxDate"
        v-on:sendRange="setRange"
      />
    </div>

    <div>
      <spacer :y="3"/>
      <ul :class="$style.detail">
        <template v-if="baseDate.startDate">
          <li>
            <label-box
              label="モニタリング期間"
              color="gray"
              size="sm"
              :class="$style.label"
            />

            <div>
              <div :class="$style.detail_div">
                <!-- 日付は上部の大カレンダーから指定してもらう -->
                <!-- <date-small-picker
                  size="sm"
                  v-model="baseDate.startDate"
                  :minDate="now"
                  :maxDate="specifiedMaxDate"
                /> -->
                <p>{{ formatDate(baseDate.startDate)  }}</p>
                <select
                  :class="$style.select"
                  v-model="baseDate.startTime"
                >
                  <option
                    v-for="hour in SELECTABLE_TIME.HOURS"
                    :key="hour"
                    :value="hour"
                  >{{ hour }}</option>
                </select>
                <label :class="$style.dateSeparator">〜</label>
                <!-- 日付は上部の大カレンダーから指定してもらう -->
                <!-- <date-small-picker
                  size="sm"
                  v-model="baseDate.endDate"
                  :minDate="now"
                  :maxDate="specifiedMaxDate"
                /> -->
                <p>{{ formatDate(baseDate.endDate)  }}</p>
                <select
                  :class="$style.select"
                  v-model="baseDate.endTime"
                >
                  <option
                    v-for="hour in SELECTABLE_TIME.HOURS"
                    :key="hour"
                    :value="hour"
                  >{{ hour }}</option>
                </select>
              </div>
              <p
                v-if="pickList.length === 0 && invalid.reversedIndexes.length > 0"
                :class="$style.alert_text"
              >{{ invalid.alertText.reversed }}</p>
              <p
                v-if="pickList.length === 0 && invalid.startBeforeNow.length > 0"
                :class="$style.alert_text"
              >{{ invalid.alertText.startBeforeNow }}</p>
              <!-- <p
                v-if="pickList.length === 0 && invalid.differentMonthIndexes.length > 0"
                :class="$style.alert_text"
              >{{ invalid.alertText.differentMonth }}</p> -->
              <p
                v-if="pickList.length === 0 && invalid.existIndexes.length > 0"
                :class="$style.alert_text"
                >{{ invalid.alertText.exists }}</p>
              <p
                v-if="pickList.length === 0 && invalid.duplicatedMonthIndexes.length > 0"
                :class="$style.alert_text"
                >{{ invalid.alertText.duplicatedMonth }}</p>
            </div>
          </li>
          <li>
            <label-box
              label="繰り返し間隔"
              color="gray"
              size="sm"
              :class="$style.label"
            />
            <div
              v-for="(val, key) in CYCLES_DEF"
              :key="key"
            >
              <input
                type="radio"
                name="monitoring_cycle"
                :id="`monitoring_cycle_${key}`"
                :value="key"
                v-model="cycleValue"
              >
              <label :for="`monitoring_cycle_${key}`">{{ val.label }}</label>
            </div>
          </li>

          <li v-if="cycleValue > 0">
            <label-box
              label="繰り返し終了日"
              color="gray"
              size="sm"
              :class="$style.label"
            />
            <date-small-picker
              size="sm"
              v-model="cycleEndDate"
              :minDate="now"
              :maxDate="specifiedMaxDate"
            />
          </li>

          <li v-if="isSpotPlan">
            <label-box
              label="種別"
              color="gray"
              size="sm"
              :class="$style.label"
            />
            <div>
              <select
                :class="$style.select"
                v-model="targetAccountTypes"
              >
                <option
                  v-for="option in accountTypeOptions"
                  :key="option.key"
                  :value="option.key"
                >{{ option.label }}</option>
              </select>
            </div>
          </li>

          <spacer
            v-if="pickList.length > 0"
            :y="3"
          />
          <li v-if="pickList.length > 0">
            <label-box
              label="登録対象期間"
              color="gray"
              size="sm"
              :class="$style.label"
            />
            <div>
              <div
                v-for="(pickDetail, i) in pickList"
                :key="i"
              >
                <ul :class="$style.picklist">
                  <li>
                    <label-box
                      :label="i + 1"
                      color="gray"
                      size="sm"
                      :class="$style.label"
                    />
                    <div>
                      <div :class="$style.detail_div">
                        <date-small-picker
                          size="sm"
                          v-model="pickDetail.startDate"
                          :minDate="now"
                          :maxDate="specifiedMaxDate"
                        />
                        <select
                          :class="$style.select"
                          v-model="pickDetail.startTime"
                        >
                          <option
                            v-for="hour in SELECTABLE_TIME.HOURS"
                            :key="hour"
                            :value="hour"
                          >{{ hour }}</option>
                        </select>
                        <label :class="$style.dateSeparator">〜</label>
                        <date-small-picker
                          size="sm"
                          v-model="pickDetail.endDate"
                          :minDate="now"
                          :maxDate="specifiedMaxDate"
                        />
                        <select
                          :class="$style.select"
                          v-model="pickDetail.endTime"
                        >
                          <option
                            v-for="hour in SELECTABLE_TIME.HOURS"
                            :key="hour"
                            :value="hour"
                          >{{ hour }}</option>
                        </select>
                      </div>
                      <p
                        v-if="invalid.startBeforeNow.includes(i)"
                        :class="$style.alert_text"
                      >{{ invalid.alertText.startBeforeNow }}</p>
                      <p
                        v-if="invalid.reversedIndexes.includes(i)"
                        :class="$style.alert_text"
                      >{{ invalid.alertText.reversed }}</p>
                      <!-- <p
                        v-if="invalid.differentMonthIndexes.includes(i)"
                        :class="$style.alert_text"
                      >{{ invalid.alertText.differentMonth }}</p> -->
                      <p
                        v-if="invalid.existIndexes.includes(i)"
                        :class="$style.alert_text"
                        >{{ invalid.alertText.exists }}</p>
                      <p
                        v-if="invalid.duplicatedMonthIndexes.includes(i)"
                        :class="$style.alert_text"
                        >{{ invalid.alertText.duplicatedMonth }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </template>
        <template v-else>
          <p>モニタリング実施期間をカレンダーから選択してください</p>
        </template>
      </ul>

      <spacer :y="3"/>
      <div
        v-show="showRegisterBtn"
        :class="$style.btn"
      >
        <basic-btn
          tag="button"
          @click="clickRegisterBtn()"
        >登録</basic-btn>
      </div>

    </div>

    <div :class="$style.close" v-on:click="hideModal"></div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import Calendar from '@/views/components/DatePicker.vue';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';

import moment from 'moment';

const SELECTABLE_TIME = {
  HOURS: (() => {
    const start = 8;
    const end = 22;
    const hours = [];
    for (let i = start; i <= end; i += 1) {
      hours.push(`${i < 10 ? '0' : ''}${i}:00`);
    }
    return hours;
  })(),
  DEFAULT_TIME_START: '08:00',
  DEFAULT_TIME_END: '18:00',
  DATE_FORMAT: 'YYYY-MM-DD',
  DATE_FORMAT_DISPLAY: 'YYYY年MM月DD日',
};
const CYCLES_DEF = {
  0: {
    label: '繰り返さない',
    value: null,
  },
  10: {
    label: '毎月',
    value: {
      unit: 'months',
      value: 1,
    },
  },
  20: {
    label: '3か月',
    value: {
      unit: 'months',
      value: 3,
    },
  },
  30: {
    label: '6か月',
    value: {
      unit: 'months',
      value: 6,
    },
  },
};
const MONITORING_SCHEDULE_TYPE = 1;
const BILLING_TYPE_1 = 1; // 従量課金モデル(スポットプラン)
const SELECTABLE_ACCOUNT_TYPES = [
  1, // 生徒・プログラム対象者
  3, // 教職員・企業担当者
];

export default {
  name: 'register-monitoring-schedule',
  props: ['data'],
  // {
  //   school: Object,
  //   callback: Function,
  // }
  components: {
    Calendar,
    Spacer,
    LabelBox,
    BasicBtn,
    DateSmallPicker,
  },
  data() {
    return {
      SELECTABLE_TIME,
      CYCLES_DEF,
      usersAll: [],
      now: new Date(),
      calendar: {
        key: null,
        schedule: [],
        initialDate: '', // カレンダー初期選択日
      },
      baseDate: {
        startDate: null,
        startHour: null,
        endDate: null,
        endHour: null,
      },
      cycleValue: 0,
      cycleEndDate: null,
      targetAccountTypes: null,
      invalid: { // 登録不可データ管理
        reversedIndexes: [], // 開始日時と終了日時の関係が逆転している
        startBeforeNow: [], // 開始日時が現在時刻より過去になっている
        // differentMonthIndexes: [], // 開始月と終了月が異なる
        existIndexes: [], // 登録済み月を開始日時に指定している
        duplicatedMonthIndexes: [], // 同一月を複数指定している
        alertText: {
          startBeforeNow: '開始日時は現在時刻より未来にしてください',
          reversed: '終了日時は開始日時より未来にしてください',
          // differentMonth: '期間が月をまたがないよう設定してください',
          exists: '指定月のモニタリングは登録済のため、新規登録できません',
          duplicatedMonth: '同一の月を複数同時指定することはできません',
        },
      },
      optionalAttrs: [], // カレンダーのオプション属性
      specifiedMaxDate: moment().add(1, 'year').endOf('month').format(SELECTABLE_TIME.DATE_FORMAT), // 本日から1年後の月の最終日を指定
    };
  },
  created() {
    if (this.holiday.list.length) {
      this.drawCalendar();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'holiday/setHoliday') {
          this.drawCalendar();
        }
      });
    }
  },
  watch: {
    accountTypeOptions() {
      // 「種別」の初期選択値を設定
      if (this.accountTypeOptions.length === 0) {
        this.targetAccountTypes = JSON.stringify(SELECTABLE_ACCOUNT_TYPES);
      } else {
        this.targetAccountTypes = this.accountTypeOptions[0].key;
      }
    },
  },
  computed: {
    ...mapState(['user', 'helper', 'holiday']),
    pickList() {
      if (CYCLES_DEF[this.cycleValue].value === null || !moment(this.cycleEndDate).isValid()) {
        return [];
      }
      // チェック内容をクリア
      this._clearInvalidData();
      // 指定の間隔でリストを作成する
      const pickList = [];
      let tmpStartDate = this.baseDate.startDate;
      let tmpEndDate;
      const diffInDays = moment(this.baseDate.endDate).diff(moment(this.baseDate.startDate), 'days');
      while (true) {
        tmpEndDate = moment(tmpStartDate).add('days', diffInDays).format(SELECTABLE_TIME.DATE_FORMAT);
        if (moment(this.cycleEndDate) < moment(tmpEndDate)) {
          break;
        }
        pickList.push({
          startDate: tmpStartDate,
          startTime: this.baseDate.startTime,
          endDate: tmpEndDate,
          endTime: this.baseDate.endTime,
        });
        tmpStartDate = moment(tmpStartDate).add(CYCLES_DEF[this.cycleValue].value.unit, CYCLES_DEF[this.cycleValue].value.value).format(SELECTABLE_TIME.DATE_FORMAT);
      }
      return pickList;
    },
    showRegisterBtn() {
      if (!this.baseDate.startDate) {
        return false;
      }
      if (CYCLES_DEF[this.cycleValue].value === null) {
        return true;
      }
      return this.pickList.length > 0;
    },
    isSpotPlan() {
      // billing_type = 1: 従量課金モデル(スポットプラン)
      return this.data.school && this.data.school.billing_type && this.data.school.billing_type.billing_type === BILLING_TYPE_1;
    },
    accountTypeOptions() {
      if (!this.isSpotPlan) {
        return [];
      }
      // 利用者全員の選択肢
      const optionsTop = [
        {
          key: JSON.stringify(SELECTABLE_ACCOUNT_TYPES),
          label: '利用者全員',
        },
      ];
      // そのほかの選択肢
      const options = [];
      const labels = this.data.school.is_company ? this.helper.master.labels.users.company_account_type : this.helper.master.labels.users.account_type;
      SELECTABLE_ACCOUNT_TYPES.forEach((key) => {
        // usersAllに存在しないアカウントタイプは表示しない
        if (!this.usersAll.some((user) => user.account_type === key)) {
          return;
        }
        const option = {
          key: JSON.stringify([key]),
          label: labels[key],
        };
        options.push(option);
      });
      // optionsの選択肢が1つの場合は、利用者全員の選択肢は採用しない
      if (options.length === 1) {
        return options;
      }
      return optionsTop.concat(options);
    },
  },
  methods: {
    async drawCalendar() {
      // 既存のユーザー情報を取得
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/user/get/list',
          params: {
            school_id: this.data.school.id,
            accountTypes: SELECTABLE_ACCOUNT_TYPES,
            onlyActiveUser: 1, // 有効なユーザーのみ
          },
        });
        this.usersAll = response.data.users.data;
      } catch (e) {
        alert('ユーザー情報の取得に失敗しました');
        throw e;
      }

      // 既存のスケジュールを取得
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/monitoringSchedule/get/list',
          params: {
            school_id: this.data.school.id,
            type: 1, // モニタリング
            from: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD 00:00:00'), // 先月１日からのスケジュールを取得
          },
        });
        const monitoringSchedules = response.data.data;
        // カレンダー上に選択不可で表示する
        this.optionalAttrs = monitoringSchedules.map((row) => {
          const isPast = moment(row.start_time).isBefore(new Date()); // 過去のスケジュールかどうか（色分けに利用）
          const data = {
            key: `exists_monitoring_schedule_${row.id}`,
            disabled: true, // 選択不可にする
            content: {
              style: {
                color: isPast ? 'white' : null,
              },
            },
            highlight: {
              style: {
                'background-color': isPast ? 'var(--gray-main)' : 'rgb(100, 149, 237)',
              },
            },
            popover: {
              label: 'スケジュール登録済み',
              visibility: 'hover',
              hideIndicator: true,
            },
            dates: {
              start: moment(row.start_time).format(SELECTABLE_TIME.DATE_FORMAT),
              end: moment(row.end_time).format(SELECTABLE_TIME.DATE_FORMAT),
            },
          };
          return data;
        });
      } catch (e) {
        alert('モニタリングスケジュール情報の取得に失敗しました');
        throw e;
      }
      // カレンダーを表示
      this.calendar.schedule = this.holiday.list.map((row) => {
        const data = {
          id: row.id,
          color: 'var(--orange-main)',
          date: row.date,
          label: row.label,
        };
        return data;
      });
      if (!this.calendar.key) {
        this.calendar.key = new Date(); // keyを更新することでカレンダーを再描画する
      }
    },
    /**
     * @param start 'yyyy-MM-dd'
     * @param end 'yyyy-MM-dd'
     */
    setRange({
      start = null,
      end = null,
    }) {
      if (!start || !end) {
        return;
      }
      // 基準日の変更
      this.baseDate = {
        startDate: start,
        startTime: this.baseDate.startTime || SELECTABLE_TIME.DEFAULT_TIME_START,
        endDate: end,
        endTime: this.baseDate.endTime || SELECTABLE_TIME.DEFAULT_TIME_END,
      };
      // MEMO: カレンダーの選択範囲がクリアされてしまう問題への対処（ローカルでは問題ないがdev環境ではクリアされてしまった。時刻を選択するとカレンダー選択状態が復活したため、その操作を便宜上コードで行うことで対処する。）
      this.$nextTick(() => {
        const backup = this.baseDate.endTime;
        this.baseDate.endTime = null;
        this.$nextTick(() => {
          this.baseDate.endTime = backup;
        });
      });
    },
    async clickRegisterBtn() {
      const dataList = [];
      // 単発登録
      if (this.pickList.length === 0) {
        dataList.push({
          type: MONITORING_SCHEDULE_TYPE,
          school_id: this.data.school.id,
          start_time: `${moment(this.baseDate.startDate).format(SELECTABLE_TIME.DATE_FORMAT)} ${this.baseDate.startTime}:00`,
          end_time: `${moment(this.baseDate.endDate).format(SELECTABLE_TIME.DATE_FORMAT)} ${this.baseDate.endTime}:00`,
          target_account_types: this.targetAccountTypes,
          create_user_id: this.user.id,
          update_user_id: this.user.id,
        });
      } else {
        // 複数登録
        this.pickList.forEach((data) => {
          dataList.push({
            type: MONITORING_SCHEDULE_TYPE,
            school_id: this.data.school.id,
            start_time: `${moment(data.startDate).format(SELECTABLE_TIME.DATE_FORMAT)} ${data.startTime}:00`,
            end_time: `${moment(data.endDate).format(SELECTABLE_TIME.DATE_FORMAT)} ${data.endTime}:00`,
            target_account_types: this.targetAccountTypes,
            create_user_id: this.user.id,
            update_user_id: this.user.id,
          });
        });
      }

      // バリデーションチェック
      this.showLoading();
      try {
        if (!await this._valid(dataList)) {
          return;
        }
      } finally {
        this.hideLoading();
      }

      // MEMO: 下記は現在時刻をまたぐスケジュール登録を許可していないため、コメントアウト
      // // 現在時刻をまたぐスケジュールを登録する場合、即時通知の警告を表示する
      // const now = moment(new Date());
      // if (dataList.some((row) => now.isAfter(moment(row.start_time)) && now.isBefore(moment(row.end_time)))) {
      //   if (!confirm('即時開始のモニタリングについては、対象者全員に即時通知を行います。よろしいですか？')) {
      //     return;
      //   }
      // }

      // 対象者数を算出
      const targetUsers = this.usersAll.filter((user) => JSON.parse(this.targetAccountTypes).includes(user.account_type));
      if (targetUsers.length === 0) {
        alert('対象者が存在しません');
        return;
      }
      // 確定前の確認ダイアログ
      const noticeDate = moment(dataList[0].start_time).format('YYYY年MM月DD日 HH:mm');
      const noticeTargetAccountLabel = (() => {
        const types = JSON.parse(this.targetAccountTypes);
        if (types.length === 1) {
          return this.accountTypeOptions.find((option) => option.key === this.targetAccountTypes).label;
        }
        return '利用者全員';
      })();
      if (!confirm(`【${noticeDate}】利用者（${noticeTargetAccountLabel}）${targetUsers.length}名宛にモニタリング開始の案内メールが自動送信されます。`)) {
        return;
      }

      this.showLoading();
      try {
        await this._saveMonitoringSchedule(dataList);
        // 成功後は自モーダルを閉じて親にリフレッシュ要求を出す
        this.data.callback();
        this.hideModal();
      } catch (e) {
        alert('モニタリングスケジュールの登録に失敗しました');
        throw e;
      } finally {
        this.hideLoading();
      }
    },
    _saveMonitoringSchedule(dataList = []) {
      const prosesses = dataList.map((data) => this.axios({
        method: 'POST',
        url: '/v1/monitoringSchedule/set/register',
        data,
      }));
      return Promise.all(prosesses);
    },
    async _valid(dataList = []) {
      const fmtYM = 'YYYY-MM';

      // チェック内容をクリア
      this._clearInvalidData();

      // 時間軸での関係性チェック
      dataList.forEach((data, i) => {
        if (!moment(data.start_time).isBefore(moment(data.end_time))) {
          this.invalid.reversedIndexes.push(i);
        }
      });
      if (this.invalid.reversedIndexes.length > 0) {
        return false;
      }

      // 開始時刻は現在時刻より未来でないといけない
      const now = moment(new Date());
      dataList.forEach((data, i) => {
        if (moment(data.start_time).isBefore(now)) {
          this.invalid.startBeforeNow.push(i);
        }
      });
      if (this.invalid.startBeforeNow.length > 0) {
        return false;
      }

      // 開始日時と終了日時は同月でないといけない
      // dataList.forEach((data, i) => {
      //   if (moment(data.start_time).format(fmtYM) !== moment(data.end_time).format(fmtYM)) {
      //     this.invalid.differentMonthIndexes.push(i);
      //   }
      // });
      // if (this.invalid.differentMonthIndexes.length > 0) {
      //   return false;
      // }

      // 同一月ですでに登録が行われている場合は登録不可とする
      try {
        // 初期表示時に一度取得しているが、チェックのため再取得する
        const response = await this.axios({
          method: 'GET',
          url: '/v1/monitoringSchedule/get/list',
          params: {
            school_id: this.data.school.id,
            type: 1, // モニタリング
            from: moment().startOf('month').subtract(1, 'months').format('YYYY-MM-DD 00:00:00'), // 先月１日からのスケジュールを取得
          },
        });
        const exists = response.data.data;
        const existIndexes = [];
        exists.forEach((exist) => {
          const foundIndex = dataList.findIndex((data) => moment(data.start_time).format(fmtYM) === moment(exist.start_time).format(fmtYM));
          if (foundIndex > -1) {
            existIndexes.push(foundIndex);
          }
        });
        // 登録済みの月がひとつでもある場合は登録を阻止する
        if (existIndexes.length > 0) {
          this.invalid.existIndexes = existIndexes;
          return false;
        }
      } catch (e) {
        alert('登録チェックのためのモニタリングスケジュール情報の取得に失敗しました');
        throw e;
      }

      // 同一月を複数指定されていたらNG
      const monthMap = {};
      dataList.forEach((data) => {
        const month = moment(data.start_time).format(fmtYM);
        if (monthMap[month]) {
          monthMap[month] += 1;
        } else {
          monthMap[month] = 1;
        }
      });
      dataList.forEach((data, i) => {
        if (monthMap[moment(data.start_time).format(fmtYM)] > 1) {
          this.invalid.duplicatedMonthIndexes.push(i);
        }
      });
      if (this.invalid.duplicatedMonthIndexes.length > 0) {
        return false;
      }

      return true;
    },
    _clearInvalidData() {
      this.invalid.reversedIndexes = [];
      this.invalid.startBeforeNow = [];
      // this.invalid.differentMonthIndexes = [];
      this.invalid.existIndexes = [];
      this.invalid.duplicatedMonthIndexes = [];
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },

    formatDate(date) {
      return moment(date).format(SELECTABLE_TIME.DATE_FORMAT_DISPLAY);
    },
  },
};
</script>

<style lang="scss" module>
.detail {
  li, .detail_div {
    display: flex;
    align-items: flex-start;
    .label {
      width: 150px;
      margin-right: .5rem;
    }
    .content {
      margin-left: 20px;
      margin-top: 3px;
      flex: 1;
      word-break: break-all;
    }
    &:not(:first-child) {
      margin-top: .5rem;
    }
  }
  .detail_div {
    align-items: center;
  }
  @include sm-view {
    font-size: 14px;
    li {
      display: block;
      .label {
        width: 100%;
        margin: 0 0 .5em;
        text-align: left;
        padding: .5em;
      }
    }
  }
}
.picklist {
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: .5rem;
    .label {
      width: 40px;
    }
    .content {
      margin-left: 20px;
      margin-top: 3px;
      flex: 1;
      word-break: break-all;
    }
  }
  @include sm-view {
    font-size: 14px;
    li {
      display: block;
      .label {
        width: 100%;
        margin: 0 0 .5em;
        text-align: left;
        padding: .5em;
      }
    }
  }
}
.select {
  margin-left: .5rem;
  padding: 6px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  font-size: 14px;
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &.plane {
    background-color: transparent;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dateSeparator {
  margin: 0 .5rem;
}
.alert_text {
  font-size: .8em;
  color: red;
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: var(--gray-main);
  border-radius: 50%;
  cursor: pointer;
  &::before, &::after {
    content: '';
    width: 3px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  @include sm-view {
    width: 20px;
    height: 20px;
    &::before, &::after {
      content: '';
      width: 2px;
      height: 13px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}
</style>
