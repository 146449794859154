<template>
  <div>
    <spacer :y="1"/>

    <!-- 検証用 -->
    <!-- <stack-item>
      <contents-box>
        <div :class="$style.search">
          <div :class="$style.searchArea">
            <dl>
              <dt>ココモニチェック 集計対象</dt>
              <dd>
                <input
                  type="checkbox"
                  id="operator-cocomoni-who5answer-check"
                  v-on:change="changeWho5Check"
                >
                <label for="operator-cocomoni-who5answer-check">健康相談予約時の回答データを含める</label>
              </dd>
            </dl>
          </div>
        </div>
      </contents-box>
    </stack-item> -->

    <cocomoni-dashboard
      v-if="mySchool"
      :key="componentKey"
      :school="mySchool"
      :getAllTypesOfWho5Answer="flag.getAllTypesOfWho5Answer"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex';

// import StackItem from '@/views/components/StackItem.vue';
// import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';

import CocomoniDashboard from '@/views/pages/Common/CocomoniDashboard.vue';

export default {
  name: 'teacher-cocomoni',
  components: {
    // StackItem,
    // ContentsBox,
    Spacer,
    CocomoniDashboard,
  },
  data() {
    return {
      flag: {
        getAllTypesOfWho5Answer: false,
      },
    };
  },
  computed: {
    ...mapState(['user']),
    mySchool() {
      if (!this.user) {
        return null;
      }
      return this.user.school[0];
    },
    componentKey() {
      // 表示条件を切り替えた際にコンポーネントを再描画するために設定
      if (!this.mySchool) {
        return null;
      }
      return `${this.mySchool.id}-${this.flag.getAllTypesOfWho5Answer}`;
    },
  },
  methods: {
    changeWho5Check(e) {
      this.flag.getAllTypesOfWho5Answer = e.target.checked;
    },
  },
};
</script>

<style lang="scss" module>
.searchArea {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 9em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 2em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
</style>
