<template>
  <div v-if="pagetype">
    <div :class="`${$style.reserve_top} ${$style[pagetype]}`">
      <i v-if="isText"
        :class="$style.reserve_top_icon"
        class="fa-regular fa-comment-lines"/>
      <i v-if="isOnline"
        :class="$style.reserve_top_icon"
        class="fa-regular fa-laptop">
        <i :class="$style.reserve_top_icon_inner" class="fa-regular fa-user-headset"/>
      </i>
      <i v-if="isVirtual"
        :class="$style.reserve_top_icon"
        class="fa-solid fa-vr-cardboard"></i>
      <div
        v-if="content"
        v-html="content.top"
        :class="$style.reserve_top_text"></div>
    </div>
    <div :class="$style.content_top_wrap">
      <div :class="$style.content_top">
        <spacer :y="9" :sm-y="4" />
        <div :class="[$style.content_top_subtitle, isVirtual ? $style.virtual : null]">いつでも どこでも 気軽に 手軽に</div>
        <spacer :y="2"/>
        <div :class="$style.content_top_title">{{ content.content_title }}</div>
        <div v-if="helper && helper.textDisableStatus && helper.textDisableStatus.emphasis_notice && isText"
        :class="$style.notice">
          <spacer :y="3" :sm-y="3" />
          <emphasis-notice
            v-if="helper.textDisableStatus.emphasis_notice
              && helper.textDisableStatus.show_emphasis_notice"
            :message="helper.textDisableStatus.emphasis_notice" />
          <spacer :y="3" :sm-y="3" />
        </div>
        <spacer v-else :y="7" :sm-y="3" />
        <div :class="$style.content_top_text">
          <p v-html="content.description"></p>
        </div>
        <spacer :y="9" :sm-y="4" />
      </div>
    </div>

    <div
      :class="$style.content"
      v-if="isOnlineOrVirtual && !schedule.id">
      <spacer :y="5"/>
      <data-grid>
        <data-detail>
          <btn-container>
            <basic-btn tag="a" @click="clickReserve">{{ reserveLabel }}</basic-btn>
          </btn-container>
        </data-detail>
      </data-grid>
      <spacer :y="5"/>
   </div>

    <div v-else :class="$style.content" id="confirm">
      <spacer :y="5"/>
      <data-grid>
        <data-row v-if="isOnlineOrVirtual && schedule.data">
          <data-term width="quater">
            <label-box
              :class="$style.label"
              label="先生"
              :color="labelBoxColor"
            />
          </data-term>
          <data-detail>
            <div :class="$style.flex">
              <round-img width="50px" :src="getMyIconUrl(schedule.data.counselor)" />
              <spacer :x="1.5"/>
              <p><span :class="$style.bold">{{ schedule.data.counselor.username }}</span>先生（{{ helper.master.labels.users.account_type[schedule.data.counselor.account_type] }}）に相談</p>
            </div>
          </data-detail>
        </data-row>

        <data-row v-if="isVirtual && schedule.data">
          <data-term width="quater">
            <label-box
              :class="$style.label"
              label="Virtual Cast ID"
              :color="labelBoxColor"
            />
            <p
              v-if="!hasClientVirtualCastId"
              :class="$style.required"
            >※必須項目</p>
          </data-term>
          <data-detail>
            <template v-if="hasClientVirtualCastId">
              <p>{{ user.virtualCastProp.virtual_cast_id }}</p>
              <template v-if="!flag.isConfirm">
                <p :class="$style.annotation">
                  以下のリンクがご自分のアカウントか確認してください。<br>
                  <a :href="getVirtualCastUserURL(user.virtualCastProp.virtual_cast_id)" target="_blank">{{ getVirtualCastUserURL(user.virtualCastProp.virtual_cast_id) }}</a>
                </p>
                <p :class="$style.annotation">万が一間違えて登録してしまった場合は営業日3日前までに事務局へ連絡ください。</p>
              </template>
            </template>
            <template v-else>
              <div
                :class="[
                  $style.vcid,
                  flag.isConfirm ? null : $style.force_link
                ]"
                v-on:click="clickVcidInputButton"
              >
                <input-codes
                  :key="`regist-form-vcid-${virtual_cast_id}`"
                  :digit="6"
                  :value="virtual_cast_id"
                  :smallMode="true"
                  :readonly="true"
                />
              </div>
              <p
                v-if="virtual_cast_id"
                :class="$style.annotation"
              ><a :href="getVirtualCastUserURL(virtual_cast_id)" target="_blank">{{ getVirtualCastUserURL(virtual_cast_id) }}</a></p>
            </template>
          </data-detail>
        </data-row>

        <data-row v-if="isOnlineOrVirtual && schedule.data">
          <data-term width="quater">
            <label-box
              :class="$style.label"
              label="希望日時"
              :color="labelBoxColor"
            />
          </data-term>
          <data-detail>
            <div :class="`${$style.flex} ${$style.space}`">
              <p>{{ filterMoment(schedule.data.date, 'Y年MM月DD日') }}</p>
              <spacer :x="2"/>
              <p>{{ filterMoment(schedule.data.date, 'HH:mm') }} ~ {{ filterMoment(schedule.data.date, 'HH:45') }}</p>
            </div>
          </data-detail>
        </data-row>

        <data-row
          v-if="helper.master && helper.master.labels">
          <data-term width="quater">
            <label-box
              :class="$style.label"
              label="お悩み"
              :color="labelBoxColor"
            />
            <p :class="$style.required">※必須項目</p>
          </data-term>
          <data-detail>
            <div :class="$style.flex">
              <div
                v-for="(item, value) in helper.master.labels.texts.type"
                :key="value"
                :class="$style.item"
                v-show="Number(value) < 6 && (!flag.isConfirm || selectItems.includes(value))">
                <input
                  v-if="!flag.isConfirm"
                  :disabled="flag.isConfirm || (isText && isTextDisable)"
                  type="checkbox"
                  :id="`${item}-${value}`"
                  :value="value"
                  :checked="selectItems.includes(value)"
                  v-on:change="updateCheckValue">
                <label
                  v-if="!flag.isConfirm || selectItems.includes(value)"
                  :for="`${item}-${value}`">{{ item }}</label>
              </div>
              <div :class="$style.item">
                <input
                  v-show="!flag.isConfirm"
                  :disabled="flag.isConfirm || (isText && isTextDisable)"
                  type="checkbox"
                  id="other"
                  value="6"
                  :checked="selectItems.includes(6)"
                  @change="updateCheckValue">
                <label v-if="!flag.isConfirm || selectItems.includes(6)" for="other">その他</label>
              </div>
            </div>
            <template v-if="selectItems.includes('6')">
              <spacer :y="1"/>
              <label
                for="other_text"
                class="n2br"
              >
                <p
                  :class="$style.other_text_label"
                  v-if="!flag.isConfirm">その他の内容を書いてください</p>
                <input
                  v-if="!flag.isConfirm"
                  :class="$style.input_text"
                  type="text"
                  v-model="other_text">
                <p v-else>（{{ other_text }}）</p>
              </label>
            </template>
          </data-detail>
        </data-row>

        <data-row>
          <data-term width="quater">
            <label-box
              :class="$style.label"
              label="相談内容"
              :color="labelBoxColor"
            />
            <p :class="$style.annotation">※絵文字や機種依存文字は文字化けの原因になります。<br> ご利用を避け、テキストのみご入力ください。</p>
          </data-term>
          <data-detail>
            <textarea
              :class="$style.textarea"
              name="textarea"
              id="textarea"
              v-model="message"
              rows="20"
              :disabled="flag.isConfirm || (isText && isTextDisable)"
              v-on:input="updateValue"
              :placeholder="isOnlineOrVirtual ? '担当の医師・心理士に事前に伝えておきたいことがあればご記入ください。': ''"></textarea>
            <div :class="$style.count">
              <p :class="{ [$style.red] : flag.over600 }">600字以内で入力してください。</p>
              <p>{{ textCount }}/<span :class="$style.bold">600</span></p>
            </div>
          </data-detail>
        </data-row>

        <data-row v-if="helper.master && helper.master.who5">
          <data-term width="quater">
            <label-box
              :class="$style.label"
              label="ココモニチェック"
              :color="labelBoxColor"
            />
            <p :class="$style.required">※必須項目</p>
          </data-term>
          <data-detail v-if="helper.master">
            <StackItem
              v-for="(item, i) in helper.master.who5.items"
              :key="i"
              :class="$style.who5item">
              <basic-box type="inner">
                <div :class="$style.who5itemTitle"><span>Q{{ i + 1 }}.</span> {{item.label}}</div>
                <spacer :y="1" />
                <ul :class="$style.optionList">
                  <li
                    v-for="(option, j) in helper.master.who5.options"
                    :key="`${i}-${j}`"
                    :class="$style.optionItem"
                  >
                    <input
                      type="radio"
                      :id="`${i}-${j}`"
                      :name="`who${i}`"
                      v-on:change="updateRadioValue(i, option.value)"
                      :disabled="flag.isConfirm || (isText && isTextDisable)">
                    <label :for="`${i}-${j}`">{{ option.label }}</label>
                  </li>
                </ul>
              </basic-box>
            </StackItem>
          </data-detail>
        </data-row>
      </data-grid>
      <spacer :y="4" />
      <btn-container>
        <basic-btn tag="button" v-if="!flag.isConfirm" v-on:click="checkConfirm" :disabled="isText && isTextDisable">相談内容を確認する</basic-btn>
        <basic-btn tag="button" v-if="flag.isConfirm" v-on:click="changeFlag" :disabled="isText && isTextDisable">戻る</basic-btn>
        <spacer :x="2"/>
        <basic-btn tag="button" v-if="flag.isConfirm" v-on:click="submit" :disabled="isText && isTextDisable || isSended">送信する</basic-btn>
      </btn-container>
      <spacer :y="8.5"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import RoundImg from '@/views/components/RoundImg';
import LabelBox from '@/views/components/LabelBox';
import Spacer from '@/views/components/Spacer.vue';
import BasicBox from '@/views/components/BasicBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import EmphasisNotice from '@/views/components/EmphasisNotice.vue';
import InputCodes from '@/views/components/parts/Form/InputCodes.vue';
import {
  DataGrid, DataRow, DataTerm, DataDetail,
} from '@/views/components/DataGrid';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'reserve-form',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    BasicBox,
    RoundImg,
    LabelBox,
    Spacer,
    StackItem,
    DataGrid,
    DataRow,
    DataTerm,
    DataDetail,
    EmphasisNotice,
    InputCodes,
  },
  data() {
    return {
      flag: {
        isConfirm: false,
        over600: false,
      },
      schedule: {
        id: null,
        data: null,
      },
      pagetype: null,
      content: {},
      contents: {
        text: {
          top: 'テキスト健康相談',
          content_title: '書いてみよう',
          description: 'マイページから、いつでも気軽に相談可能。心理士とのメッセージのやり取りを通じて、不安な気持ちや悩みを書き出すことができます。回答のあった心理士との面談（オンライン健康相談）予約も簡単ラクラク！<br>※相談する心理士の指定はできません。<br>※相談内容の送信から営業日2日以内に担当心理士より回答が届きます。<br><br>Welcome to talk 営業日: 平日13:00-22:00<br>(土日祝日、年末年始を除く)<br>　例1)平日金曜に送信>>翌週の火曜日までに回答<br>　例2)平日金曜に送信、翌週月曜が祝日>>翌週の水曜までに回答<br>　例3)平日水曜に送信、翌日木曜が祝日>>翌週の月曜までに回答',
        },
        online: {
          top: 'オンライン健康相談',
          content_title: '話してみよう',
          description: 'テレビ会議システムを用いた1対1のプライベート空間で、希望の心理士もしくは医師の顔を見ながら安心して相談ができます。営業日３日後から最大翌月末までの予約が可能！好きな場所から、好きな時間に（13:00-22:00）、どんな些細なことでも気軽にご相談ください。<br>※Zoomアプリケーションのダウンロードが必要です。',
        },
        virtual: {
          top: 'バーチャル健康相談',
          content_title: 'バーチャル空間で会いましょう',
          description: 'MetaQuestを用いたバーチャル空間で、相談体験をしてみませんか？<br>'
            + '悩みやモヤモヤをテキストで書くのが苦手…、オンライン上でも顔を見せて話すのは緊張する…、テキスト健康相談やオンライン健康相談を始めていない方も、バーチャル空間ならいつもと違った体験になるかもしれません。<br>'
            + '自分の好きなアバターで、好きな時間に（13:00-22:00）、Welcome to talkの専門家（医師・心理士）がバーチャル空間でお待ちしています。<br>'
            + '<br>'
            + '【バーチャル健康相談をご利用いただくために】<br>'
            + '※MetaQuest VRヘッドセットが必要です<br>'
            + '※お手持ちのMeta Quest2またはMeta Quest3の設定<br>'
            + '※バーチャルキャスト（VC）のログインID取得/ルームのお気に入り設定',
        },
      },
      other_text: null,
      message: null,
      selectItems: [],
      textCount: 0,
      isSended: false,
      who5Answers: [null, null, null, null, null],
      virtual_cast_id: null,
    };
  },
  created() {
    const path = this.$route.path.split('/');
    this.pagetype = path[2];
    this.content = this.contents[this.pagetype];

    if (['online', 'virtual'].includes(this.pagetype)) {
      // schedule_idからカウンセラーとシフト情報取得
      const query = this.$route.query;
      if (query.schedule_id && cf.convert2Num(query.schedule_id)) {
        this.schedule.id = cf.convert2Num(query.schedule_id);
        this.getScheduleData();
      }
    }
  },
  computed: {
    ...mapState(['helper', 'user']),
    isText() {
      return this.pagetype === 'text';
    },
    isOnline() {
      return this.pagetype === 'online';
    },
    isVirtual() {
      return this.pagetype === 'virtual';
    },
    isOnlineOrVirtual() {
      return this.isOnline || this.isVirtual;
    },
    isRegist() {
      let flag = true;
      // お悩みを選択しているか
      if (!this.selectItems.length) flag = false;
      // 相談内容が600字以内に収まっているか
      if (this.textCount > 600) flag = false;
      // 質問票に回答しているか
      this.who5items.forEach((item) => {
        if (item.value === null) {
          flag = false;
        }
      });
      return flag;
    },
    hasProgress() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.texts
      && this.user.counseling.texts.progress
      && this.user.counseling.texts.progress.length) {
        flag = true;
      }
      return flag;
    },
    hasFutureReservation() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.reserves
      && this.user.counseling.reserves.future
      && this.user.counseling.reserves.future.length) {
        flag = true;
      }
      return flag;
    },
    reserveLabel() {
      return this.hasFutureReservation ? '予約状況を確認する' : '相談する先生を選ぶ';
    },
    calcCustomerAge() {
      const birthDate = moment(this.user.birthday);
      const nowDate = moment(new Date());
      const age = nowDate.year() - birthDate.year();
      if (!age) return null;
      const thisYearsBirthDay = `${nowDate.year()}-${birthDate.month() + 1}-${birthDate.date()}`;
      const thisYearsBirthDate = moment(thisYearsBirthDay);
      const calcAge = age + ((thisYearsBirthDate.isAfter(nowDate)) ? -1 : 0);
      return calcAge;
    },
    isTextDisable() {
      let flag = false;
      if (this.helper
      && this.helper.textDisableStatus
      && this.helper.textDisableStatus.disable_text_counseling) {
        flag = true;
      }
      return flag;
    },
    hasClientVirtualCastId() {
      return this.user.virtualCastProp ? !!this.user.virtualCastProp.virtual_cast_id : false;
    },
    labelBoxColor() {
      return this.isVirtual ? 'green' : null; // null = defaultのorangeが採用される
    },
  },
  methods: {
    ignores() {
      let result = false;
      const ignoreSchools = [];
      if (ignoreSchools.includes(this.user.school[0].id)) result = true;
      return result;
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
    async checkConfirm() {
      // テキスト相談の場合の独自チェック
      if (this.isText && this.hasProgress) {
        alert('相談中のやりとりが残っているため、新たに相談内容を登録することはできません。');
        return;
      }
      // オンライン/バーチャル相談の場合の独自チェック
      if (this.isOnlineOrVirtual) {
        // 同時間帯での予約数チェック
        if (!(await cf.methods.checkOnlineReserveTime(this.schedule.data.date))) {
          return;
        }
      }
      // バーチャル健康相談の場合の独自チェック
      if (this.isVirtual && !this.hasClientVirtualCastId && !this.virtual_cast_id) {
        alert('Virtual Cast ID を入力してください。');
        return;
      }

      let valid = true;

      if (!this.selectItems.length) {
        alert('お悩みの種別を選択してください');
        valid = false;
      }

      if (Object.values(this.selectItems).includes('6') && !this.other_text) {
        alert('お悩みのその他の内容を入力してください');
        valid = false;
      }

      if (this.isText && !this.message) { // テキスト健康相談
        alert('相談内容を入力してください');
        valid = false;
      }
      if (this.flag.over600) {
        alert('相談内容は600字以内で入力してください。');
        valid = false;
      }

      // who5はすべて埋まっている必要がある
      this.who5Answers.some((score) => {
        if (score === null) {
          alert('ココモニチェックはすべて回答してください');
          valid = false;
          return true;
        }
        return false;
      });
      if (valid) this.changeFlag();
    },

    changeFlag() {
      this.flag.isConfirm = !this.flag.isConfirm;
      if (this.flag.isConfirm) {
        const elem = document.getElementById('confirm');
        const position = elem.getBoundingClientRect().top;
        scrollBy(0, position - 77);
      }
    },

    updateValue(e) {
      const val = e.target.value;
      this.textCount = val.length;
      if (this.textCount > 600) this.flag.over600 = true;
      else this.flag.over600 = false;
    },

    updateCheckValue(e) {
      if (this.selectItems.includes(e.target.value)) {
        const idx = this.selectItems.indexOf(e.target.value);
        this.selectItems.splice(idx, 1);
      } else {
        this.selectItems.push(e.target.value);
      }
    },

    updateRadioValue(i, value) {
      this.who5Answers[i] = value;
    },

    async submit() {
      // オンライン/バーチャル相談の場合の独自チェック
      if (this.isOnlineOrVirtual) {
        // 同時間帯での予約数チェック
        if (!(await cf.methods.checkOnlineReserveTime(this.schedule.data.date))) {
          return;
        }
      }

      this.showLoading();
      // selectItemsの中身をparseIntする
      this.selectItems.forEach((item, i) => {
        this.selectItems[i] = Number(item);
      });

      // VCIDの登録
      if (this.isVirtual && !this.hasClientVirtualCastId) {
        try {
          await this.axios({
            method: 'POST',
            url: '/v1/user/set/update',
            data: {
              id: this.user.id,
              account_type: this.user.account_type,
              virtualCastProp: {
                virtual_cast_id: this.virtual_cast_id,
              },
            },
          });
        } catch (error) {
          alert('Virtual Cast ID の登録に失敗しました。');
          console.error(error);
          return;
        }
      }

      let data = {};
      let modelName = 'messageGroup';
      if (this.isOnlineOrVirtual) { // オンライン/バーチャル健康相談
        modelName = 'reserve';
        // 必須項目
        data = {
          from_user_id: this.user.id,
          to_user_id: this.schedule.data.counselor.id,
          schedule_id: this.schedule.id,
          customer_age: this.calcCustomerAge,
          customer_school_type: this.user.school[0].school_type,
          customer_school_year: this.user.school_year,
          counseling_type: {
            type: this.selectItems,
            text: this.other_text || null,
          },
          who5Data: {
            answers: this.who5Answers,
          },
          is_virtual: this.isVirtual ? 1 : 0,
        };
        // 追加データ
        // if (this.other_text) data.other_text = this.other_text;
        if (this.message) data.memoData = { memo: this.message };
      } else if (this.isText) {

        // テキスト利用可能回数確認
        const billingType = this.user.school[0].billing_type.billing_type || null;
        let remaining;
        if (billingType && billingType === 1 && !this.ignores()) {
          if (this.user.school[0].textCount === 0) remaining = 0;
          else if (this.user.school[0].textCount !== 0) remaining = this.user.school[0].textCount || null;
        }

        // 必須項目
        data = {
          user_id: this.user.id,
          target_type: 1, // テキスト健康相談
          customer_age: this.calcCustomerAge,
          customer_school_type: this.user.school[0].school_type,
          customer_school_year: this.user.school_year,
          counseling_type: {
            type: this.selectItems,
            text: this.other_text || null,
          },
          messageData: {
            user_id: this.user.id,
            message: this.message,
          },
          who5Data: {
            answers: this.who5Answers,
          },
          remaining,
        };
      }

      const endpoint = `/v1/${modelName}/set/register`;
      this.axios({
        method: 'POST',
        url: endpoint,
        data,
      })
        .then((response) => {
          // オンライン/バーチャル
          if (this.isOnlineOrVirtual) {
            // エラー処理
            if (response.data.exists) {
              // 予約重複
              alert(`${cf.methods.filterMoment(this.schedule.data.date, 'YYYY年MM月DD日')}の${cf.methods.filterMoment(this.schedule.data.date, 'HH:mm')}予約はすでに存在しています。`);
              return false;
            }
            if (response.data.noCountsLeft) {
              // 残カウント不足
              alert('予約可能カウントが残っていないため、新たにオンライン健康相談を受け付けることができません。');
              return false;
            }
            if (response.data.virtualReserveDuplicated) {
              // バーチャル健康相談の開始時刻重複エラー
              alert('対象の開始時刻にすでに他のバーチャル健康相談予約が入っているため、予約を受け付けることができません。');
              return false;
            }
            // 正常処理
            this.onlineCounselingContact(response.data.reserveId);
            alert(`${cf.methods.filterMoment(this.schedule.data.date, 'YYYY年MM月DD日')}の${cf.methods.filterMoment(this.schedule.data.date, 'HH:mm')}にてご予約を承りました。`);
            // 作成の連絡
          }
          // テキスト
          if (this.isText) {
            // const billingType = this.user.school[0].billing_type.billing_type || null;

            if (response.data.noCountsLeft) {
              alert('予約可能カウントが残っていないため、新たにテキスト健康相談を受け付けることができません');
              return false;
            }

            alert('テキスト健康相談を投稿しました。2営業日以内に担当心理士より返答を行います。');
            this.isSended = true;
            // 作成の連絡
            this.textCounselingContact(response.data.group_id);
            if (this.user
              && this.user.school[0]
              && this.user.school[0].billing_type.billing_type === 1
              && !this.ignores()) {
              this.registTextCountHistories(response.data.group_id);
            }
          }
        })
        .catch((error) => {
          if (this.isOnline) {
            alert('オンライン健康相談の予約登録に失敗しました。お手数ですが管理者までお知らせください。');
          } else if (this.isVirtual) {
            alert('バーチャル健康相談の予約登録に失敗しました。お手数ですが管理者までお知らせください。');
          } else {
            alert('テキスト健康相談登録に失敗しました。お手数ですが管理者までお知らせください。');
          }
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
          // 予約storeの更新
          this.$store.dispatch('user/counseling/getCounseling', null, { root: true });
        });
    },

    getScheduleData() {
      this.axios({
        method: 'GET',
        url: '/v1/schedule/get/detail',
        params: {
          id: this.schedule.id,
        },
      })
        .then((response) => {
          this.schedule.data = response.data.schedule;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** テキスト健康相談の連絡 */
    textCounselingContact(groupId) {
      // 利用者へ連絡
      this.axios({
        method: 'POST',
        url: '/v1/contact/regist/text/user',
        data: { group_id: groupId },
      })
        .then((response) => {
          if (this.helper.env.name === 'local') {
            console.log(response.data);
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });

      // 心理士へ連絡
      this.axios({
        method: 'POST',
        url: '/v1/contact/regist/text/counselor',
        data: { group_id: groupId },
      })
        .then((response) => {
          if (this.helper.env.name === 'local') {
            console.log(response.data);
          }
          // トップへリダイレクト
          this.$router.push({
            path: '/',
            query: { tab: 'text' },
          });
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    registTextCountHistories(groupId) {
      const data = {
        school_id: this.user.school[0].id,
        group_id: groupId,
        count: 1,
        is_add: 0,
      };

      this.axios({
        method: 'POST',
        url: '/v1/count/historyText/set/register',
        data,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    /** オンライン健康相談の連絡 */
    onlineCounselingContact(reserveId) {
      // 利用者へ連絡
      this.axios({
        method: 'POST',
        url: '/v1/contact/regist/online/user',
        data: { reserve_id: reserveId },
      })
        .then((response) => {
          if (this.helper.env.name === 'local') {
            console.log(response.data);
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });

      // 心理士へ連絡
      this.axios({
        method: 'POST',
        url: '/v1/contact/regist/online/counselor',
        data: { reserve_id: reserveId },
      })
        .then((response) => {
          if (this.helper.env.name === 'local') {
            console.log(response.data);
          }
          // トップへリダイレクト
          this.$router.push({
            path: '/',
            query: { tab: this.isVirtual ? 'virtual' : 'online' },
          });
          // 強制リロード（TODO: storeの変更を検知してupdateしたい）
          setTimeout(() => { this.$router.go(this.$router.currentRoute.path); }, 500);
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    clickReserve() {
      const pathKey = this.isVirtual ? 'virtual' : 'online';
      const path = this.hasFutureReservation ? `/?tab=${pathKey}` : `/reserve/select/${pathKey}/`;
      this.$router.replace(path);
    },

    /** VCID入力モーダルを開く */
    clickVcidInputButton() {
      if (this.flag.isConfirm) {
        return;
      }
      const args = {
        modalName: 'input-vcid',
        data: {
          callback: (vcid) => {
            this.virtual_cast_id = vcid;
          },
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.reserve_top {
  display: flex;
  justify-content: center;
  padding: 90px;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  margin: 0 calc((100vw - 100%) / (-2));
  position: relative;

  @include sm-view {
    padding: 45px;
    font-size: 25px;
  }

  &.text {
    background-color: var(--blue-main);
  }

  &.online {
    background-color: var(--orange-main);
  }

  &.virtual {
    background-color: var(--green-main);
  }

  &_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: .2;
    font-size: 130px;

    @include sm-view {
      font-size: 90px;
    }

    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%);
      font-size: 0.384em;
    }
  }

  &_text {
    text-align: center;
    i {
      font-size: 46px;
      margin-right: 10px;
      @include sm-view {
        font-size: 30px;
        margin-right: 10px;
      }
    }
  }
}
.content {
  &_top {
    text-align: center;

    &_wrap {
      background-color: #fff;
      display: flex;
      justify-content: center;
      margin: 0 calc((100vw - 100%) / (-2));
    }

    &_title {
      font-size: 40px;

      @include sm-view {
        font-size: 25px;
      }
    }

    &_subtitle {
      display: inline-block;
      background-color: var(--orange-main);
      padding: .6em 1.5em;
      border-radius: 2em;
      color: #fff;
      font-weight: 700;

      @include sm-view {
        padding: 9px 15px;
        line-height: 1;
      }

      &.virtual {
        background-color: var(--green-main);
      }
    }

    &_text {
      display: inline-block;
      p {
        padding: 0 40px;
        max-width: calc(var(--main-contents-width) + var(--main-contents-gutter) * 2);
        text-align: start;
        line-height: 2;

        @include sm-view {
          max-width: 100%;
          padding: 0 var(--main-contents-gutter);
          font-size: 14px;
        }
      }
    }
  }
}
.label {
  @include sm-view {
    width: 150px;
  }
}
.required {
  font-size: 12px;
  color: red;
}
.annotation {
  font-size: 12px;
}
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.bold {
  font-weight: bold;
}
.space {
  margin-top: 8px;
  @include sm-view {
    margin-top: 0;
  }
}

.item {
  margin-right: 10px;
}

.input_text {
  padding: 13px;
  outline: none;
  border: none;
  border-radius: 8px;
  width: 100%;
}
.textarea {
  width: 100%;
  resize: vertical;
  min-width: 152.5px;
  border: none;
  outline: none;
  border-radius: 10px;
  padding: 20px;
}
.count {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.other_text_label {
  font-size: 14px;
  margin-bottom: 3px;
}

.who5itemTitle {
  font-size: 15px;

  @include sm-view {
    font-size: 14px;
  }
}

.optionList {
  padding-bottom: 8px;
}

.optionItem {
  display: flex;
  align-items: center;

  & + & {
    margin-top: 6px;
  }

  label {
    margin-left: 3px;
    font-size: 14px;
    line-height: 1;

    @include sm-view {
      font-size: 13px;
    }
  }
}
.red {
  color: var(--pink);
}
.notice {
  padding: 0 40px;
}
.thumbnail {
  width: 100px;
  padding-top: 100px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 20px;
  border: 1px solid rgba(#aaa, 0.3);
  &_wrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
  }
  &_btn {
    display: inline-block;
    border-radius: 22px;
    padding: 6px 15px;
    font-size: 14px;
    border: 1px solid var(--black);
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
}

.vcid {
  width: 200px;
}
.force_link {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}
</style>
