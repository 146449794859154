<template>
  <div>
    <loader-simple
      :flag="flag.loader"
      v-if="flag.loader"
    />
    <user-box
      v-else
      type="inner"
      :showId="true"
      :user="riskApplication.targetUser"
      :ownUser="user"
      :bg="userBoxColor(riskApplication.targetUser)"
      :link="targetUserLink"
      :targetBlank="true"
      :showCocomoniBtn="true"
    >
      <template v-slot:main>
        <div
          v-if="showNew"
          :class="[$style.boxTag, $style.new]"
        >NEW</div>
        <div
          v-if="showDraftedTag"
          :class="[$style.boxTag, $style.drafted]"
        >下書き</div>
        <div>
          <spacer :y="2"/>
          <slot name="body">
            <div>

              <template v-if="inProgress">
                <ul :class="$style.detail">
                  <li>
                    <label-box label="申請者" color="gray" size="sm" :class="$style.label"/>
                    <p :class="$style.content">{{ riskApplication.applyerUser.username }}</p>
                  </li>
                  <li>
                    <label-box label="申請日時" color="gray" size="sm" :class="$style.label"/>
                    <p :class="$style.content">{{ riskApplication.applied_at ? formatTimestamp(riskApplication.applied_at, DATE_FORMAT) : '-' }}</p>
                  </li>
                  <li v-if="showReviewedAt">
                    <label-box label="内部評価申請日時" color="gray" size="sm" :class="$style.label"/>
                    <p :class="$style.content">{{ formatTimestamp(riskApplication.reviewed_at, DATE_FORMAT) }}</p>
                  </li>
                  <li v-if="showApprovalActionAt">
                    <label-box :label="getApprovalActionAtLabel()" color="gray" size="sm" :class="$style.label"/>
                    <p :class="$style.content">{{ formatTimestamp(riskApplication.approval_action_at, DATE_FORMAT) }}</p>
                  </li>
                  <li>
                    <label-box label="申請ステータス" color="gray" size="sm" :class="$style.label"/>
                    <p :class="$style.content">
                      <span
                        v-if="riskApplication.is_risk_reduced"
                        :class="[$style.boxTagInner, $style.reduceRisk]"
                      >リスク取り下げ</span>
                      <span>{{ helper.master.labels.riskApplication.riskType[riskApplication.risk_type].label }}</span>
                    </p>
                  </li>
                </ul>
              </template>

              <template v-else>
                <div>
                  <ul :class="$style.detail">
                    <li>
                      <label-box label="種別" color="gray" size="sm" :class="$style.label"/>
                      <p :class="$style.content">
                        <span
                          v-if="riskApplication.is_risk_reduced"
                          :class="[$style.boxTagInner, $style.reduceRisk]"
                        >リスク取り下げ</span>
                        <span>{{ helper.master.labels.riskApplication.riskType[riskApplication.risk_type].label }}</span>
                      </p>
                    </li>
                    <li>
                      <label-box label="報告基準" color="gray" size="sm" :class="$style.label"/>
                      <p :class="$style.content">{{ getReportReasonTitle(riskApplication) }}</p>
                    </li>
                    <li>
                      <label-box label="リスク判断" color="gray" size="sm" :class="$style.label"/>
                      <p :class="$style.content">{{ helper.master.labels.counselingTypes[riskApplication.counseling_type] }}（{{ displayDate }}）</p>
                    </li>

                    <!-- 申請アクションにまつわる各種日付情報 -->
                    <li v-if="!isTeacher">
                      <label-box label="申請日時" color="gray" size="sm" :class="$style.label"/>
                      <p :class="$style.content">{{ riskApplication.applied_at ? formatTimestamp(riskApplication.applied_at, DATE_FORMAT) : '-' }}</p>
                    </li>
                    <li v-if="showReviewedAt">
                      <label-box label="内部評価申請日時" color="gray" size="sm" :class="$style.label"/>
                      <p :class="$style.content">{{ formatTimestamp(riskApplication.reviewed_at, DATE_FORMAT) }}</p>
                    </li>
                    <li v-if="showApprovalActionAt">
                      <label-box :label="getApprovalActionAtLabel()" color="gray" size="sm" :class="$style.label"/>
                      <p :class="$style.content">{{ formatTimestamp(riskApplication.approval_action_at, DATE_FORMAT) }}</p>
                    </li>
                    <!-- 学校管理者のみ（一覧上に表示されるのは報告済み申請のみ） -->
                    <li v-if="isTeacher">
                      <label-box label="報告日時" color="gray" size="sm" :class="$style.label"/>
                      <p :class="$style.content">{{ formatTimestamp(riskApplication.approval_action_at, DATE_FORMAT) }}</p>
                    </li>

                    <!-- 一覧上でのコメント機能は不要 -->
                    <!-- <li v-if="showManagerComment">
                      <label-box label="管理者コメント" color="gray" size="sm" :class="$style.label"/>
                      <div :class="$style.content">
                        <loader-simple :flag="flag.loaderCommentSubmitting">
                          <div :class="$style.commentArea">
                            <textarea
                              type="text"
                              :class="$style.input"
                              v-model.trim="managerComment"
                              :disabled="flag.loaderCommentSubmitting"
                            />
                            <spacer :y="1" :class="$style.only_sp"/>
                            <div @click="clickSubmitMessage">
                              <i
                                class="fa-solid fa-pen-to-square"
                                :class="$style.messagePen"
                              />
                            </div>
                          </div>
                        </loader-simple>
                        <spacer :y="1" />
                        <ul>
                          <li
                            v-for="message in displayMessages"
                            :key="message.id"
                            :class="[$style.riskApplicationMessage, $style[getCommentClass(message)]]"
                          >
                            <div>
                              <p>{{ getPostUserDisplayName(message) }}　[{{ message.created_at }}]</p>
                            </div>
                            <div class="n2br">
                              <p>{{ message.message }}</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li> -->
                  </ul>
                  <spacer :y="2"/>
                  <div>
                    <label-box
                      label="申請者"
                      color="gray"
                      size="sm"
                      :class="$style.label"
                      smAlign="left"
                    />
                    <spacer :y="1"/>
                    <user-box
                      type="inner"
                      :showId="false"
                      :user="riskApplication.applyerUser"
                      :showAge="false"
                    />
                  </div>
                  <spacer :y="2"/>
                  <div>
                    <label-box
                      label="承認者"
                      color="gray"
                      size="sm"
                      :class="$style.label"
                      smAlign="left"
                    />
                    <spacer :y="1"/>
                    <user-box
                      type="inner"
                      :showId="false"
                      :user="riskApplication.approverUser"
                      :showAge="false"
                    />
                  </div>
                </div>
              </template>

            </div>
          </slot>
          <div>
            <spacer :y="3"/>
            <slot name="btn"/>
          </div>
        </div>
      </template>
    </user-box>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';

import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';
import raf from '@/mixins/riskApplicationFunctions';

const DATE_FORMAT = 'YYYY年MM月DD日 HH:mm';

export default {
  name: 'RiskApplicationBox',
  mixins: [
    cf,
    raf,
  ],
  components: {
    UserBox,
    LabelBox,
    Spacer,
    LoaderSimple,
  },
  props: {
    riskApplication: {
      type: Object,
    },
    targetUserLink: {
      type: String,
    },
    hideDraftedTag: {
      type: Boolean,
    },
    manageReadFlag: {
      type: Boolean,
    },
    forceShowNew: {
      type: Boolean,
    },
  },
  data() {
    return {
      DATE_FORMAT,
      flag: {
        loader: true,
        // loaderCommentSubmitting: false, // コメント送信中
      },
      displayDate: null,
      managerComment: '',
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    showNew() {
      if (this.forceShowNew) {
        // "NEW"強制表示
        return true;
      }
      if (!this.manageReadFlag) {
        return false;
      }
      const readflagManagementTargets = [ // 既読管理対象
        // 事務局用
        this.helper.master.labels.riskApplication.status.REVIEWED,
        this.helper.master.labels.riskApplication.status.APPROVER_DRAFTED,
        // 事務局/学校管理者用
        this.helper.master.labels.riskApplication.status.APPROVED,
        this.helper.master.labels.riskApplication.status.DENIED,
      ];
      if (!readflagManagementTargets.includes(this.riskApplication.status)) {
        return false;
      }
      return this.user.readflags && !this.user.readflags.riskApplications.includes(this.riskApplication.id);
    },
    showDraftedTag() {
      if (this.showNew) {
        // NEWとの二重表示は不可
        return false;
      }
      if (this.hideDraftedTag) {
        // 呼び出し元から表示不可と指定された場合
        return false;
      }
      const STATUS = this.helper.master.labels.riskApplication.status;
      const draftedStatuses = [
        STATUS.APPLYER_DRAFTED,
        STATUS.REVIEWER_DRAFTED,
        STATUS.APPROVER_DRAFTED,
      ];
      return draftedStatuses.includes(this.riskApplication.status);
    },
    inProgress() {
      return this.riskApplication.status < this.helper.master.labels.riskApplication.status.APPROVED;
    },
    isTeacher() {
      return this.user && [11, 12].includes(this.user.account_type);
    },
    isOperator() {
      return this.user && [31].includes(this.user.account_type);
    },
    // showManagerComment() {
    //   return this.riskApplication.status === this.helper.master.labels.riskApplication.status.APPROVED && (this.isTeacher || this.isOperator);
    // },
    // displayMessages() {
    //   if (this.riskApplication.messages && this.riskApplication.messages.length > 0) {
    //     return this.riskApplication.messages.toReversed(); // created_atの降順に変換
    //   }
    //   return [];
    // },
    showReviewedAt() {
      // 学校管理者には見せない
      if (this.isTeacher) {
        return false;
      }
      return !!this.riskApplication.reviewed_at;
    },
    showApprovalActionAt() {
      // 学校管理者には見せない
      if (this.isTeacher) {
        return false;
      }
      return !!this.riskApplication.approval_action_at;
    },
    showReceiptDate() {
      // 学校管理者には見せない
      return !this.isTeacher;
    },
  },
  async beforeMount() {
    // 申請書のステータスが完了状態の場合は「受付日/実施日」を設定する
    if (!this.inProgress) {
      await this.getDisplayDate();
    }

    this.flag.loader = false;
  },
  methods: {
    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if ((user.flag !== 1 || user.school) && user.school.length && user.school[0].flag !== 1) {
        color = 'gray';
      } else if (user.flag !== 1) {
        color = 'gray';
      } else if (user.temporary) {
        color = 'silver';
      } else if (user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },
    getReportReasonTitle(riskApplication) {
      return raf.getRiskReportReasonTitle({
        labels: this.helper.master.labels,
        riskType: riskApplication.risk_type,
        riskTypeTags: JSON.parse(riskApplication.risk_type_tags),
        riskTypeTagNote: riskApplication.risk_type_tag_note,
        onlyTagsLabel: true,
      });
    },
    getApprovalActionAtLabel() {
      if (this.riskApplication.status === this.helper.master.labels.riskApplication.status.APPROVED) {
        return '承認日時';
      }
      if (this.riskApplication.status === this.helper.master.labels.riskApplication.status.DENIED) {
        return '差し戻し日時';
      }
      return null;
    },
    async getDisplayDate() {
      const COUNSELINGTYPE_TEXT = 2;
      let counselingData;
      if (this.riskApplication.counseling_type === COUNSELINGTYPE_TEXT) {
        // テキスト健康相談: 受付日を表示する
        if (this.riskApplication.counselingData) {
          counselingData = this.riskApplication.counselingData;
        } else {
          // 健康相談データが引き渡されていない場合のみ個別で取得する
          const response = await this.axios({
            method: 'GET',
            url: '/v1/messagegroup/get/detail',
            params: {
              id: this.riskApplication.foreign_id,
              isMessages: 0,
              isWho5: 0,
            },
          });
          counselingData = response.data.detail;
        }
        this.displayDate = moment(counselingData.created_at).format(DATE_FORMAT);
      } else {
        // オンライン/バーチャル健康相談: 相談実施日を表示する
        if (this.riskApplication.counselingData) {
          counselingData = this.riskApplication.counselingData;
        } else {
          // 健康相談データが引き渡されていない場合のみ個別で取得する
          const response = await this.axios({
            method: 'GET',
            url: '/v1/reserve/get/detail',
            params: {
              id: this.riskApplication.foreign_id,
              counseling_type: this.riskApplication.counseling_type,
              isRisk: 0,
              isWho5: 0,
            },
          });
          counselingData = response.data.reserve;
        }
        this.displayDate = moment(counselingData.schedule.date).format(DATE_FORMAT);
      }
    },
    // async clickSubmitMessage() {
    //   if (this.flag.loaderCommentSubmitting || !this.managerComment) {
    //     return;
    //   }
    //   if (!confirm('コメントを投稿します。よろしいですか？')) {
    //     return;
    //   }
    //   // 投稿
    //   this.flag.loaderCommentSubmitting = true;
    //   try {
    //     await this.axios({
    //       method: 'POST',
    //       url: '/v1/riskApplications/message',
    //       data: {
    //         risk_application_id: this.riskApplication.id,
    //         user_id: this.user.id,
    //         message: this.managerComment,
    //       },
    //     });
    //     this.$emit('risk-application-message-posted'); // 呼び出し元の表示情報を更新する
    //     alert('コメントを投稿しました。');
    //     this.managerComment = '';
    //   } catch (error) {
    //     if (error.message) console.log(error.message);
    //     else console.error(error);
    //     alert('コメントの投稿に失敗しました');
    //   } finally {
    //     this.flag.loaderCommentSubmitting = false;
    //   }
    // },
    getPostUserDisplayName(message) {
      if (message.user.account_type === 31) {
        return '事務局';
      }
      return message.user.username;
    },
    getCommentClass(message) {
      return message.user.account_type === 31 ? 'operatorsMessage' : 'teachersMessage';
    },
  },
};
</script>

<style lang="scss" module>
.detail {
  li {
    display: flex;
    align-items: flex-start;
    .content {
      // font-size: 14px;
      margin-top: 3px;
      flex: 1;
      word-break: break-all;
    }
    .riskApplicationMessage {
      display: block;
    }
    &:not(:last-child) {
      margin-bottom: 15px;
      &.riskApplicationMessage {
        margin-bottom: 5px;
      }
    }
  }
  @include sm-view {
    font-size: 14px;
    li {
      display: block;
      .label {
        width: 100%;
        margin: 0 0 .5em;
        text-align: left;
        padding: .5em;
      }
      &:not(:first-child) {
        margin-top: 1.4em;
      }
    }
  }
}
.label {
  width: 130px;
  margin-right: 20px;
}
.riskApplicationMessage {
  padding: 12px;
  border-radius: 4px;
  @include sm-view {
    font-size: 13px;
  }
  &.operatorsMessage {
    background-color: rgba(var(--blue-main-rgb), 0.2);
  }
  &.teachersMessage {
    background-color: rgba(var(--orange-main-rgb), .15);
  }
}
// .commentArea {
//   display: flex;
//   align-items: center;
//   @include sm-view {
//     margin-top: 10px;
//     text-align: right;
//   }
//   .input {
//     width: calc(100% - 45px);
//     padding: 13px;
//     border-radius: 8px;
//     background-color: var(--gray-sub);
//     border: none;
//     outline: none;
//     @include sm-view {
//       width: calc(100% - 25px);
//     }
//   }
//   .messagePen {
//     font-size: 25px;
//     font-weight: bold;
//     color: var(--gray-side);
//     cursor: pointer;
//     margin-left: 20px;
//     @include sm-view {
//       margin-top: 10px;
//     }
//   }
// }
.only_sp {
  display: none;
  @include sm-view {
    display: block;
  }
}
.boxTag {
  position: absolute;
  top: -10px;
  left: auto;
  right: -8px;
  z-index: 1;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;

  &_wrap {
    position: relative;
  }

  @include sm-view {
    padding: 3px 7px 2px;
    font-size: 11px;
    right: -4px;
    top: -4px;
  }

  &.new {
    background-color: var(--pink);
    color: #fff;
  }
  &.drafted {
    background-color: var(--gray-sub);
  }
}
.boxTagInner {
  font-weight: bold;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;
  margin-right: .5rem;

  @include sm-view {
    padding: 3px 7px 2px;
    font-size: 11px;
  }

  &.reduceRisk {
    background-color: var(--gray-main);
    color: #fff;
  }
}
</style>
