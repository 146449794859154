<template>
  <div>
    <spacer :y="8.5" :md-y="4" :sm-y="2"/>
    <loader-simple :flag="flag.loader">
      <div v-if="!flag.loader">
        <carousel
          :items-to-show="1"
          :wrapAround="true"
          dir="ltr"
          :autoplay="10000"
          :pauseAutoplayOnHover="true">
            <slide
              v-for="slide in sliders"
              :key="slide.name">
                <div @click="clickSlider(slide.name)" :class="$style.sliderOuter">
                  <a :href="`/teacher/?tab=${slide.to}`">
                    <div
                      class="slide"
                      :class="[
                        $style.carousel__item,
                        $style.slider_setting,
                        `${slide.name}`,
                        sliderUnvClass(slide),
                      ]"></div>
                  </a>
                </div>
            </slide>
              <template #addons>
                <navigation />
                <pagination />
              </template>
          </carousel>

        <tab-nav
          id="tab"
          :items="tabItems"
          :isActive="isActive"
          v-on:sendIsActiveValue="receiveIsActiveValue"/>
        <div>
          <div
            v-for="(tab, key) in tabItems"
            :key="tab.name"
            :id="`panel-${tab.name}`"
            :aria-labelledby="`tab-${tab.name}`"
            :aria-hidden="key !== isActive">
            <component
              v-if="key === isActive"
              :is="`${tab.name}`"
              :school="school"
              @updateSchool="getSchoolDetail" />
          </div>
        </div>
      </div>
    </loader-simple>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import TwoColumn from '@/views/layouts/TwoColumn.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import TabNav from '@/views/components/TabNav';
import ContentsBox from '@/views/components/ContentsBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import StackItem from '@/views/components/StackItem.vue';
import Spacer from '@/views/components/Spacer.vue';
import Students from '@/views/pages/Teacher/tabs/Students.vue';
// import Cocomoni from '@/views/pages/Teacher/tabs/Cocomoni.vue'; // 旧ココモニ
import Cocomoni from '@/views/pages/Teacher/tabs/CocomoniNew.vue'; // 新ココモニ
import Myschool from '@/views/pages/Teacher/tabs/Myschool.vue';
import Reserve from '@/views/pages/Teacher/tabs/Reserve.vue';
import History from '@/views/pages/Teacher/tabs/History.vue';
import Counselors from '@/views/pages/Teacher/tabs/Counselors.vue';
import Qr from '@/views/pages/Teacher/tabs/QR.vue';
import Units from '@/views/pages/Teacher/tabs/Units.vue';
import RiskManagement from '@/views/pages/Teacher/tabs/RiskManagement.vue';
import {
  Carousel,
  Slide,
  Pagination,
  Navigation,
} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'Home',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    TwoColumn,
    UserBox,
    LabelBox,
    TabNav,
    ContentsBox,
    LoaderSimple,
    StackItem,
    Spacer,
    Students,
    Cocomoni,
    Myschool,
    Reserve,
    History,
    Counselors,
    Qr,
    Units,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    RiskManagement,
  },
  data() {
    return {
      sliders: [
        { name: 'virtual', to: 'reserve' },
        { name: 'reserve', to: 'myschool' },
        { name: 'text', to: 'history' },
        { name: 'online', to: 'reserve' },
      ],
      flag: {
        loader: true,
      },
      tabItems: {
        myschool: {
          label: 'ホーム',
          name: 'myschool',
          new: false,
        },
        cocomoni: {
          label: 'ココモニ',
          name: 'cocomoni',
          new: false,
        },
        students: {
          label: 'アカウント',
          name: 'students',
          new: false,
        },
        reserve: {
          label: '予約',
          name: 'reserve',
          new: false,
        },
        history: {
          label: '履歴',
          name: 'history',
          new: false,
        },
        riskManagement: {
          label: 'リスク管理',
          name: 'riskManagement',
          new: false,
        },
        counselors: {
          label: '医師・心理士',
          name: 'counselors',
          new: false,
        },
        units: {
          label: 'グループ',
          name: 'units',
          new: false,
        },
        qr: {
          label: '管理',
          name: 'qr',
          new: false,
        },
      },
      isActive: 'myschool',
      admins: {
        main: null,
        sub: null,
      },
      school: null,
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    baseURL() {
      return `${location.protocol}//${location.host}`;
    },
    myTabItems() {
      const items = cloneDeep(this.tabItems);
      const schoolAdministrators = [
        11, // 学校管理者
        12, // 学校副管理者
      ];
      if (!schoolAdministrators.includes(this.user.account_type)) {
        delete items.riskManagement;
      }
      return items;
    },
  },
  created() {
    if (this.user.email) {
      if (this.user.account_type > 20 || this.user.account_type < 10) this.redirect2top();
      else {
        this.getSchoolDetail();
        this.changeTab();
      }
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          if (this.user.account_type > 20 || this.user.account_type < 10) this.redirect2top();
          else {
            this.getSchoolDetail();
            this.changeTab();
          }
        }
      });
    }
  },
  watch: {
    $route() {
      this.changeTab();
    },
    user: {
      deep: true,
      handler() {
        this.getSchoolDetail();
      },
    },
  },
  methods: {
    changeTab() {
      // クエリでどのタブを表示させるか判定
      const query = this.$route.query;
      const tabNames = Object.keys(this.tabItems);
      if (query.tab && tabNames.includes(query.tab)) this.isActive = query.tab;
      else this.isActive = 'myschool';
    },
    receiveIsActiveValue(name) {
      this.isActive = name;
      this.$router.replace(`/teacher/?tab=${name}`);
    },
    clickSlider(name) {
      this.$router.replace(`/teacher/?tab=${this.sliders[name].to}`);
      // タブまでスクロール
      const elem = document.getElementById('tab');
      const position = elem.getBoundingClientRect().top;
      scrollBy(0, position - 77);
    },

    getSchoolDetail() {
      // 学校情報はstore.user.schoolを使う
      this.school = this.user.school[0];
      // 主管理
      this.admins.main = this.school.mainUser.length ? this.school.mainUser[0] : null;
      // 副管理
      this.admins.sub = this.school.subUser.length ? this.school.subUser[0] : null;
      this.flag.loader = false;
    },

    /** ログイン済みユーザはトップリダイレクト */
    redirect2top() {
      const ls = cf.getLocalStorage('wtt');
      // ログアウト時にログインへリダイレクトされた場合の対応
      if (!ls.jwt) return;

      const accountType = this.user.account_type;
      const customerTypes = [1, 2, 3];
      const counselorTypes = [21, 22];
      const teacherTypes = [11, 12];
      const operatorTypes = [31];
      let path = '';
      if (this.user.account_type) {
        if (customerTypes.includes(accountType)) {
          path = { path: '/' };
        } else if (counselorTypes.includes(accountType)) {
          path = { path: '/counselor/' };
        } else if (teacherTypes.includes(accountType)) {
          path = { path: '/teacher/' };
        } else if (operatorTypes.includes(accountType)) {
          path = { path: '/operator/' };
        }
      }
      this.$router.push(path);
    },
    // 大学生用のclass付与
    sliderUnvClass(slide) {
      return (this.user.school[0].school_type === 4) && (slide.name === 'text' || slide.name === 'online') ? 'unv' : '';
    },
  },
};
</script>

<style lang="scss" module>
.date {
  font-size: 12px;
}
.link {
  color: var(--black);
}
.labels {
  display: flex;
  flex-wrap: wrap;
}
.label {
  width: 80px;
}
.slider {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  padding: 30px;
  display: flex;
  @include sm-view {
    display: block;
  }
  .left {
    width: 75%;
    .listSchool {
      li {
        display: flex;
        align-items: center;

        &:not(:first-child) {
          margin-top: 10px;
        }

        .schoolName {
          font-size: 25px;
        }

        .icon {
          color: var(--orange-main);
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
    .listUser {
      margin-top: 20px;
      .icon {
        font-size: 20px;
        color: var(--orange-main);
        margin-right: 10px;
      }
      ul {
        margin-top: 8px;
      }
      .assistant {
        font-size: 14px;
        margin-top: 8px;
      }
      li {
        .user {
          font-size: 14px;
          display: inline-block;
          background-color: var(--gray-sub);
          padding: 4px 12px;
          border-radius: 4px;
        }
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
    @include sm-view {
      width: 100%;
      li {
        .user {
          font-size: 10px;
        }
      }
    }
  }
  .right {
    width: 25%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    .qr {
      padding: 20px;
      width: 100%;
    }
    .count {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      &_value {
        font-weight: bold;
        font-size: 50px;
      }
    }
    @include sm-view {
      width: 100%;
      margin-top: 30px;
      flex-flow: row-reverse;
      .qr {
        width: 50%;
      }
      .count {
        width: 50%;
      }
    }
  }
}
.carousel__item {
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sliderOuter {
  width: 100%;
  @include xs-view {
    width: 120%;
  }
}
.slider_setting {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: percentage(453/1064);
  @include sm-view {
    padding-top: percentage(1000 / 780);
  }
}
</style>

<style lang="scss">
.slide {
  $names: 'reserve', 'text', 'online';
  @each $name in $names {
    &.#{$name} {
      background-image: url("/img/slider/#{$name}.jpg");
      @include sm-view {
        background-image: url("/img/slider/#{$name}_sp.jpg");
      }
    }
  }
}
.carousel__prev {
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
}
.carousel__next {
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
}
.carousel__prev, .carousel__next {
  color: gray;
  font-size: 8px;
  background-color: rgb(248, 248, 248);
  @include sm-view {
    display: none;
  }
}
.carousel__pagination-button {
  margin: var(--vc-pgn-margin);
  padding: 3px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  background-color: silver;
}
.carousel__pagination-button {
  background-color: silver;
}
.carousel__pagination-button--active {
  background-color: #333333;
}
</style>
