<template>
  <div>
    <loader-simple :flag="loaderFlag">
      <div v-show="!loaderFlag">
        <ul>
          <stack-item v-if="user.school[0].billing_type.billing_type === 1
            && user.school[0].textCount <= 0
            && !hasProgress
            && !ignores()">
            <contents-box>
              <p>ご利用上限回数に達しているため受付できません</p>
            </contents-box>
            </stack-item>
          <stack-item v-else-if="!hasProgress">
            <contents-box
              title="受付中"
              icon="<i class='fa-regular fa-comment-lines'></i>"
              iconColor="blue"
            >
              <p>現在、回答待ちの相談はありません。</p>
              <p v-if="!hasProgress && !hasAnswerd">まずは気軽にご相談ください</p>
              <spacer :y="2"/>
              <btn-container>
                <basic-btn to="/reserve/text/">{{ (!hasProgress && !hasAnswerd) ? 'テキスト健康相談について' : '新しくテキスト健康相談を作成する' }}</basic-btn>
              </btn-container>
            </contents-box>
          </stack-item>

          <stack-item v-if="hasProgress">
            <contents-box
              title="相談中"
              icon="<i class='fa-regular fa-comment-lines'></i>"
              iconColor="blue"
            >
              <spacer :y="1" />
              <stack-item
                v-for="counseling in user.counseling.texts.progress"
                :key="counseling.id">
                <contents-box type="inner">
                  <p :class="$style.create">{{ formatTimestamp(counseling.created_at, 'YYYY年MM月DD日（HH:mm）') }}</p>
                  <spacer :y="1"/>
                  <div :class="$style.check_wrap">
                    <div :class="$style.check_list">
                      <div :class="$style.check">お悩み</div>
                      <div :class="[$style.value, $style.type]">
                        <p
                          v-for="type in counseling.type"
                          :key="type">{{ helper.master.labels.texts.type[type] }}<span v-if="counseling.other_text && type === 6">（{{ counseling.other_text }}）</span></p>
                      </div>
                    </div>
                    <div v-if="counseling.scoreWho5 >= 0"
                      :class="$style.check_list">
                      <div :class="$style.check">ココモニ</div>
                      <div :class="$style.value">
                        <div :class="$style.who5_top">
                          <p :class="$style.who5_score"><span :class="$style.who5_score_label">ココモニチェック</span>{{ counseling.scoreWho5 }}点</p>
                          <div :class="$style.open" @click="flag.show_unsolved = !flag.show_unsolved">
                            <p v-if="!flag.show_unsolved">詳細を表示<i class="fa-solid fa-angles-down"/></p>
                            <p v-else>詳細を非表示<i class="fa-solid fa-angles-up"/></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-show="flag.show_unsolved" :class="$style.who5_detail">
                      <spacer :y="3"/>
                      <ul :class="$style.who5_detail_list">
                        <li v-for="(item, i) in helper.master.who5.items"
                          :key="i">
                            <p :class="$style.who5_label">{{item.label}}</p>
                            <p :class="$style.who5_answer">{{helper.master.who5.labels[counseling.who5Answer.answers[i]]}}</p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <spacer :y="3"/>

                  <div
                    :class="[$style.waitingCommentBox]"
                    v-html="counseling.messages[0].message" />
                </contents-box>
              </stack-item>
            </contents-box>
          </stack-item>

          <stack-item v-if="hasAnswerd">
            <contents-box title="回答済" icon="<i class='fa-solid fa-user-doctor'></i>">
              <stack-item
                :class="$style.item"
                v-for="n of itemNum"
                :key="n">
                <div
                  :class="$style.new"
                  v-if="isNew(user.counseling.texts.answered[n - 1].id)">NEW</div>
                <contents-box type="inner">
                  <div :class="$style.check_wrap">
                    <div :class="$style.check_list">
                      <div :class="$style.check">受付日時</div>
                      <div :class="[$style.value, $style.type]">
                        <p>{{ formatTimestamp(user.counseling.texts.answered[n - 1].created_at, 'YYYY年MM月DD日（HH:mm）') }}</p>
                      </div>
                    </div>
                    <div :class="$style.check_list">
                      <div :class="$style.check">回答日時</div>
                      <div :class="[$style.value, $style.type]">
                        <p>{{ formatTimestamp(user.counseling.texts.answered[n - 1].messages[1].updated_at, 'YYYY年MM月DD日（HH:mm）') }}</p>
                      </div>
                    </div>
                    <div :class="$style.check_list">
                      <div :class="$style.check">お悩み</div>
                      <div :class="[$style.value, $style.type]">
                        <p
                          v-for="type in user.counseling.texts.answered[n - 1].type"
                          :key="type">{{ helper.master.labels.texts.type[type] }}<span v-if="user.counseling.texts.answered[n - 1].other_text && type === 6">（{{ user.counseling.texts.answered[n - 1].other_text }}）</span></p>
                      </div>
                    </div>
                    <div v-if="user.counseling.texts.answered[n - 1].scoreWho5"
                      :class="$style.check_list">
                      <div :class="$style.check">ココモニ</div>
                      <div :class="$style.value">
                        <div :class="$style.who5_top">
                          <p :class="$style.who5_score"><span :class="$style.who5_score_label">ココモニチェック</span>{{ user.counseling.texts.answered[n - 1].scoreWho5 }}点</p>
                          <div :class="$style.open" @click="changeWho5OpenIds(n)">
                            <p v-if="!who5openIds.includes(n)">詳細を表示<i class="fa-solid fa-angles-down"/></p>
                            <p v-else>詳細を非表示<i class="fa-solid fa-angles-up"/></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="who5openIds.includes(n)" :class="$style.who5_detail">
                      <spacer :y="3"/>
                      <ul :class="$style.who5_detail_list">
                        <li v-for="(item, i) in helper.master.who5.items"
                          :key="i">
                            <p :class="$style.who5_label">{{item.label}}</p>
                            <p :class="$style.who5_answer">{{helper.master.who5.labels[user.counseling.texts.answered[n - 1].who5Answer.answers[i]]}}</p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <spacer :y="3"/>

                  <div
                    :class="[$style.waitingCommentBox]"
                    v-html="user.counseling.texts.answered[n - 1].messages[0].message" />
                  <spacer :y="2"/>
                  <user-box
                    type="inner"
                    :showId="false"
                    :user="user.counseling.texts.answered[n - 1].messages[1].user"
                    :showAge="false">
                    <template v-slot:btn>
                      <div :class="$style.open" @click="changeOpenIds(user.counseling.texts.answered[n - 1].id)">
                        <p v-if="!openIds.includes(n)">回答を表示<i class="fa-solid fa-angles-down"/></p>
                        <p v-else>回答を非表示<i class="fa-solid fa-angles-up"/></p>
                      </div>
                    </template>
                    <template v-slot:main>
                      <div v-if="openIds.includes(user.counseling.texts.answered[n - 1].id)">
                        <spacer :y="1"/>
                        <div :class="$style.answer" class="n2br">
                          <p v-html="user.counseling.texts.answered[n - 1].messages[1].message"/>
                        </div>
                        <spacer :y="2"/>
                        <btn-container>
                          <basic-btn size="sm" tag="a" @click="clickOnline(n)">この先生にオンライン相談する</basic-btn>
                        </btn-container>
                      </div>
                    </template>
                  </user-box>
                </contents-box>
              </stack-item>
            </contents-box>
          </stack-item>
        </ul>
      </div>
    </loader-simple>
    <spacer :y="8.5"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import UserBox from '@/views/components/UserBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions';
// import moment from 'moment';

export default {
  name: 'Home',
  mixins: [cf],
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    StackItem,
    LoaderSimple,
    UserBox,
    Spacer,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      openIds: [],
      who5openIds: [],
    };
  },
  created() {
  },
  computed: {
    ...mapState(['user', 'helper']),
    hasAnswerd() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.texts
      && this.user.counseling.texts.answered
      && this.user.counseling.texts.answered.length) {
        flag = true;
      }
      return flag;
    },
    hasProgress() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.texts
      && this.user.counseling.texts.progress
      && this.user.counseling.texts.progress.length) {
        flag = true;
      }
      return flag;
    },
    hasFutureReservation() {
      let flag = false;
      if (this.user.counseling
      && this.user.counseling.reserves
      && this.user.counseling.reserves.future
      && this.user.counseling.reserves.future.length) {
        flag = true;
      }
      return flag;
    },
    itemNum() {
      let num = 0;
      if (this.user.counseling
      && this.user.counseling.texts
      && this.user.counseling.texts.answered
      && this.user.counseling.texts.answered.length) {
        num = this.user.counseling.texts.answered.length < 4 ? this.user.counseling.texts.answered.length : 3;
      }
      return num;
    },
    loaderFlag() {
      let flag = false;
      if (!this.user.counseling.texts.progress) flag = true;
      return flag;
    },
  },
  methods: {
    ignores() {
      let result = false;
      const ignoreSchools = [];
      if (ignoreSchools.includes(this.user.school[0].id)) result = true;
      return result;
    },
    changeOpenIds(id) {
      if (this.user.readflags && !this.user.readflags.messageGroups.includes(id)) this.registReadFlag(id);
      if (this.openIds.includes(id)) {
        const idx = this.openIds.indexOf(id);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(id);
      }
    },
    changeWho5OpenIds(id) {
      if (this.who5openIds.includes(id)) {
        const idx = this.who5openIds.indexOf(id);
        this.who5openIds.splice(idx, 1);
      } else {
        this.who5openIds.push(id);
      }
    },
    isNew(id) {
      let flag = true;
      if (this.user.readflags && this.user.readflags.messageGroups.includes(id)) {
        flag = false;
      }
      return flag;
    },
    registReadFlag(id) {
      const data = {
        model_type: 5, // messageGroups
        user_id: this.user.id,
        foreign_id: id,
      };
      this.axios({
        method: 'POST',
        url: '/v1/readflag/set/register',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    clickOnline(index) {
      this.$router.replace({
        path: '/reserve/select/',
        query: {
          target: 'counselor',
          counselor: this.user.counseling.texts.answered[index - 1].messages[1].user.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" module>
.slider {
  width: 100%;
  height: 510px;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.create {
  font-weight: bold;
}
.flex {
  display: flex;

  @include sm-view {
    display: block;
  }
}
.link {
  color: black;
}
.user_wrap {
  width: 180px;
}
.user {
  display: flex;
}
.md {
  @include sm-view {
    display: none;
  }
}
.only_sm {
  display: none;
  @include sm-view {
    display: flex;
    margin-top: 16px;
  }
}
.date {
  font-size: 10px;
}

.check_wrap {
  position: relative;
  flex-wrap: wrap;
  align-items: center;

  @include sm-view {
     flex-direction: column;
     align-items: flex-start;
  }
}

.check_list {
  display: flex;
  align-items: flex-start;
  // font-size: 14px;
  @include sm-view {
    font-size: 13px;
    display: block;
  }
  &:not(:first-child) {
    margin-top: 15px;
  }
}

.check {
  background-color: var(--gray-sub);
  font-weight: bold;
  border-radius: 4px;
  padding: 4px 0;
  width: 140px;
  text-align: center;
  margin-right: 12px;
  @include sm-view {
    width: 100%;
    text-align: left;
    padding-left: 8px;
  }
}

.value {
  flex: 1;
  margin-top: 4px;
  font-weight: bold;

  &.type {
    display: flex;
    flex-wrap: wrap;
    p {
      white-space: nowrap;
      &:not(:last-child) {
        &::after {
          margin: 0 5px;
          content: '/';

        }
      }
      span {
        white-space: pre-wrap;
      }
    }
  }

  .who5_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .who5_score {
    margin-right: 8px;
    &_label {
      margin-right: 12px;
    }
  }
}
.open {
  font-size: 12px;
  font-weight: normal;
  color: var(--gray-side);
  cursor: pointer;
  i {
    margin-left: 4px;
  }
  @include sm-view {
    text-align: center;
  }
}
.who5_detail {
  &_list {
    margin: 0 auto;
    width: 95%;
    li {
      // font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:first-child) {
        margin-top: 8px;
      }

      .who5_answer {
        font-weight: bold;
      }

      @include sm-view {
        display: block;
        font-size: 13px;
      }
    }
  }
}

.waitingCommentBox {
  display: flex;
  // font-size: 14px;
  white-space: pre-wrap;

  @include sm-view {
    font-size: 13px;
  }
}

.waitingCommentIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  padding-bottom: 2px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  background-color: #f0f8ff;
  color: var(--blue-main);

  @include sm-view {
    position: absolute;
    top: -26px;
    left: -30px;
  }
}

.QandA_wrap {
  flex: 1;
}

.QAcommentBox {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;

  @include sm-view {
    padding: 8px 8px 8px 12px;
    border-radius: 8px;
  }

  &.question {
    background-color: rgba(var(--blue-main-rgb), 0.2);
  }

  &.answer {
    background-color: rgba(var(--orange-main-rgb), 0.2);
  }

  .text {
    padding-top: 3px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include sm-view {
      font-size: 13px;
    }
  }
}

.commentTypeIcon {
  font-size: 25px;
  line-height: 1;
  font-weight: bold;

  @include sm-view {
    font-size: 18px;
  }

  &.question {
    color: var(--blue-main);
  }

  &.answer {
    color: var(--orange-main);
  }
}

.item {
  position: relative;
}

.icon {
  margin-left: 15px;
  color: var(--gray-main);
  font-size: 20px;
  margin-top: 3px;
}
.answer_at {
  font-weight: bold;
  font-size: 14px;
}
.answer {
  padding: 12px;
  background-color: rgba(var(--orange-main-rgb), .15);
  border-radius: 4px;
  @include sm-view {
    font-size: 13px;
  }
}

.new {
  position: absolute;
  top: -10px;
  left: auto;
  right: -8px;
  z-index: 1;
  background-color: var(--pink);
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;

  @include sm-view {
    padding: 3px 7px 2px;
    font-size: 11px;
    right: -4px;
    top: -4px;
  }
}
</style>
