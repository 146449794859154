/*
 * 共通関数
 * コンポーネントで使用するにはmixinsで読み込む
 * import cf from '@/mixins/commonFunctions';
 * mixins: [cf],
 */

import moment from 'moment';
import { cloneDeep } from 'lodash';
import axios from '../plugins/axios';

// 曜日の日本語表記
moment.locale('ja', {
  weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
});

export default {
  /**
   * localStorage[${key}]の値を返却
   * @param  str key localStorageの対象キー
   * @return obj jsonパース済みオブジェクト
   */
  getLocalStorage(key) {
    let result;
    if (localStorage[key]) {
      result = JSON.parse(localStorage[key]);
    }
    return result;
  },


  /**
   * ローカルストレージに保存
   * @param obj 既存データにマージしたいオブジェクト
   * @param str key localStorageの対象キー
   */
  saveLocalStorage(obj, key = '') {
    if (!key) return;
    const ls = this.getLocalStorage(key);
    const save = ls ? { ...ls, ...obj } : obj;
    const stringify = JSON.stringify(save);
    localStorage.setItem(key, stringify);
  },


  /**
   * localStorage削除
   * @param key localStorageから削除するキー
   */
  deleteLocalStorage(key, key2 = null) {
    if (!key) return;
    if (localStorage[key]) {
      if (!key2) {
        localStorage.removeItem(key);
        console.log(`localStorage[${key}]を削除しました`);
      } else {
        // 特定の子階層のみを削除

        // 指定キー以外を一時ストック
        const stock = {};
        const target = JSON.parse(localStorage[key]);

        const keys = Object.keys(target);
        for (let i = 0; i < keys.length; i += 1) {
          const k = keys[i];
          if (k !== key2) stock[k] = target[k];
        }
        // 一度localstorage[key]ごと削除して
        localStorage.removeItem(key);
        // ストックしておいたkey2の除外データを保存
        this.saveLocalStorage(stock, key);
        console.log(`localStorage[${key}]の[${key2}]を削除しました`);
      }
    } else {
      console.log(`localStorage[${key}]は存在しません`);
    }
  },

  saveTabState(query) {
    const tabName = query.tab || '';
    this.saveLocalStorage({
      saveState: {
        tab: tabName,
      },
    }, 'wtt');
  },

  /** LSにtab情報があれば削除 */
  deleteTabState() {
    const ls = this.getLocalStorage('wtt');
    if (ls.saveState && ls.saveState.tab) {
      // localStorageのsaveStateを削除
      this.deleteLocalStorage('wtt', 'saveState');
    }
  },

  /**
   * 文字列判定
   * @param value
   * @return bool
   */
  isString(inputText) {
    return (typeof inputText === 'string' || inputText instanceof String);
  },


  /**
   * 数値判定（NaNやInfinityは無効）
   * @param value
   * @return bool
   */
  isNumber(value) {
    return Number.isFinite(value);
  },


  /**
   * 整数値の判定
   * @param str
   * @return bool
   */
  checkInteger(str) {
    if (!str) return false;
    return String(str).match(/^[+|-]?[0-9]+$/);
  },


  /**
   * 整数値か小数値の判定
   * @param str
   * @return bool
   */
  checkFloat(str) {
    if (!str) return false;
    return str.match(/^[-|+]?[0-9]*\.[0-9]+$|^[+|-]?[0-9]+$/);
  },


  /**
   * valueが整数値に変更可能かどうか
   * @param value
   * @return bool
   */
  convert2Num(str) {
    if (!str) return null;
    if (!this.checkInteger(str)) return null;
    const result = Number(str);
    return this.isNumber(result) ? result : null;
  },


  /** URLからページ番号取得 */
  getPageNum(route) {
    const query = route.query;
    const page = this.convert2Num(query.page);
    return page || 1;
  },


  /**
   * ページネーション配列の生成
   * @param object args
   *   currentPage
   *   lastPage
   * @return array
   *
   * パターン1
   *   合計ページ数が5未満
   * パターン2
   *   1 2 3 4 ... 50
   * パターン3
   *   1 ... 48 49 50
   * パターン4
   *   1 ... 3 4 5 ... 50
   */
  generatePagination(args) {
    const result = [];
    const last = args.lastPage;
    const prevNext = last > 5;

    // 1ページ完結の場合はスルー
    if (last === 1) return [];

    // prevボタン
    if (prevNext) {
      result.push({
        class: 'prev',
        icon: 'fal fa-angle-left',
        page: args.currentPage - 1,
      });
    }

    // パターン毎にpager作成
    const start = [1, 2, 3];
    const end = [(last - 2), (last - 1), last];
    const num = 4;
    if (!prevNext) {
      for (let i = 0; i < last; i += 1) {
        result.push({ page: i + 1 });
      }
    } else if (start.includes(args.currentPage)) {
      // パターン1
      for (let i = 0; i < num; i += 1) {
        result.push({ page: i + 1 });
      }
      result.push(
        { dot: true },
        { page: last },
      );
    } else if (end.includes(args.currentPage)) {
      // パターン2
      result.push(
        { page: 1 },
        { dot: true },
      );
      for (let i = 0; i < 4; i += 1) {
        result.push({ page: last - (num - i - 1) });
      }
    } else {
      // パターン3
      result.push(
        { page: 1 },
        { dot: true },
        { page: args.currentPage - 1 },
        { page: args.currentPage },
        { page: args.currentPage + 1 },
        { dot: true },
        { page: last },
      );
    }

    // nextボタン
    if (prevNext) {
      result.push({
        class: 'next',
        icon: 'fal fa-angle-right',
        page: args.currentPage + 1,
      });

      // 現ページが最初か最後の場合はdisabledクラス追加
      if (args.currentPage === 1) result[0].class = 'prev disabled';
      if (args.currentPage === last) result[result.length - 1].class = 'next disabled';
    }

    return result;
  },

  /**
   * user_idからuser情報を返却
   */
  async getGuestUser(id) {
    const response = await axios({
      method: 'GET',
      url: '/v1/user/get/byId',
      params: { id },
    });
    return response.data.user;
  },

  /**
   * 環境に応じてstripeのpublicKeyを返却
   */
  getStripePubKey() {
    let STRIPE_PUB_KEY = null;
    switch (process.env.NODE_ENV) {
      case 'production':
        STRIPE_PUB_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY_PROD;
        break;
      default:
        STRIPE_PUB_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY_DEV;
        break;
    }
    return STRIPE_PUB_KEY;
  },


  /** オンライン健康相談をシフト日付昇順でソート */
  upSortReserves(rows) {
    return rows.sort((a, b) => {
      // firefoxで期待した結果が得られないため先にフォーマットしておく
      const A = moment(a.schedule.date).format('YYYY-MM-DD HH:mm:ss');
      const B = moment(b.schedule.date).format('YYYY-MM-DD HH:mm:ss');
      if (moment(A) < (moment(B))) return -1;
      if (moment(A) > (moment(B))) return 1;
      return 0;
    });
  },

  /** オンライン健康相談をシフト日付降順でソート */
  downSortReserves(rows) {
    return rows.sort((a, b) => {
      // firefoxで期待した結果が得られないため先にフォーマットしておく
      const A = moment(a.schedule.date).format('YYYY-MM-DD HH:mm:ss');
      const B = moment(b.schedule.date).format('YYYY-MM-DD HH:mm:ss');
      if (moment(A) > (moment(B))) return -1;
      if (moment(A) < (moment(B))) return 1;
      return 0;
    });
  },

  /** テキスト健康相談を作成日昇順でソート */
  upSortTexts(rows) {
    return rows.sort((a, b) => {
      const A = moment(a.created_at).format('YYYY-MM-DD HH:mm:ss');
      const B = moment(b.created_at).format('YYYY-MM-DD HH:mm:ss');
      if (moment(A) < moment(B)) return -1;
      if (moment(A) > moment(B)) return 1;
      return 0;
    });
  },

  /** 営業日計算 */
  calcSalesMinDate(holiday) {
    const today = new Date();
    // 基本は３日後
    let result = moment(today);
    const holidays = holiday.list;
    holidays.sort((a, b) => {
      if (a.date < b.date) return -1;
      return 1;
    });
    /** 営業日計算
     * 土日及び祝日の日はcheckNoを追加せず、それ以外の日には追加する
     * これが3営業日になったらそれまでに経過した日数をresultに反映
     * （土日と祝日の処理を順番に処理してしまうと、追加した日数の日に対して処理できなくなる）
     */
    let checkNo = 0;
    let addDay = 0;
    while (checkNo < 3) {
      addDay += 1; // 日数を追加
      const targetDate = moment(today).add(addDay, 'days').startOf('day').format('YYYY-MM-DD');
      const targetDayNo = moment(targetDate).format('e');
      const isHoliday = holidays.some((targetHoliday) => {
        if (targetHoliday.date === targetDate) return true;
        return false;
      });
      if (![0, 6].includes(Number(targetDayNo)) && !isHoliday) {
        checkNo += 1;
      }
    }
    result = result.add(addDay, 'd');
    result = result.toDate();
    return result;
  },
  /**
   * 年度(yyyy)を取得する
   * @param {*} date 
   * @returns yyyy
   */
  getFiscalYear(date) {
    return moment(date).clone().add(-3, 'months').year(); // 対象月から3か月前を計算し、その年の部分を抽出する
  },
  /**
   * ユーザ名を整形する
   * @param {*} username
   * @returns 整形後の文字列
   */
  fixUsername(username) {
    let str = username;
    if (!str) {
      return str;
    }
    str = str.replace(/"/g, ''); // csvからの登録では文字列リテラルがついていることがあるので
    if (str.indexOf(' ')) {
      str = str.replace(' ', '');
    } else if (str.indexOf('　')) {
      str = str.replace('　', '');
    }
    return str;
  },

  /**
   * 【重要】
   * コンポーネントで直接使用するには
   * methodsオブジェクトに入れておく必要がある
   */
  methods: {
    /** 日付の曜日を取得
     *  type:
     *    full => 全表記(例:月曜日)
     *    short => 短縮表記(例:月)
     */
    getWeekDayLabel(date, type) {
      const m = moment(date).locale('ja');
      if (type === 'full') return m.format('dddd');
      if (type === 'short') return m.format('ddd');
      return 'Type Error! Check cf.';
    },

    /** タイムスタンプのフォーマット */
    formatTimestamp(stamp, format = 'YYYY-MM-DD') {
      let result = moment(stamp, 'X').format(format);
      if (!String(stamp).includes('GMT')) {
        result = moment(stamp).format(format);
      }
      return result;
    },

    /** 日付の差分（引数ー本日） */
    getRemainingToday(target) {
      const today = moment(new Date());
      const limit = moment(target);
      const remainingDays = limit.diff(today, 'days') > -1
        ? limit.diff(today, 'days')
        : 0;
      return remainingDays;
    },

    /** クローンオブジェクトの返却 */
    getClone(object) {
      return cloneDeep(object);
    },

    /**
     * アイコンURL（type = 2）の返却
     * @param obj object
     *   ユーザ等特定idのオブジェクト
     */
    getMyIconUrl(object) {
      const defaultIcon = '/img/default/noimage.jpg';
      let url = defaultIcon;

      if (!object) return url;

      const keys = Object.keys(object);
      if (!keys.includes('urls')) return url;

      const isArray = Array.isArray(object.urls);
      if (isArray) {
        // adjustUrls未適用（[{}, {}, ...]）
        const urls = object.urls || [];

        if (urls.length) {
          // item.type = 2（アイコン）画像を返却する
          urls.some((item) => {
            if (item.type === 2) {
              url = item.url || defaultIcon;
              return true;
            }
            return false;
          });
        }
      } else if (object.urls && object.urls.icon.length) {
        // adjustUrls適用済み（{ main: {}, icon: {}, ... }）
        url = object.urls.icon[0].url || defaultIcon;
      }

      return url;
    },

    /**
     * ユーザオブジェクトから表示ラベルを返却
     * // 1. usernameが存在する場合は優先
     * // 2. usernameなくfacebook.nameが存在する場合はfacebook.name
     * // 3. username・facebook.nameともにない場合はemail
     */
    getUserLabel(user) {
      let name = user.email;
      if (user.username) name = user.username;
      return name;
    },

    /**
     * アイキャッチ取得
     * data.eyecatchの有無でデフォ画像と振り分け
     */
    getEyecatch(data) {
      return data.eyecatch || '/img/default/default.jpg';
    },

    /** 文字列の中からimgタグとhtmlタグを除去 */
    replaceImgTag(str) {
      let result = str;
      if (str) {
        // imgタグをsrc丸ごと削除
        const images = str.match(/<img(.|\s)*?>/gi);
        if (images && images.length) {
          images.forEach((img) => {
            result = result.replace(img, '');
          });
        }
        // テキスト文中からhtmlタグを削除
        result = result
          .replace(/(<([^>]+)>)/gi, '')
          .replace(/\n/g, '')
          .replace(/&nbsp;/g, '');
      }
      return result;
    },

    /**
     * 3桁毎にカンマ割り振り
     * @param int num 元の数値
     */
    addComma(num, afterPoint = '', count = 0) {
      if (num !== 0 && !num) return '';
      // if (!this.isNumber(num)) return false;

      // 文字列にする
      let strNum = String(num);
      let afterDecimalPoint = afterPoint;
      if (strNum.includes('.')) {
        // 小数点以下を含む場合
        const split = strNum.split('.');
        strNum = split[0];
        afterDecimalPoint = `.${split[1]}`;
      }
      const len = Number(strNum.length);

      // 再帰的に呼び出し
      if (len > 3) {
        // 前半を引数に再帰呼び出し + 後半3桁
        strNum = `${this.addComma(strNum.substring(0, len - 3), afterDecimalPoint, count + 1)},${strNum.substring(len - 3)}`;
      }

      if (count === 0) {
        strNum += `${afterDecimalPoint}`;
      }

      return `${strNum}`;
    },

    /**
     * 小数点以下の計算をする際に
     * ずれが生じてしまうため、
     * 文字列かして小数点の位置を判別
     */
    getDotPosition(value) {
      // 数値のままだと操作できないので文字列化
      const strVal = String(value);
      let dotPosition = 0;

      // 小数点が存在するか確認
      if (strVal.lastIndexOf('.') !== -1) {
        // 小数点があったら位置を取得
        dotPosition = (strVal.length - 1) - strVal.lastIndexOf('.');
      }

      return dotPosition;
    },

    /**
     * 小数点が存在する数値の合計値計算
     * @param value1  対象1
     * @param value2  対象2
     * @param formula 式
     *   addition 足し算
     *   subtract 引き算
     *   multiply 掛け算
     *   divide   割り算
     */
    decimalCalculation(value1, value2, formula = 'addition') {
      // それぞれの小数点の位置を取得
      const dotPosition1 = this.getDotPosition(value1);
      const dotPosition2 = this.getDotPosition(value2);

      // 位置の値が大きい方（小数点以下の位が多い方）の位置を取得
      const max = Math.max(dotPosition1, dotPosition2);

      // 大きい方に小数の桁を合わせて文字列化、
      // 小数点を除いて整数の値にする
      const intValue1 = parseInt((value1.toFixed(max)).replace('.', ''), 10);
      const intValue2 = parseInt((value2.toFixed(max)).replace('.', ''), 10);

      // 10^N の値を計算
      const power = 10 ** max;

      // 整数値で計算した後に10^Nで割る
      let result;
      switch (formula) {
        case 'addition': // 足し算
          result = (intValue1 + intValue2) / power; break;
        case 'subtract': // 引き算
          result = (intValue1 - intValue2) / power; break;
        case 'multiply': // 掛け算
          result = (intValue1 * intValue2) / power; break;
        case 'divide': // 割り算
          result = (intValue1 / intValue2) / power; break;
        default:
          result = (intValue1 + intValue2) / power; break;
      }
      return result;
    },

    /**
     * 小数点n位で四捨五入
     * @param value
     */
    roundN(value, n) {
      if (!value) return 0;
      const N = 10 ** n;
      return Math.round(value * N) / N;
    },

    /**
     * value1/valu2ををパーセンテージで返却
     * 小数点2位以下を四捨五入
     * @param value1, value2
     */
    getPercentage(value1, value2) {
      const decimalValue = this.decimalCalculation(value1, value2, 'divide');
      return this.roundN(decimalValue * 100, 2);
    },

    /**
     * 回答期限のアラート
     */
    getLimitAlert(limit) {
      let result = { color: '', label: '' };
      const yellow = 'yellow';
      const red = 'red';
      // フォーマットを合わせる必要がある
      const today = moment(new Date()).format('YYYY-MM-DD');
      const mlimit = moment(limit).format('YYYY-MM-DD');
      // 差分を抽出
      const diff = moment(mlimit).diff(moment(today), 'days');

      if (diff === 1) {
        result = {
          color: yellow,
          label: '締切前日',
        };
      } else if (diff === 0) {
        result = {
          color: red,
          label: '締切当日',
        };
      } else if (diff < 0) {
        result = {
          color: red,
          label: '締切日超過',
        };
      }
      return result;
    },

    /**
     * filter群
     */
    filterMoment(value, format) {
      return moment(value).format(format);
    },
    filterDate(date) {
      return moment(date).format('YYYY.M.D');
    },
    filterDate_ja(date) {
      return moment(date).format('YYYY年M月D日');
    },
    filterDatetime(date) {
      return moment(date).format('YYYY.M.D HH:mm');
    },
    filterNumber_format(value) {
      // valueを文字列に変換
      const str = String(value);
      if (!str.match(/^\d+$/)) {
        return value;
      }
      const formatter = new Intl.NumberFormat('ja-JP');
      return formatter.format(str);
    },
    filterZip(value) {
      const str = value ? String(value) : null;
      let result = '';
      if (str && str.includes('-')) {
        // ハイフンが含まれている場合はそのまま出力
        result = `〒${str}`;
      } else if (str && !str.includes('-')) {
        // ハイフンを付与して出力
        result = `〒${str.substr(0, 3)}-${str.substr(3, 4)}`;
      }
      return result;
    },
    filterRemove_hyphen(value) {
      const str = value.replace(/-/g, '');
      return str;
    },
    filterDay(str) {
      return moment(str).day();
    },

    /**
     * 予約しようとしているスケジュールと同時間帯の予約が入っていないかチェックする。
     * 同時間帯の予約がすでに２件以上成立している場合、falseを返却する。
     * @param startDate 予定開始時刻
     * @returns 予約可能な場合はtrue, それ以外はfalse
     */
    async checkOnlineReserveTime(startDate) {
      // 同時間帯での予約数チェック
      const params = {
        date: this.filterDatetime(startDate),
      };
      try {
        const response = await axios({
          method: 'GET',
          url: '/v1/schedule/get/list',
          params,
        });
        const schedules = response.data.schedules.data;
        // 予約済みで未実行のものを抽出し、それがすでに２件以上存在する場合はこれ以上予約させない
        const futureReserves = schedules.filter((schedule) => {
          const flag = schedule.flag === 1 // スケジュール有効
            && schedule.reserve // 予約あり
            && schedule.reserve.cancel_reason === 0 // キャンセルなし
            && schedule.reserve.flag === 1; // 未実行
          return flag;
        });
        if (futureReserves.length >= 2) {
          alert('この時間帯はすでに満席です。');
          return false;
        }
        return true;
      } catch (error) {
        console.error(error);
        alert('予約済みのオンライン相談情報の取得に失敗しました。');
        throw error;
      }
    },

    // textareaでの文字列をhtml化する
    convertText2Html(text) {
      let notice = text;
      // notice = notice.replace(/\r\n/g, '&lt;br /&gt;<br />');
      notice = notice.replace(/(\n|\r)/g, '<br />');
      return `<p>${notice}</p>`;
    },

    // パーセンテージを算出
    calcPercentage(target, all) {
      return Math.trunc(Math.round((target / all) * 100));
    },

    //パーセンテージを指定した小数点以下まで算出 デフォルトは小数点以下1桁まで
    calcPercentageDecimal(target, all, digits = 1) {
      return Math.round((target / all) * 100 * (10 ** digits)) / (10 ** digits);
    },

    //指定した少数点数で切り上げ
    calcCeilDecimal(target, all, digits = 1) {
      return Math.ceil((target / all) * 100 * (10 ** digits)) / (10 ** digits);
    },

    /** バーチャルキャストのユーザURLを取得する */
    getVirtualCastUserURL(vcid) {
      return `https://virtualcast.jp/users/${vcid}`;
    },

    /** バーチャルキャストのルームURLを取得する */
    getVirtualCastMedicalRooomURL(roomKey) {
      return `https://virtualcast.jp/rooms/${roomKey}`;
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user && user.flag >= 990) {
        color = 'gray';
      } else if (user && user.risk && user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },

    // キャンセルを実行したユーザのアカウントタイプ
    getAccountTypeLabel(reserve) {
      let result = '';
      let accountType;
      const labels = this.helper.master.labels;
      if (reserve.cancel_user_id) {
        // 240215 cancel_user_id追加後はキャンセルユーザが直でわかる
        accountType = reserve.cancelUser.account_type || null;
      } else {
        // それ以前の予約はreasonおよびメモからキャンセル実行者を判定
        // 学校管理者・事務局は判定不可
        const reason = reserve.cancel_reason;
        if (reason === 4) {
          // 4（利用者現れない）は医師・心理士がキャンセル実行
          accountType = reserve.toUser.account_type;
        } else {
          const cancelMemo = reserve.memos.cancel;
          const hasMemo = cancelMemo.length;
          accountType = hasMemo ? cancelMemo[0].user.account_type : null;
        }
      }
      result = accountType ? `（実行者: ${labels.users.account_type[accountType]}）` : '';
      return result;
    },
    /**
     * 検索後のハイライトを意図的に除去して返却
     * デフォルトで数値型にして返すが、第二引数にstrなどが指定されていれば文字列で返す
     */
    replaceHighlight(mixedId, type = 'num') {
      let result = mixedId;
      //　変換が必要ないケース
      if (typeof mixedId === 'number' && type === 'num') return result;
      // spanタグ除去
      if (mixedId.includes('<span')) {
        result = mixedId.replace('<span class="highlight">', '').replace('</span>', '');
        if (type === 'num') Number(result);
      }
      return result;
    },

    /**
     * オンライン健康相談の相談内容が存在するか判定し返却
     */
    getCustomerMemo(reserveCustomerMemo) {
      let result = '記入なし';
      if (
        reserveCustomerMemo.length
          && reserveCustomerMemo[0].memo
          && reserveCustomerMemo[0].memo !== ''
      ) result = reserveCustomerMemo[0].memo;
      return result;
    },
  },
};
