<template>
  <div :class="$style.wrap">
    <ul v-if="histories.length">
      <stack-item
        :space="2"
        :class="$style.item"
        v-for="(row, i) in histories" :key="row">

        <!-- テキスト -->
        <div v-if="row.type === 'text'">
          <contents-box type="inner" :class="$style.new_wrap" :id="`text_${row.content.id}`">
            <div
              :class="$style.new"
              v-if="this.user.readflags && !this.user.readflags.messageGroups.includes(row.content.id) && showNew">NEW</div>
            <div :class="$style.create_wrap">
              <i class="fa-regular fa-comment-lines" :class="[$style.create_icon, $style.icon_text]"/>
              <p :class="$style.create">{{ formatTimestamp(row.date, `YYYY年MM月DD日（${getWeekDayLabel(row.date, 'short')}）`) }}</p>
              <div
                v-if="getSuitRiskApplication(row)"
                :class="$style.riskApplicationTag"
              >
                <a
                  :href="`/${accountTypeCode}/risk-applications/${getSuitRiskApplication(row).id}`"
                  target="_blank"
                >{{ getRiskJudgeLabel(getSuitRiskApplication(row)) }}</a>
              </div>
            </div>

            <div v-if="showFromUser">
              <spacer :y="1"/>
              <user-box
                :showId="true"
                :user="row.content.customer"
                :ownUser="user"
                :showCocomoniBtn="true"
                :link="isAbleToClickUserBox ? `/${user.account_type === 31 ? 'operator' : 'teacher'}/history/${row.content.messages[0].user.id}` : null"
                :bg="user.account_type >= 11 ? userBoxColor(row.content.customer) : 'white'"
                :targetBlank="isAbleToClickUserBox"
                :isCompany="isCompany"
                type="inner">
                <!-- ↓学校管理者の回答者は重複して表示される情報だったので暫定非表示 -->
                <!-- <template v-slot:btn>
                  <div
                    :class="$style.open"
                    @click="changeOpenIds(i)"
                    v-if="user.account_type === 11">
                    <p v-if="!openIds.includes(i)">回答者を表示<i class="fa-solid fa-angles-down"/></p>
                    <p v-else>回答者を非表示<i class="fa-solid fa-angles-up"/></p>
                  </div>
                </template> -->
                <template v-slot:main>
                  <div v-if="openIds.includes(i) && user.account_type === 11">
                    <spacer :y="2"/>
                    <user-box
                      :showId="false"
                      :user="row.content.messages[1].user"
                      :showAge="user.account_type === 31"
                      type="inner" />
                  </div>
                </template>
              </user-box>
            </div>


            <!-- <div v-if="!teacher.includes(user.account_type)"> -->
            <div>
              <spacer :y="1"/>
              <div :class="$style.check_wrap">
                <div :class="$style.check_list">
                  <div :class="$style.check">受付日時</div>
                  <div :class="[$style.value, $style.type]">
                    <p><span>{{ filterMoment(row.content.messages[0].created_at, 'YYYY年MM月DD日 HH:mm') }}</span></p>
                  </div>
                </div>
                <div :class="$style.check_list" v-if="teacher.includes(user.account_type)">
                  <div :class="$style.check">受付内容</div>
                  <div :class="[$style.value, $style.type]">
                    <p
                      v-for="cType in row.content.type"
                      :key="cType">{{ helper.master.labels.texts.type[cType] }}</p>
                  </div>
                </div>
                <div
                  :class="$style.check_list"
                  v-if="row.content.messages[1] && row.content.messages[1].flag === 1">
                  <div :class="$style.check">回答日時</div>
                  <div :class="[$style.value, $style.type]">
                    <p><span>{{ filterMoment(row.content.messages[1].updated_at, 'YYYY年MM月DD日 HH:mm') }}</span></p>
                  </div>
                </div>
                <div :class="$style.check_list" v-if="!teacher.includes(user.account_type)">
                  <div :class="$style.check">お悩み</div>
                  <div :class="[$style.value, $style.type]">
                    <p
                      v-for="cType in row.content.type"
                      :key="cType">{{ helper.master.labels.texts.type[cType] }}<span v-if="row.content.other_text && cType === 6">（{{ row.content.other_text }}）</span></p>
                  </div>
                </div>

                <div
                  v-if="!row.content.done
                    && showReplyDeadline
                    && !teacher.includes(user.account_type)"
                  :class="$style.check_list">
                  <div :class="$style.check">回答期限</div>
                  <p :class="$style.limitStatus">
                    <span
                      :style="`background: ${getLimitAlert(row.content.limit).color}`"
                      :class="$style.circleStatus"
                      v-if="getLimitAlert(row.content.limit).color"></span>
                      <b>{{ filterMoment(row.content.limit, 'YYYY年MM月DD日') }}<b v-if="getLimitAlert(row.content.limit).label">（{{ getLimitAlert(row.content.limit).label }}）</b>
                    </b>
                  </p>
                </div>

                <div
                  v-if="row.content.customer.messageGroups.length
                    && showReplyDeadline
                    && !teacher.includes(user.account_type)"
                  :class="$style.check_list">
                  <div :class="$style.check">相談履歴</div>
                    <div :class="$style.content">
                      <div :class="$style.who5_top">
                        <b>{{ row.content.customer.messageGroups.length }}件</b>
                        <div :class="$style.plus" @click="changeHistoryOpenIds(i)">
                          <i v-if="historyOpenIds.includes(i)" class="fa-solid fa-minus"></i>
                          <i v-else class="fa-solid fa-plus"></i>
                        </div>
                      </div>

                      <div v-if="historyOpenIds.includes(i)">
                        <ul :class="$style.btns">
                          <li
                            :class="$style.btn"
                            v-for="group in row.content.customer.messageGroups"
                            :key="group.id">
                            <a
                              :href="`/operator/reserve/text/${group.id}/`"
                              target="_blank">{{ filterMoment(group.created_at, 'M/D') }}
                              <span v-for="gType in group.type.type" :key="gType">{{ helper.master.labels.texts.type[gType] }}</span>
                            </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  v-if="row.content.scoreWho5 >= 0
                    && !teacher.includes(user.account_type)"
                  :class="$style.check_list">
                  <div :class="$style.check">ココモニ</div>
                  <div :class="$style.value">
                    <div :class="$style.who5_top">
                      <p :class="$style.who5_score"><span :class="$style.who5_score_label">ココモニチェック</span>{{ row.content.scoreWho5 }}点</p>
                      <div :class="$style.open" @click="changeWho5OpenIds(i)">
                        <p v-if="!who5openIds.includes(i)">詳細を表示<i class="fa-solid fa-angles-down"/></p>
                        <p v-else>詳細を非表示<i class="fa-solid fa-angles-up"/></p>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="who5openIds.includes(i)
                    && !teacher.includes(user.account_type)"
                  :class="$style.who5_detail">
                  <spacer :y="3"/>
                  <ul :class="$style.who5_detail_list">
                    <li v-for="(item, i) in helper.master.who5.items"
                      :key="i">
                        <p :class="$style.who5_label">{{item.label}}</p>
                        <p :class="$style.who5_answer">{{helper.master.who5.labels[row.content.who5Answer.answers[i]]}}</p>
                    </li>
                  </ul>
                </div>
              </div>

              <spacer :y="3"/>
              <div
                v-if="!teacher.includes(user.account_type)"
                :class="[$style.waitingCommentBox]"
                v-html="row.content.messages[0].message" />
            </div>


            <div
              v-if="row.content.messages[1]">
              <spacer :y="3" v-if="!teacher.includes(user.account_type)" />
              <user-box
                type="inner"
                :showId="false"
                :user="row.content.messages[1].user"
                :showAge="false">
                <template v-slot:btn v-if="!teacher.includes(user.account_type)">
                  <div :class="$style.open" @click="changeOpenIds(i)">
                    <p v-if="!openIds.includes(i)">{{ row.content.messages[1].flag !== 1 ? '下書き' : '回答' }}を表示<i class="fa-solid fa-angles-down"/></p>
                    <p v-else>{{ row.content.messages[1].flag !== 1 ? '下書き' : '回答' }}を非表示<i class="fa-solid fa-angles-up"/></p>
                  </div>
                </template>
                <template v-slot:main>
                  <div v-if="openIds.includes(i) && !teacher.includes(user.account_type)">
                    <spacer :y="2"/>
                    <div class="n2br" :class="$style.answer">
                      <p v-html="row.content.messages[1].message"></p>
                    </div>
                    <spacer :y="2" v-if="!hideCounseling" />
                    <btn-container v-if="!hideCounseling">
                      <basic-btn size="sm" tag="a" @click="clickOnline(row.content.messages[1].user.id)">この先生にオンライン相談する</basic-btn>
                    </btn-container>
                  </div>
                </template>
              </user-box>
            </div>
          </contents-box>
        </div>

        <!-- オンライン / バーチャル -->
        <div v-if="isOnlineOrVirtual(row.type)">
          <contents-box
            type="inner"
            :bg="[901, 902].includes(row.content.flag) ? 'gray' : ''">
            <div :class="$style.create_wrap">
              <i :class="[$style.create_icon, isVirtual(row.type) ? $style.icon_virtual : $style.icon_online, getReserveIconClass(row.type)]">
                <i :class="[$style.create_icon_inner, getReserveInnerIconClass(row.type)]" />
              </i>
              <p :class="[$style.create, $style.only_pc]">
                {{ formatTimestamp(row.date, `YYYY年MM月DD日（${row.content.weekday.label}）【HH:mm - HH:45】`) }}
                <span :class="[$style.status, { cancel: row.content.flag === 901 || row.content.flag === 902 }]">{{ helper.master.labels.reserve.flag[row.content.flag] }}</span>
              </p>
              <p :class="[$style.create, $style.only_sm]">
                {{ formatTimestamp(row.date, `YYYY年MM月DD日（${row.content.weekday.label}）`) }}
                <br :class="$style.only_sm">
                {{ formatTimestamp(row.date, `HH:mm - HH:45`) }}
                <span :class="[$style.status, { cancel: row.content.flag === 901 || row.content.flag === 902 }]">{{ helper.master.labels.reserve.flag[row.content.flag] }}</span>
              </p>
              <div
                v-if="getSuitRiskApplication(row)"
                :class="$style.riskApplicationTag"
              >
                <a
                  :href="`/${accountTypeCode}/risk-applications/${getSuitRiskApplication(row).id}`"
                  target="_blank"
                >{{ getRiskJudgeLabel(getSuitRiskApplication(row)) }}</a>
              </div>
            </div>
            <spacer :y="2"/>
            <user-box
              type="inner"
              :showId="true"
              :ownUser="user"
              :showCocomoniBtn="true"
              :user="showFromUser ? row.content.fromUser : row.content.toUser"
              :bg="user.account_type >= 11 ? userBoxColor(row.content.fromUser) : 'white'"
              :link="isAbleToClickUserBox ? `/${user.account_type === 31 ? 'operator' : 'teacher'}/history/${row.content.fromUser.id}` : null"
              :targetBlank="isAbleToClickUserBox"
              :showAge="false"
              :isCancel="[901, 902].includes(row.content.flag) ? true : false"
              :isCompany="isCompany">
              <template v-slot:btn>
                <div :class="[$style.open]" @click="changeOpenIds(i)">
                  <p v-if="!openIds.includes(i)">詳細を表示<i class="fa-solid fa-angles-down"/></p>
                  <p v-else>詳細を非表示<i class="fa-solid fa-angles-up"/></p>
                </div>
              </template>
              <template v-slot:main>
                <div v-if="openIds.includes(i)">
                  <spacer :y="2"/>
                  <div :class="$style.check_wrap">
                    <div :class="$style.check_list">
                      <div :class="$style.check">お悩み</div>
                      <div :class="[$style.value, $style.type]">
                        <p
                          v-for="cType in row.content.type"
                          :key="cType">{{ helper.master.labels.texts.type[cType] }}<span v-if="row.content.other_text && cType === 6">（{{ row.content.other_text }}）</span></p>
                      </div>
                    </div>
                    <div v-if="row.content.scoreWho5 !== null"
                      :class="$style.check_list">
                      <div :class="$style.check">ココモニ</div>
                      <div :class="$style.value">
                        <div :class="$style.who5_top">
                          <p :class="$style.who5_score"><span :class="$style.who5_score_label">ココモニチェック</span>{{ row.content.scoreWho5 }}点</p>
                          <div :class="$style.open" @click="changeWho5OpenIds(i)">
                            <p v-if="!who5openIds.includes(i)">詳細を表示<i class="fa-solid fa-angles-down"/></p>
                            <p v-else>詳細を非表示<i class="fa-solid fa-angles-up"/></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-show="who5openIds.includes(i)" :class="$style.who5_detail">
                      <spacer :y="3"/>
                      <ul :class="$style.who5_detail_list">
                        <li v-for="(item, i) in helper.master.who5.items"
                          :key="i">
                            <p :class="$style.who5_label">{{item.label}}</p>
                            <p :class="$style.who5_answer">{{helper.master.who5.labels[row.content.who5Answer.answers[i]]}}</p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <spacer :y="3" v-if="row.content.memos.customer[0]"/>
                  <p :class="$style.memo" v-if="row.content.memos.customer[0]">{{ row.content.memos.customer[0].memo }}</p>

                  <div :class="$style.memo" v-if="user.account_type > 10" class="n2br">
                    <spacer :y="3"/>
                    <contents-box type="inner" :title="isCompany ? '企業コメント' : '学校コメント'">
                      <spacer :y="1"/>
                      <p v-if="row.content.memos.teacher.length"
                        v-html="row.content.memos.teacher[0].memo"></p>
                      <p v-else>コメントの登録はありません。</p>
                    </contents-box>
                  </div>

                  <div :class="$style.memo" v-if="user.account_type === 31" class="n2br">
                    <spacer :y="3"/>
                    <contents-box type="inner" title="事務局コメント">
                      <spacer :y="1"/>
                      <p v-if="row.content.memos.operator.length"
                        v-html="row.content.memos.operator[0].memo"></p>
                      <p v-else>コメントの登録はありません。</p>
                    </contents-box>
                  </div>


                  <div :class="$style.memo" v-if="row.content.flag > 900 && row.content.cancel_reason">
                    <spacer :y="3"/>
                    <contents-box type="inner" :title="`キャンセル理由${getAccountTypeLabel(row.content)}`">
                      <spacer :y="1"/>
                      <p>{{ helper.master.labels.reserve.cancel_reason[row.content.cancel_reason] }}</p>
                      <dl v-if="row.content.memos.cancel.length" :class="$style.comment">
                        <dt>コメント</dt>
                        <dd>{{ row.content.memos.cancel[0].memo }}</dd>
                      </dl>
                    </contents-box>
                  </div>


                  <spacer :y="3" v-if="counselor.includes(user.account_type)"/>
                  <contents-box type="inner" title="カルテ"  v-if="counselor.includes(user.account_type)">
                    <spacer :y="1"/>
                    <ul>
                      <li v-for="memo in row.content.memos.counselor" :key="memo.id" class="n2br">
                        <p :class="$style.record" v-html="memo.memo"/>
                      </li>
                      <li>
                        <p :class="$style.record" v-if="!row.content.memos.counselor.length">カルテは登録されていません。</p>
                      </li>
                    </ul>
                  </contents-box>
                  <div v-if="showFromUser">
                    <spacer :y="3"/>
                    <user-box
                      :showId="false"
                      :user="row.content.toUser"
                      :showAge="user.account_type === 31"
                      type="inner"/>
                  </div>
                  <spacer :y="3" v-if="user.account_type > 10"/>
                  <btn-container v-if="user.account_type > 10">
                    <a
                      :href="`/${ user.account_type === 31 ? 'operator/report' : counselor.includes(user.account_type) ? 'counselor/registed-report' : 'teacher/report'}/${row.content.id}`"
                      :target="counselor.includes(user.account_type) ? 'self' : 'blank'">
                      <basic-btn tag="button" size="sm">報告書兼意見書</basic-btn>
                    </a>
                  </btn-container>
                </div>
              </template>
            </user-box>
          </contents-box>
        </div>
      </stack-item>
    </ul>
    <p v-else>相談履歴はありません。</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import UserBox from '@/views/components/UserBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';


export default {
  name: 'history',
  mixins: [cf],
  props: {
    histories: {
      type: Object,
      require: true,
    },
    riskApplications: { // 健康相談とリスク報告書を関連づけたいシーンでのみ渡される
      type: Object,
      require: true,
    },
    showNew: {
      type: Boolean,
      default: true,
    },
    hideCounseling: {
      type: Boolean,
      default: false,
    },
    showFromUser: {
      type: Boolean,
      default: false,
    },
    showCustomerId: {
      type: Boolean,
      default: false,
    },
    isAbleToClickUserBox: {
      type: Boolean,
      default: false,
    },
    showReplyDeadline: {
      type: Boolean,
      default: false,
    },
    isCompany: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BasicBtn,
    BtnContainer,
    ContentsBox,
    StackItem,
    UserBox,
    Spacer,
  },
  data() {
    return {
      openIds: [],
      who5openIds: [],
      historyOpenIds: [],
      counselor: [21, 22],
      teacher: [11, 12],
    };
  },
  computed: {
    ...mapState(['helper', 'user']),
    // これの利用箇所でコメントアウトされていたため、同様にコメントアウト
    // 再度利用したい場合はuser.counselingの予約も有効にする
    // hasFutureReservation() {
    //   let flag = false;
    //   if (this.user.counseling
    //   && this.user.counseling.reserves
    //   && this.user.counseling.reserves.future
    //   && this.user.counseling.reserves.future.length) {
    //     flag = true;
    //   }
    //   return flag;
    // },
    accountTypeCode() {
      if (!this.user || !this.user.account_type) {
        return null;
      }
      const teachers = [11, 12];
      if (teachers.includes(this.user.account_type)) {
        return 'teacher';
      }
      const counselors = [21, 22];
      if (counselors.includes(this.user.account_type)) {
        return 'counselor';
      }
      if (this.user.account_type === 31) {
        return 'operator';
      }
      return null;
    },
  },
  methods: {
    isOnlineOrVirtual(type) {
      return [
        'reserve',
        'virtualReserve',
      ].includes(type);
    },
    isVirtual(type) {
      return type === 'virtualReserve';
    },
    getReserveIconClass(type) {
      return this.isVirtual(type) ? 'fa-solid fa-vr-cardboard' : 'fa-regular fa-laptop';
    },
    getReserveInnerIconClass(type) {
      return this.isVirtual(type) ? null : 'fa-regular fa-user-headset';
    },
    changeOpenIds(idx) {
      if (this.histories[idx].type === 'text'
        && this.user.readflags
        && !this.user.readflags.messageGroups.includes(this.histories[idx].content.id)) {
        this.registReadFlag(this.histories[idx].content.id);
      }
      if (this.openIds.includes(idx)) {
        const i = this.openIds.indexOf(idx);
        this.openIds.splice(i, 1);
      } else {
        this.openIds.push(idx);
      }
    },
    changeWho5OpenIds(id) {
      if (this.who5openIds.includes(id)) {
        const idx = this.who5openIds.indexOf(id);
        this.who5openIds.splice(idx, 1);
      } else {
        this.who5openIds.push(id);
      }
    },
    changeHistoryOpenIds(id) {
      if (this.historyOpenIds.includes(id)) {
        const idx = this.historyOpenIds.indexOf(id);
        this.historyOpenIds.splice(idx, 1);
      } else {
        this.historyOpenIds.push(id);
      }
    },

    isNew(date) {
      const today = moment(new Date());
      // 3日以内（暫定的）
      return today.diff(moment(date), 'days') < 3;
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if (user.risk && user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },

    clickOnline(counselorId) {
      // if (this.hasFutureReservation) {
      //   alert('予約中のオンライン相談があるため、新たな相談予約は行えません。');
      //   return;
      // }
      this.$router.replace({
        path: '/reserve/select/',
        query: {
          target: 'counselor',
          counselor: counselorId,
        },
      });
    },
    registReadFlag(id) {
      const data = {
        model_type: 5, // messageGroups
        user_id: this.user.id,
        foreign_id: id,
      };
      this.axios({
        method: 'POST',
        url: '/v1/readflag/set/register',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    /** 対象の健康相談に関連するリスク報告書情報を返却する */
    getSuitRiskApplication(history) {
      if (!this.riskApplications || this.riskApplications.length === 0) {
        return null;
      }
      const TYPE_VALUE = {
        reserve: 1,
        text: 2,
        virtualReserve: 3,
      };
      const foreignId = history.id || history.content.id;
      const suit = this.riskApplications.find((row) => row.counseling_type === TYPE_VALUE[history.type] && row.foreign_id === foreignId);
      return suit || null;
    },
    /** リスク報告書へのリンクラベルを決定する */
    getRiskJudgeLabel(riskApplication) {
      if (!riskApplication) {
        return null;
      }
      return riskApplication.is_risk_reduced ? 'リスク取り下げ' : 'リスク判断';
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  position: relative;
}
.create {
  font-weight: bold;

  &_wrap {
    display: flex;
    align-items: center;
  }

  &_icon {
    font-size: 25px;
    position: relative;
    margin-right: 8px;

    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      font-size: 10px;
    }

    &.icon_text {
      color: var(--blue-main);
    }

    &.icon_online {
      color: var(--orange-main);
    }
    &.icon_virtual {
      color: var(--green-main);
    }
  }
}
.flex {
  display: flex;

  @include sm-view {
    display: block;
  }
}
.link {
  color: black;
}
.user_wrap {
  width: 180px;
}
.user {
  display: flex;
}
.md {
  @include sm-view {
    display: none;
  }
}
.only_sm {
  display: none;
  @include sm-view {
    display: block;
  }
}
.only_pc {
  @include sm-view {
    display: none;
  }
}
.date {
  font-size: 10px;
}

.check_wrap {
  position: relative;
  flex-wrap: wrap;
  align-items: center;

  @include sm-view {
     flex-direction: column;
     align-items: flex-start;
  }
}

.check_list {
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  // font-size: 14px;
  @include sm-view {
    font-size: 13px;
    display: block;
  }
  @include xs-view {
    display: block;
  }
}

.check {
  background-color: var(--gray-sub);
  font-weight: bold;
  border-radius: 4px;
  padding: 4px 0;
  width: 140px;
  text-align: center;
  margin-right: 12px;
  @include sm-view {
    width: 100%;
    text-align: left;
    padding: 0.5em;
  }
}

.value {
  flex: 1;
  margin-top: 4px;
  font-weight: bold;

  @include sm-view {
    padding-left: 3%;
  }

  &.type {
    display: flex;
    flex-wrap: wrap;
    p {
      &:not(:last-child) {
        &::after {
          margin: 0 5px;
          content: '/';

        }
      }
    }
  }

  .who5_top {
    display: flex;
    align-items: center;
    @include sm-view {
      justify-content: space-between;
    }
  }
  .who5_score {
    margin-right: 16px;
    &_label {
      margin-right: 12px;
    }
  }
}
.open {
  font-size: 12px;
  font-weight: normal;
  color: var(--gray-side);
  cursor: pointer;
  i {
    margin-left: 4px;
  }
  @include sm-view {
    margin-top: 10px;
    text-align: center;
  }
}
.who5_detail {
  &_list {
    margin: 0 auto;
    width: 95%;
    li {
      // font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:first-child) {
        margin-top: 8px;
      }

      .who5_answer {
        font-weight: bold;
      }

      @include sm-view {
        display: block;
        font-size: 13px;
      }
    }
  }
}


.waitingCommentBox {
  display: flex;
  // font-size: 14px;
  white-space: pre-wrap;

  @include sm-view {
    font-size: 13px;
  }
}

.icon {
  margin-left: 8px;
  color: var(--gray-main);
  font-size: 20px;
}

.answer {
  // font-size: 14px;
  padding: 12px;
  background-color: rgba(var(--orange-main-rgb), .15);
  border-radius: 4px;
  @include sm-view {
    font-size: 13px;
  }
}

.new {
  position: absolute;
  top: -10px;
  left: auto;
  right: -8px;
  z-index: 1;
  background-color: var(--pink);
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;

  &_wrap {
    position: relative;
  }

  @include sm-view {
    padding: 3px 7px 2px;
    font-size: 11px;
    right: -4px;
    top: -4px;
  }
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include sm-view {
    display: block;
    .cancel {
      margin-top: 10px;
    }
  }
}
.counselor {
  display: flex;
  margin-right: 10px;
  @include sm-view {
    margin-bottom: 10px;
  }
  .img {
    margin-right: 10px;
  }
}

.position {
  font-size: 12px;
}

.labels {
  display: flex;
  align-items: center;
  @include sm-view {
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .md {
    width: 250px;
  }
  .sm {
    width: 130px;
  }

}

.url {
  a {
    color: var(--black);
    text-decoration: underline;
  }
}
.slider {
  width: 100%;
  height: 510px;
  border-radius: 10px;
  background-color: var(--primary-color);
}
.status {
  font-size: 12px;
  font-weight: normal;
  margin-left: 8px;
}
.memo {
  @include sm-view {
    font-size: 13px;
  }
}
.record {
  font-size: 14px;
}
.limitStatus {
  margin-top: 3px;
  display: flex;
  align-items: center;
  .circleStatus {
    $size: 1.2em;
    margin-right: .5rem;
    width: $size;
    height: $size;
    border: 1px solid #ddd;
    border-radius: 100%;
    overflow: hidden;
  }
}
.who5 {
  li {
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  .who5_answer {
    font-weight: bold;
  }

  &_top {
    display: flex;
    justify-content: space-between;
    .plus {
      font-size: 20px;
      color: var(--gray-main);
      cursor: pointer;
    }
    .sub {
      margin-right: 12px;
    }
  }
}
.btns {
  display: flex;
  flex-wrap: wrap;
  li {
   margin-right: 10px;
   margin-top: 8px;
   white-space: nowrap;
   a {
     color: var(--black);
   }
  }
  .btn {
    padding: 4px 12px;
    border-radius: 4px;
    background-color: var(--gray-sub);
  }
}
.content {
  margin-top: 3px;
  flex: 1;
}
.bg-gray {
  background-color: rgba(170, 170, 170, 0.25);
}
.comment {
  margin-top: 1em;
  font-size: 13px;
  dt {
    font-weight: bold;
  }
  dd {
    margin-left: 0;
    margin-top: .5em;
  }
}

.riskApplicationTag {
  margin-left: 1rem;
  padding: 3px 15px;
  z-index: 1;
  border: red solid;
  color: red;
  background-color: white;
  border-radius: 20px;
  font-size: 14px;
}
</style>

<style lang="scss" scoped>
.cancel {
  color: red;
}
</style>
