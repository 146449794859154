<template>
  <div>
    <loader-simple :flag="flag.loader" v-if="flag.loader"/>
    <div v-if="!flag.loader">
      <contents-box title="回数別ココモニチェック 推移">
        <div v-if="!flag.notFound">
          <Line
            :chart-options="chartOptions"
            :chart-data="chartData"
            chart-id="line-text-chart"
            css-classes="graphStyle"
            width="400"
            height="200"
          />
          <div :class="$style.filter_container">
            <div>
              <p :class="$style.filter_title">学校・企業</p>
              <div :class="$style.filter_contents">
                <select
                  :class="$style.select_box"
                  name="schoolFilter"
                  id="schoolFilter"
                  v-model="targetSchool"
                  @change="updateSchoolFilter()">
                  <option :value="0" :selected="targetSchool === 0">すべて</option>
                  <option
                    v-for="school of filters.schools"
                    :key="school"
                    :selected="school === targetSchool">{{ school }}</option>
                </select>
              </div>
            </div>
            <div v-if="targetSchool && filters.users">
              <p :class="$style.filter_title">利用者</p>
              <div :class="$style.filter_contents">
                <select
                  :class="$style.select_box"
                  name="userFilter"
                  id="userFilter"
                  v-model="targetUser"
                  @change="updateFilter()">
                  <option :value="0" :selected="targetUser === 0">すべて</option>
                  <option
                    v-for="user of filters.users"
                    :key="user"
                    :selected="user === targetUser">{{ user }}</option>
                </select>
              </div>
            </div>
            <div>
              <p :class="$style.filter_title">回数</p>
              <div :class="$style.filter_contents">
                <select
                  :class="$style.select_box"
                  name="countFilter"
                  id="countFilter"
                  v-model="targetCount"
                  @change="updateCountFilter()">
                  <option :value="0" :selected="targetCount === 0">すべて</option>
                  <option
                    v-show="count >= 2"
                    v-for="count of filters.counts"
                    :key="count"
                    :selected="count === targetCount">{{ count }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <p v-else>表示できるデータが有りませんでした。</p>
      </contents-box>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions.js';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import { Line } from 'vue-chartjs';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, annotationPlugin);

export default {
  name: 'OperatorAnalyticsReserveGraph',
  components: {
    LoaderSimple,
    Line,
    ContentsBox,
  },
  props: {
    targetDatas: {
      type: Object,
    },
  },
  data() {
    return {
      targetRserveData: null,
      allTarget: null,
      flag: {
        loader: true,
        notFound: false,
      },
      chartData: {
        labels: [],
        datasets: [],
      },
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            scaleLabel: {
              display: true,
              labelString: '回数',
            },
            ticks: {
              stepSize: 1,
            },
          },
          y: {
            max: 30, // who-5の最大値
            min: 0,
            scaleLabel: {
              display: true,
              labelString: 'WHO5スコア',
              fontColor: 'gray',
              fontSize: 16,
            },
            grid: {
              drawBorder: false,
              display: true,
              tickColor: 'rgba(255, 255, 255, 0)',
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: () => null,
              label: (item) => [`ユーザID: ${item.raw.meta.user_id}`, `スコア: ${item.raw.meta.score}`, `相談日: ${item.raw.meta.created_at}`],
            },
          },
          legend: {
            display: false,
          },
        },
      },
      ctx: null,
      annotationHover: false,
      userIds: [],
      targetUser: 0,
      targetSchool: 0,
      targetCount: 0,
      filters: {
        schools: [],
        users: [],
        counts: 0,
      },
    };
  },
  async created() {
    const ls = cf.getLocalStorage('wtt');
    if (ls.analytics && ls.analytics.reserve && Object.keys(ls.analytics.reserve).length) {
      this.targetRserveData = ls.analytics.reserve;
      this.allTarget = ls.analytics.reserve;
      await this.initialize();
      this.setSelectContents();
    } else {
      this.flag.notFound = true;
      this.flag.loader = false;
    }
  },
  computed: {
    ...mapState(['helper']),
  },
  methods: {
    initialize(count) {
      let max = 1;
      Object.keys(this.targetRserveData).forEach((row) => {
        if (this.targetRserveData[row].length > max) max = this.targetRserveData[row].length;
        // 回数で絞り込み
        let data = [];
        if (count) {
          data = this.targetRserveData[row].filter((dataRow) => Number(dataRow.x) === count);
        } else {
          data = this.targetRserveData[row];
        }
        const rgb = this.generateRGB();
        // datasetsを設定
        this.chartData.datasets.push({
          // label: row,
          borderColor: rgb,
          borderWidth: 3,
          radius: 3, // プロットの大きさ
          data,
        });
        // 凡例用にIDとカラーを保存
        this.userIds.push({
          id: this.targetRserveData[row][0].meta.user_name,
          color: rgb,
        });
      });
      this.chartData.labels = [...Array(max)].map((_, i) => i + 1);
      this.flag.loader = false;
    },

    // 利用者情報に存在するデータをセレクトボックスにセット
    setSelectContents() {
      Object.keys(this.targetRserveData).forEach((elem) => {
        const target = this.targetRserveData[elem][0];
        const numOfUse = this.targetRserveData[elem].length;
        // 学校
        if (!this.filters.schools.includes(target.meta.school)) {
          this.filters.schools.push(target.meta.school);
        }
        // 利用回数
        if (this.filters.counts <= numOfUse) {
          this.filters.counts = numOfUse;
        }
      });
    },

    // 全ユーザーデータから学校でフィルタリング
    updateSchoolFilter() {
      if (!this.targetSchool) {
        this.targetRserveData = this.allTarget;
        this.chartData = {
          labels: [],
          datasets: [],
        };
        this.initialize();
        return true;
      }

      // 利用者絞り込み用ボックスをセット
      this.filters.users = [];
      const filterTarget = {};
      const filterPass = Object.keys(this.allTarget).filter((user) => this.allTarget[user][0].meta.school === this.targetSchool);

      filterPass.forEach((user) => {
        // 回数絞り込み用ボックス初期化
        this.filters.counts = 0;
        console.log(user);
        if (this.filters.counts < this.allTarget[user].length) {
          this.filters.counts = this.allTarget[user].length;
        }
        this.filters.users.push(this.allTarget[user][0].meta.user_id);
        filterTarget[this.allTarget[user][0].meta.id] = this.allTarget[user];
      });

      this.targetRserveData = filterTarget;
      this.chartData = {
        labels: [],
        datasets: [],
      };
      this.initialize();
    },

    // 学校でフィルタリングされたデータからさらに利用者で絞り込み
    updateFilter() {
      if (!this.targetUser) {
        this.updateSchoolFilter();
        return true;
      }
      const filterTarget = {};
      const filterPass = Object.keys(this.allTarget).filter((user) => this.allTarget[user][0].meta.user_id === this.targetUser);
      filterPass.forEach((user) => {
        // 回数絞り込み用ボックス初期化
        this.filters.counts = 0;
        if (this.filters.counts < this.allTarget[user].length) {
          this.filters.counts = this.allTarget[user].length;
        }
        filterTarget[this.allTarget[user][0].meta.id] = this.allTarget[user];
      });

      this.targetRserveData = filterTarget;
      this.chartData = {
        labels: [],
        datasets: [],
      };
      this.initialize();
    },

    // 利用回数で絞り込み、選択された回数以内のデータをフィルタリング
    updateCountFilter() {
      if (!this.targetCount) {
        this.updateSchoolFilter();
        this.updateFilter();
        return true;
      }
      const filterTarget = {};
      const filterPass = Object.keys(this.allTarget).filter((user) => this.allTarget[user].length <= this.targetCount);
      filterPass.forEach((user) => {
        filterTarget[this.allTarget[user][0].meta.id] = this.allTarget[user];
      });

      this.targetRserveData = filterTarget;
      this.chartData = {
        labels: [],
        datasets: [],
      };
      this.initialize();
    },

    generateRGB() {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      return `rgb(${r}, ${g}, ${b})`;
    },
  },
};
</script>

<style lang="scss" module>
.guide {
  display: block;
  // text-align: right;
  float: right;
  >div {
    display: flex;
  }
  &_who5 {
    display: inline;
  }
  &_who5:before {
    font-size: 1em;
    content: '●';
    position: relative;
    left: -5px;
  }
}
.filter {
  &_container {
    // display: flex;
    // @include md-view {
    //   display: block;
    // }
  }
  &_title {
    padding-top: 8px;
  }
}
.select {
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
  &_elem {
    margin-left: 10px;
  }
  &_box {
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
