<template>
  <span>
    <span :class="$style.title">
      <i :class="icon" class="icon"
        @mouseover="showFlag = true"
        @mouseleave="showFlag = false"></i>
      <div
        :class="[
          $style['info_content'],
          noTransform ? $style['no_transform'] : null,
        ]"
        :style="{width: boxSize + 'px'}"
        v-if="showFlag">{{hoverText}}</div>
    </span>
  </span>
</template>

<script>
export default {
  name: 'GuideIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    hoverText: {
      type: String,
      required: true,
    },
    boxSize: {
      type: Number,
      default: 300,
    },
    noTransform: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFlag: false,
    };
  },
  created() {
  },
};
</script>

<style lang="scss" module>
.title {
  position: relative;
}
.info_content {
  position: absolute;
  z-index: 100;
  transform: translate(-30%, -160%);
  width: 400px;
  font-size: .9em;
  font-weight: normal;
  background-color: white;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}
.no_transform {
  transform: none;
}
</style>

<style lang="scss" scoped>
.icon {
  cursor: pointer;
}
</style>
