<template>
  <basic-box
    :type="type"
    :bg="bg">
    <div
      :class="$style.subtitle"
      v-html="sub"></div>
    <div :class="$style.header">
      <span :class="$style.icon_parent">
        <span
          v-if="icon"
          :class="[
            $style.icon,
            $style[iconColor],
          ]"
          v-html="icon"></span>
        <span
          v-if="innerIcon"
          :class="[
            $style.inner_icon,
            $style[iconColor],
          ]"
          v-html="innerIcon"></span>
      </span>
      <component :class="titleClassName" :is="tag" v-html="title"></component>
      <template v-if="guideIcon.hoverText">
        <spacer y="1" />
        <GuideIcon
          :icon="guideIcon.icon"
          :boxSize="guideIcon.boxSize"
          :hoverText="guideIcon.hoverText"
          :noTransform="guideIcon.noTransform"
        />
      </template>
    </div>
    <spacer :y="underTitle" />
    <slot />
    <div :class="$style.right_alignment">
      <slot name="btn" />
    </div>
  </basic-box>
</template>

<script>
import BasicBox from './BasicBox';
import Spacer from './Spacer';
import GuideIcon from '@/views/components/GuideIcon.vue';

export default {
  name: 'ContentsBox',
  components: {
    BasicBox,
    Spacer,
    GuideIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    sub: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      default: '',
    },
    innerIcon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: 'black',
      validator: (value) => ['black', 'orange', 'blue', 'green', 'gray', 'pink'].includes(value),
    },
    tag: {
      type: String,
      default: 'h3',
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => ['default', 'inner'].includes(value),
    },
    bg: {
      type: String,
      default: 'white',
      validator: (value) => ['white', 'gray', 'blue'].includes(value),
    },
    noHeaderSpacer: {
      type: Boolean,
      default: false,
    },
    guideIcon: {
      type: Object,
      default: () => ({
        icon: '',
        hoverText: '',
        boxSize: 300,
        noTransform: false,
      }),
    },
  },
  computed: {
    titleClassName() {
      return `${this.$style.title} ${this.$style[this.type]}`;
    },
    underTitle() {
      const space = this.type === 'default' && !this.noHeaderSpacer ? 2 : 0;
      return space;
    },
  },
};
</script>

<style lang="scss" module>
.subtitle {
  position: relative;
  left: 35px;
  bottom: -5px;
  @include sm-view {
    left: 27px;
    font-size: .9em;
  }
}
.header {
  position: relative;
  display: flex;
  align-items: center;
}
.icon_parent {
  position: relative;
  > span {
    &.black {
      // 特別指定なし
    }
    &.orange {
      color: var(--orange-main);
    }
    &.blue {
      color: var(--blue-main);
    }
    &.green {
      color: var(--green-main);
    }
    &.gray {
      color: var(--gray-sub);
    }
    &.pink {
      color: var(--pink);
    }
  }
}
.icon {
  display: block;
  margin-right: 10px;
  font-size: 25px;
  line-height: 1;

  @include sm-view {
    font-size: 20px;
    margin-right: 7px;
  }
}
.inner_icon {
  position: absolute;
  top: 50%;
  left: 37%;
  transform: translate(-50%, -60%);
  font-size: 10px;
}

.title {
  display: block;
  font-weight: bold;

  &.default {
    font-size: 20px;

    @include sm-view {
      font-size: 16px;
    }
  }

  &.inner {
    font-size: 14px;
    // margin: 0 auto;
    text-align: center;

    @include sm-view {
      font-size: 13px;
    }
  }
  small {
    font-size: 12px;
    color: red;
    margin-left: 1em;
    @include sm-view {
      display: block;
    }
  }
}
.right_alignment {
  margin-left: auto;
}
</style>
