<template>
  <div>
    <v-date-picker
      ref="calendar"
      :attributes="attrs"
      :select-attribute="selectAttribute"
      :model-config="modelConfig"
      :min-date="minDate"
      :max-date="maxDate"
      :disabled-dates="disabledDates"
      :initial-date="initialDate"
      :masks="{
        title: 'YYYY年 MM月',
        dayPopover: 'YYYY年MM月DD日',
      }"
      v-model="date"
      is-expanded
      :is-range="isRange"
      :rows="rows"
      :columns="columns"
      :step="step"
      locale="ja-JP"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import cf from '@/mixins/commonFunctions.js';

const MODE_RANGE = 'range';

export default {
  mixins: [cf],
  props: {
    schedule: {
      type: Object,
    },
    information: {
      type: Boolean,
      default: false,
    },
    selectableSunSat: { // 土日を平日と同様に扱うか
      type: Boolean,
      default: false,
    },
    selectableHoliday: { // 祝日を選択可能にするか
      type: Boolean,
      default: false,
    },
    initialDate: { // カレンダーの初期選択日
      type: String,
      default: null,
    },
    selectOnlineDate: { // ３営業日後から選択可能、19日までは当月まで20日以降は翌月末まで有効
      type: Boolean,
      default: false,
    },
    registSchedule: { // ３営業日から選択可能
      type: Boolean,
      default: false,
    },
    selectableDates: { // 選択可にしたい日付（'YYYY-MM-DD'形式の日付文字列配列）
      type: Array,
      default: () => [],
    },
    specifiedMinDate: { // 選択可能な最小日の明示指定
      type: Object, // Date || String
      default: null,
    },
    mode: { // カレンダーのモード
      type: String,
      default: 'single', // 'single' || 'range'
    },
    rows: { // カレンダーの行数
      type: Number,
      default: 1,
    },
    columns: { // カレンダーの列数
      type: Number,
      default: 1,
    },
    optionalAttrs: { // カレンダーに追加する属性
      type: Array,
      default: () => [],
    },
    specifiedMaxDate: { // 選択可能な最大日の明示指定
      type: Object, // Date || String
      default: null,
    },
  },
  data() {
    return {
      date: this.mode === MODE_RANGE
        ? { // 範囲選択時の選択日
          start: null,
          end: null,
        }
        : null, // 通常選択時の選択日
      attrs: [],
      selectAttribute: {
        highlight: {
          style: {
            'background-color': this.mode === MODE_RANGE
              ? '#87cefa' // 範囲選択中の色味に合わせる: lightskyblue
              : 'var(--orange-main)', // 通常選択時のハイライト色
          },
        },
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      // endDateOfNextMonth: moment().add(1, 'months').endOf('month').toDate(), // 翌月末日
    };
  },
  created() {
    if (this.schedule) {
      this.schedule.forEach((row) => {
        const data = {
          key: row.id,
          highlight: {
            style: {
              'background-color': row.color || 'var(--orange-main)',
            },
          },
          popover: row.label ? {
            label: row.label,
            visibility: 'hover',
            hideIndicator: true,
          } : null,
          dates: new Date(row.date),
        };
        this.attrs.push(data);
      });
    }
    // 祝日表示対応
    if (!this.selectableHoliday) {
      this.holiday.list.forEach((row) => {
        const data = {
          key: row.date,
          popover: {
            label: row.label,
            visibility: 'hover',
            hideIndicator: true,
          },
          dates: new Date(row.date),
        };
        this.attrs.push(data);
      });
    }
    if (this.initialDate) {
      this.date = this.initialDate;
    }
    // 任意の属性を追加
    if (this.optionalAttrs.length > 0) {
      this.optionalAttrs.forEach((row) => {
        this.attrs.push(row);
      });
    }
  },
  mounted() {
    if (this.initialDate) {
      this.$refs.calendar.move(this.initialDate);
    }
  },
  computed: {
    ...mapState(['holiday']),
    minDate() {
      let result = null;
      if (!this.information) {
        result = new Date();
      }
      if (this.selectOnlineDate || this.registSchedule) {
        result = cf.calcSalesMinDate(this.holiday);
      }
      if (this.specifiedMinDate) {
        result = moment(this.specifiedMinDate).toDate();
      }

      return result;
    },
    maxDate() {
      // 選択可能な最大日の明示指定がある場合はその日付を返す
      if (this.specifiedMaxDate) {
        return moment(this.specifiedMaxDate).toDate();
      }
      let result = null;
      if (!this.information) {
        result = moment().add(1, 'months').endOf('month').toDate(); // 翌月末日
      }
      // 19日までは当月末まで、20日以降は翌月末まで
      if (this.selectOnlineDate) {
        const today = moment(new Date());
        if (today.date() < 20) {
          result = today.endOf('month').toDate();
        } else {
          result = today.add(1, 'month').endOf('month').toDate();
        }
      }
      // 14日から翌月のシフト登録可能に
      if (this.registSchedule) {
        const today = moment(new Date());
        if (today.date() < 14) {
          result = today.endOf('month').toDate();
        } else {
          result = today.add(1, 'month').endOf('month').toDate();
        }
      }
      return result;
    },
    disabledDates() {
      const dates = [];

      // selectableDates が指定された場合は、その日付以外はすべて選択不可にする
      // ただし、この制御を有効にするのはカレンダーの開始/終了日が定義される場合のみ（カレンダーの年月移動に制限がない場合は selectableDates による制御はしない∵選択不可日付情報が大量に必要になってしまうから）
      if (this.minDate !== null && this.maxDate !== null && this.selectableDates && this.selectableDates.length > 0) {
        const currentDate = new Date(this.minDate); // 選択可能開始日
        while (currentDate <= this.maxDate) { // 表示対象の日付すべてでループ
          const currentDateStr = this.filterMoment(currentDate, 'YYYY-MM-DD');
          // 選択可能日ではない場合は不可日に追加する
          if (!this.selectableDates.find((selectableDate) => selectableDate === currentDateStr)) {
            dates.push({
              start: currentDateStr,
              end: currentDateStr,
            });
          }
          currentDate.setDate(currentDate.getDate() + 1); // 次の日にする
        }
        return dates;
      }

      // 祝日対応
      if (!this.selectableHoliday) {
        this.holiday.list.forEach((row) => {
          dates.push({
            start: row.date,
            end: row.date,
          });
        });
      }
      // 土日対応
      if (!this.selectableSunSat) {
        dates.push({ weekdays: [1, 7] });
      }
      // 任意属性対応
      if (this.optionalAttrs.length > 0) {
        this.optionalAttrs.forEach((row) => {
          if (row.disabled) {
            dates.push(row.dates);
          }
        });
      }
      return dates;
    },
    isRange() {
      return this.mode === MODE_RANGE;
    },
    step() {
      if (this.rows === 1) {
        // 1行表示の場合は1月ずつ移動
        return 1;
      }
      // 2行以上表示の場合は完全に次ページへ移動
      return this.rows * this.columns;
    },
  },
  watch: {
    date: {
      handler() {
        if (this.isRange) {
          // 範囲選択時
          this.$emit('sendRange', this.date);
        } else {
          // 通常選択時
          this.$emit('sendDate', this.date);
        }
      },
      deep: true, // range の場合は deep: true が必要
    },
  },
};
</script>

<style lang="scss" module>
</style>
