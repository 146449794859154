<template>
  <div>
    <!-- チャート -->
    <template v-if="targetUser">
      <h2 :class="[$style.title, $style.userChartWho5Title]">
        <span v-if="targetUser.grade">{{ targetUser.grade }}</span>
        <span>{{ targetUser.username }}</span>
      </h2>
      <basic-btn
        tag="button"
        size="xs"
        @click="clearTargetUser()"
      >
        <i
          class="fa-regular fa-user-chart"
        /> 対象者変更
      </basic-btn>
      <spacer :y="2"/>
      <div :class="$style.userChart">
        <p v-if="who5Answers.length === 0">有効な回答がありません</p>
        <template v-if="lineChartData4Display">
          <!-- CHART_POINTごとの凡例を表示する -->
          <div>
            <ul :class="$style.legends">
              <li v-for="(point, index) in CHART_POINT" :key="index">
                <span :class="[
                  $style.square,
                  $style[`type${index}`],
                ]" />
                <span>{{ helper.master.labels.who5answer.type[index] }}</span>
              </li>
            </ul>
          </div>
          <Line
            v-if="lineChartData4Display"
            :chart-options="lineChartData4Display.options"
            :chart-data="lineChartData4Display.data"
            css-classes="graphStyle"
            width="400"
            height="200"
            :class="$style['col-9']"
          />
          <div :class="$style.chartSlider">
            <div
              ref="slider"
              class="slider-styled"
            />
          </div>
        </template>
      </div>
    </template>

    <!-- 対象者検索 -->
    <div v-show="!targetUser">
      <h2 :class="[$style.title, $style.userChartWho5Title]">
        <span>対象者を選択してください</span>
      </h2>

      <stack-item>
        <contents-box>
          <form v-on:submit="registKeyword">
            <div :class="$style.wordsearch">
              <div :class="$style.wordsearch_form">
                <word-search-form
                  placeholder="キーワードで検索"
                  v-on:sendValue="receiveValue" />
              </div>
              <i
                :class="$style.wordsearch_btn"
                class="fa-regular fa-magnifying-glass"
                v-on:click="registKeyword"/>
            </div>
          </form>
          <spacer :y="2"/>
          <div>
            <!-- アカウントタイプ -->
            <ul :class="$style.select">
              <li v-for="item in searchCondition.accountTypeItems" :key="item">
                <input
                  type="checkbox"
                  :id="item.name"
                  :value="item.value"
                  v-on:change="updateType($event, 'account')">
                <label :for="item.name">{{ item.label }}</label>
              </li>
            </ul>
            <spacer :y="1"/>
            <!-- 当コンポーネントでは学校指定が必須のため、検索条件としては不要 -->
            <!-- 学校タイプ -->
            <!-- <ul :class="$style.select">
              <li v-for="item in schoolTypeItems" :key="item">
                <input
                  type="checkbox"
                  :id="item.name"
                  :value="item.value"
                  v-on:change="updateType($event, 'school')">
                <label :for="item.name">{{ item.label }}</label>
              </li>
            </ul>
            <spacer :y="1"/> -->
            <!-- 非アクティブユーザーの表示 -->
            <ul :class="$style.select">
              <li v-for="item in searchCondition.activeTypeItems" :key="item">
                <input
                  type="checkbox"
                  :id="item.name"
                  :value="item.value"
                  v-on:change="updateType($event, 'active')">
                <label :for="item.name">{{ item.label }}</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  name="riskAlert"
                  id="riskAlert"
                  v-model="searchCondition.riskAlert">
                <label for="riskAlert">リスク案件</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  name="kanasort"
                  id="kanasort"
                  v-model="searchCondition.kanasort">
                <label for="kanasort">五十音順</label>
              </li>
            </ul>
            <!-- MEMO: チャート表示するのは本登録ユーザのみのため、仮登録/本登録の検索条件は不要 -->
            <!-- <spacer :y="1"/>
            <ul :class="$style.select">
              <li v-for="item in temporaryTypeItems" :key="item">
                <input
                  type="checkbox"
                  name="temporary"
                  :id="item.name"
                  :value="item.value"
                  @change="updateType($event, 'temporary')">
                <label :for="item.name">{{ item.label }}</label>
              </li>
            </ul> -->
          </div>
          <spacer :y="1"/>
          <div :class="$style.birth">
            <dl>
              <dt><i class="fa-solid fa-calendar-days"></i>生年月日</dt>
              <dd>
                <div>
                  <date-small-picker
                    :name="'startBirthday'"
                    v-on:sendDate="receiveDate" />
                </div>
                  <date-small-picker
                    :name="'endBirthday'"
                    v-on:sendDate="receiveDate" />
              </dd>
            </dl>
            <spacer :y="1"/>
            <dl>
              <dt><i class="fa-solid fa-calendar-plus"></i>登録年月日</dt>
              <dd>
                <div>
                  <date-small-picker
                    :name="'startCreatedAt'"
                    v-model="searchCondition.startCreatedAt"
                    v-on:sendDate="receiveDate, autoEnter()" />
                </div>
                  <date-small-picker
                    :name="'endCreatedAt'"
                    v-model="searchCondition.endCreatedAt"
                    v-on:sendDate="receiveDate" />
              </dd>
            </dl>
            <spacer :y="1"/>
            <dl v-if="!data.school.is_company">
              <!-- 当コンポーネントでは学校指定が必須のため、検索条件としては不要 -->
              <!-- <dt>学校ソート</dt>
              <dd>
                <select
                  :class="$style.select_box"
                  name="school"
                  id="school"
                  v-on:change="updateSchool">
                  <option :value="0" :selected="targetSchool === 0">選択してください</option>
                  <option
                    v-for="n of schoolsList"
                    :key="n"
                    :value="n.id"
                    v-show="targetSchoolType.includes(n.school_type) || targetSchoolType.length === 5"
                    :selected="n.id === targetSchool">{{ n.name }}</option>
                </select>
              </dd> -->
              <dt :class="$style.school_year">学年</dt>
              <dd>
                <select
                  :class="$style.select_box"
                  name="schoolYear"
                  id="schoolYear"
                  v-on:change="updateSchoolYear">
                  <option :value="null" :selected="schoolYear === 0">選択なし</option>
                  <option
                    v-for="n of 6"
                    :key="n"
                    :value="n">{{ n }}</option>
                  <option :value="0">登録なし</option>
                </select>
              </dd>
            </dl>

          </div>
        </contents-box>

        <!-- 結果表示部 -->
        <loader-simple :flag="flag.loading.searchedUsers">
          <spacer
            v-show="!flag.loading.searchedUsers"
            :y="3"
          />
          <contents-box
            v-show="!flag.loading.searchedUsers"
            :title="`${searchedUsers.length}件`"
          >
            <div>
              <ul :class="$style.flex_elem">
                <li
                  v-for="user in searchedUsers"
                  :key="user.id"
                  :class="user.flag === 1 ? null : $style.invalid"
                  @click="setTargetUser(user)"
                ><label>{{ user.username }}</label>
                </li>
              </ul>
            </div>
          </contents-box>
        </loader-simple>

      </stack-item>
    </div>

    <div :class="$style.close" v-on:click="hideModal"></div>

  </div>
</template>

<script>
/** chatrjs */
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import 'chartjs-adapter-moment';

/** nouislider */
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

import moment from 'moment';

import { mapState } from 'vuex';

import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import StackItem from '@/views/components/StackItem.vue';
import WordSearchForm from '@/views/components/WordSearchForm.vue';
import DateSmallPicker from '@/views/components/DateSmallPicker.vue';

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale);

const DATE_FORMAT = 'YYYY/MM/DD HH:mm';
const MULTIPLIER = 1000 * 60 * 60; // 秒->分->時

const CHART_POINT = {
  // 色について
  // frontend/vue/src/assets/styles/vars/_color.scss より

  // // orange
  // --orange-main: #e7a223;
  // --orange-sub: #f0c26f;
  // --orange-main-rgb: 231, 162, 35;

  // // blue
  // --blue-main: #60b2c1;
  // --blue-sub: #def6fa;
  // --blue-main-rgb: 96, 178, 193;

  // // green
  // --green-main: #60c192;
  // --green-sub: #ade1c8;
  // --green-main-rgb: 96, 193, 146;

  1: { // オンライン健康相談
    COLOR: { // orange
      R: 231,
      G: 162,
      B: 35,
    },
    STYLE: 'rect',
  },
  2: { // テキスト健康相談
    COLOR: { // blue
      R: 96,
      G: 178,
      B: 193,
    },
    STYLE: 'rect',
  },
  3: { // バーチャル健康相談
    COLOR: { // green
      R: 96,
      G: 193,
      B: 146,
    },
    STYLE: 'rect',
  },
  4: { // ココロの健康診断
    COLOR: {
      R: 100,
      G: 149,
      B: 237,
    },
    STYLE: 'circle',
  },
};

export default {
  name: 'user-chart-who5',
  props: [
    'data',
    // school: {},
    // user: {
    //   id,
    //   username,
    //   grade, // 肩書（'○年生' || '保護者' || '教職員' || null）
    // }
  ],
  components: {
    Line,
    Spacer,
    BasicBtn,
    ContentsBox,
    StackItem,
    WordSearchForm,
    DateSmallPicker,
  },
  data() {
    return {
      CHART_POINT,
      targetUser: null,
      activeUsers: [],
      activeUserGroups: [], // TODO 学年でグルーピング
      keyword: '',
      who5Answers: [],
      lineChartDataAll: [], // 全データ
      lineChartData4Display: null, // チャート表示用データ
      displayMoment: { // スライダーによる表示範囲
        left: 0,
        right: 0,
      },
      flag: {
        loading: {
          activeUsers: true,
          searchedUsers: false,
        },
      },

      // 以下、検索条件用（/views/pages/Operator/tabs/UserList.vue から拝借のうえ一部カスタム）
      searchedUsers: [],
      searchCondition: {
        keyword: null,
        targetColumn: [],
        startBirthday: null,
        endBirthday: null,
        startCreatedAt: null,
        endCreatedAt: null,
        kanasort: false,
        schoolsList: [],
        targetSchool: 0,
        schoolYear: null,
        riskAlert: false,
        accountTypeItems: [
          {
            name: 'user',
            label: this.data.school.is_company ? 'プログラム参加者' : '在学生',
            value: 1,
            checked: false,
          },
          // { // MEMO: 保護者はチャート表示しない
          //   name: 'parent',
          //   label: '保護者',
          //   value: 2,
          //   checked: false,
          // },
          {
            name: 'teacher',
            label: this.data.school.is_company ? '企業担当者' : '教職員',
            value: 3,
            checked: false,
          },
        ],
        activeTypeItems: [
          {
            name: 'nonActiveUser',
            label: '非アクティブユーザー含む',
            value: 1,
            checked: false,
          },
          // {
          //   name: 'nonActiveSchool',
          //   label: '非アクティブ学校のユーザー含む',
          //   value: 2,
          //   checked: false,
          // },
        ],
      },
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    isCompany() {
      return this.data.school.is_company;
    },
    selectableUsers() {
      return this.activeUsers.filter((user) => {
        if (!this.keyword) {
          return true;
        }
        // カナ
        if (user.kana && user.kana.includes(this.keyword)) {
          return true;
        }
        // 名前
        return user.username.includes(this.keyword);
      });
    },
    targetAccountType() {
      let array = [];
      let all = true;
      this.searchCondition.accountTypeItems.forEach((row) => {
        if (row.checked) {
          all = false;
          array.push(row.value);
        }
      });
      if (all) array = [1, 3]; // 2: 保護者は含めない
      return array;
    },
  },
  async mounted() {
    this.targetUser = this.data.user || null;
    // 対象組織の全ユーザを非同期で検索しておく
    this.getUsers(true);
    // チャートを表示する
    this.showChart();
  },
  methods: {
    setTargetUser(user) {
      this.targetUser = user;
      this.targetUser.grade = this._getGrade(user);
      // チャート表示
      this.showChart();
    },
    clearTargetUser() {
      this.targetUser = null;
      this.lineChartData4Display = null;
    },
    async showChart() {
      if (!this.targetUser) {
        return;
      }
      await this.setLineChartData();
      this.setSlider();
    },
    async setLineChartData() {
      // 対象ユーザのすべてのwho-5情報を取得する
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/who5answer/get/list',
          params: {
            user_id: this.targetUser.id,
          },
        });
        this.who5Answers = response.data.who5answers;
      } catch (e) {
        alert('who5データの取得に失敗しました');
        throw e;
      }
      if (this.who5Answers.length === 0) {
        return;
      }

      // 全チャートデータを生成する
      this.lineChartDataAll = this.who5Answers.map((row) => {
        let scoreTotal = 0;
        (JSON.parse(row.answers)).forEach((score) => {
          scoreTotal += score;
        });
        return {
          y: scoreTotal,
          x: moment(row.created_at).format(DATE_FORMAT),
          created_at: row.created_at,
          type: row.type,
        };
      });
      // 表示用データを生成する
      this.lineChartData4Display = {
        data: {
          datasets: [
            {
              label: 'スコア',
              data: this.lineChartDataAll, // 最初は全データ
              pointRadius: 10, // ポイントの半径
              pointBackgroundColor: (context) => { // ポイントの色
                if (context.type === 'dataset') {
                  return; // なぜかdatasetがここに入ってくる場合があるのでその際はスルー
                }
                return `rgba(${CHART_POINT[context.raw.type].COLOR.R}, ${CHART_POINT[context.raw.type].COLOR.G}, ${CHART_POINT[context.raw.type].COLOR.B}, 0.5)`;
              },
              pointBorderColor: (context) => { // ポイントの枠線の色
                if (context.type === 'dataset') {
                  return; // なぜかdatasetがここに入ってくる場合があるのでその際はスルー
                }
                return `rgb(${CHART_POINT[context.raw.type].COLOR.R}, ${CHART_POINT[context.raw.type].COLOR.G}, ${CHART_POINT[context.raw.type].COLOR.B})`;
              },
              pointStyle: (context) => { // ポイントの形状
                // @see https://www.chartjs.org/docs/latest/configuration/elements.html#point-styles
                if (context.type === 'dataset') {
                  return; // なぜかdatasetがここに入ってくる場合があるのでその際はスルー
                }
                return CHART_POINT[context.raw.type].STYLE;
              },
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false, // 凡例を非表示
            },
            tooltip: { // @see https://www.chartjs.org/docs/latest/configuration/tooltip.html
              callbacks: {
                title: (context) => moment(context[0].dataset.data[context[0].dataIndex].created_at).format(DATE_FORMAT),
                label: (context) => `${context.raw.y}点（${this.helper.master.labels.who5answer.type[context.raw.type]}）`,
              },
              usePointStyle: true,
              titleFont: {
                size: 18,
              },
              bodyFont: {
                size: 16,
              },
            },
            // 5点ライン描画
            annotation: { // @see https://www.chartjs.org/chartjs-plugin-annotation/latest/guide/types/line.html
              annotations: {
                line1: {
                  type: 'line',
                  yMin: 5,
                  yMax: 5,
                  borderColor: 'rgb(255, 99, 132)',
                  borderWidth: 2,
                },
              },
            },
          },
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'month',
                displayFormats: {
                  month: 'YYYY/MM',
                },
              },
              max: new Date(), // 右端
            },
            y: {
              min: -1.1, // 0点の場合にポイントが見づらくなることへの対処
              max: 26.1, // 25点の場合にポイントが見づらくなることへの対処
              ticks: {
                callback: (value) => ([0, 5, 10, 15, 20, 25].includes(value) ? value : null), // y軸目盛の明示指定
              },
            },
          },
        },
      };
    },
    setSlider() {
      if (this.who5Answers.length === 0) {
        return;
      }
      // 左端設定
      this.displayMoment.left = moment(this.who5Answers[0].created_at).add(-1, 'weeks'); // 左端にデータが来ると見づらいので1週間の余裕を設ける
      const min = Math.round(this.displayMoment.left.valueOf() / MULTIPLIER);

      // 右端設定
      this.displayMoment.right = moment(new Date());
      const max = Math.round(this.displayMoment.right.valueOf() / MULTIPLIER);

      // スライダー表示
      noUiSlider.create(this.$refs.slider, {
        start: [min, max],
        connect: true,
        step: 1, // 1msecごとのメモリ
        range: {
          min,
          max,
        },
        tooltips: {
          // tooltips are output only, so only a "to" is needed
          to: (numericValue) => this._convertSliderValueToMoment(numericValue).format(DATE_FORMAT),
        },
      });
      // スライダー移動時のイベントリスナ
      this.$refs.slider.noUiSlider.on('update', (values, handle) => {
        if (handle === 0) {
          // 左側のスライダー移動時
          this.displayMoment.left = this._convertSliderValueToMoment(values[handle]);
          this.lineChartData4Display.options.scales.x.min = this.displayMoment.left.format(DATE_FORMAT); // グラフのx軸左端を調整
        } else {
          // 右側のスライダー移動時
          this.displayMoment.right = this._convertSliderValueToMoment(values[handle]);
          this.lineChartData4Display.options.scales.x.max = this.displayMoment.right.format(DATE_FORMAT); // グラフのx軸右端を調整
        }
      });
    },
    _convertSliderValueToMoment(value) {
      return moment(Math.round(parseInt(value, 10) * MULTIPLIER));
    },
    // 肩書を取得する
    _getGrade(user) {
      if (this.isCompany) {
        return null;
      }
      if (user.account_type === 1 && user.school_year) {
        return `${user.school_year}年生`;
      }
      return this.helper.master.labels.users.account_type[user.account_type];
    },

    hideModal(args = null) {
      this.$store.dispatch('modal/contents/hideModal', args, { root: true });
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    // 以下、検索用（/views/pages/Operator/tabs/UserList.vue からコピーのうえでカスタム）
    getUsers(init = false) {
      this.flag.loading.searchedUsers = true;
      if (!init) {
        this.showLoading();
      }

      const params = {
        orderBy: 'desc',
        includeDeleted: this.searchCondition.activeTypeItems[0].checked ? 1 : 0,
        // includeDeletedSchool: this.searchCondition.activeTypeItems[1].checked ? 1 : 0,
        school_id: this.data.school.id,
        school_year: this.searchCondition.schoolYear,
        targetColumn: this.searchCondition.keyword && this.searchCondition.keyword !== '' ? this.searchCondition.targetColumn : null,
        keyword: this.searchCondition.keyword && this.searchCondition.keyword !== '' ? this.searchCondition.keyword : null,
        accountTypes: this.targetAccountType,
        // schoolTypes: this.targetSchoolType,
        isRisk: 1,
        riskAlert: this.searchCondition.riskAlert ? 1 : 0,
        betweenBirthday: [this.searchCondition.startBirthday, this.searchCondition.endBirthday],
        betweenCreatedAt: [this.searchCondition.startCreatedAt, this.searchCondition.endCreatedAt],
        // temporaryOnly: this.temporaryTypeItems[0].checked ? 1 : 0,
        // noTemporary: this.temporaryTypeItems[1].checked ? 1 : 0,
        // page: this.page,
        // limit: this.riskAlert ? 99999 : this.limit,
        isSchool: 0, // 学校情報は不要
      };
      if (this.searchCondition.kanasort) { // 五十音順にチェックがあればあいうえおソート
        params.orderBy = 'asc';
        params.order = 'kana';
      }

      this.axios({
        method: 'GET',
        url: '/v1/user/get/search',
        params,
      })
        .then((response) => {
          const res = response.data;

          // MEMO: レイアウト調整が必要な場合はこのロジックを流用する
          // const users = res.users.data;
          // if (users && users.length && this.searchCondition.keyword) {
          //   const searchTargets = [...this.searchCondition.targetColumn, 'customer_id'];
          //   users.forEach((user) => {
          //     searchTargets.forEach((column) => {
          //       if (user[column] && String(user[column]).includes(this.searchCondition.keyword)) {
          //         if (column === 'id') user.rawId = Number(user[column]);
          //         user[column] = String(user[column]).replace(new RegExp(this.searchCondition.keyword, 'g'), `<span class="highlight">${this.searchCondition.keyword}</span>`);
          //       }
          //     });
          //   });
          // }

          this.searchedUsers = res.users.data;
          this.flag.loading.searchedUsers = false;
          if (!init) {
            this.hideLoading();
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    /** 誕生日と登録日の始点と終点取得 */
    receiveDate(args) {
      if (args.date === 'Invalid date') this.searchCondition[args.name] = null;
      else this.searchCondition[args.name] = args.date;
      // this.deleteConditions();
    },
    /** 登録日年月日の開始点のみ入力されたら終点を自動入力 */
    autoEnter() {
      if (this.searchCondition.startCreatedAt && !this.searchCondition.endCreatedAt) {
        // 終点に今日を自動で入力
        this.searchCondition.endCreatedAt = moment(new Date()).format('YYYY-MM-DD');
      }
    },
    /** タイプによる絞り込み(アカウント・学校) */
    updateType(e, name) {
      this.searchCondition[`${name}TypeItems`].forEach((row) => {
        if (e.target.id === row.name) row.checked = e.target.checked;
      });
      // this.deleteConditions();
    },
    updateSchoolYear(e) {
      this.searchCondition.schoolYear = Number(e.target.value);
      if (e.target.value === '選択なし') this.searchCondition.schoolYear = null;
      // this.deleteConditions();
    },
    receiveValue(data) {
      const userIdRegex = new RegExp(/^[0-9０-９]{4}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{1,}(-|ー|−|―|‐|-|ｰ)[0-9０-９]{4}$/);
      if (userIdRegex.test(data.value)) {
        const userId = data.value.split(/[-|ー|−|―|‐|-|ｰ]/);
        this.searchCondition.keyword = String(Number(this.toHalfNumber(userId[2]))); // user.id指定
        this.searchCondition.targetColumn = ['id', 'old_id', 'tel'];
      } else {
        this.searchCondition.keyword = data.value;
        this.searchCondition.targetColumn = ['username', 'kana', 'email', 'tel', 'id', 'old_id'];
      }
      // this.deleteConditions();
    },
    registKeyword(e) {
      e.preventDefault();
      if (this.searchCondition.keyword === '') this.searchCondition.keyword = null;
      // this.resetStore();
      this.getUsers();
    },
    toHalfNumber(str) {
      return str.replace(/[０-９]/g, (s) => String.fromCharCode(s.charCodeAt(0) - 0xFEE0));
    },
  },
};
</script>

<style lang="scss" module>
.userChartWho5Title {
  > span {
    &:first-child {
      margin-right: 1rem;
    }
  }
}
.userChart {
  max-width: 1040px;
  margin: 0 auto;
}
.chartSlider {
  margin: 3rem 1.5rem 0 1.5rem;
}

.input {
  outline: none;
  border: none;
  background-color: var(--gray-sub);
  min-width: 20rem;
  padding: 10px;
  border-radius: 45px;
}

.legends {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > li {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 12px;
    // 最後の要素意外にmargin-rightを付与
    &:not(:last-child) {
      margin-right: 1rem;
    }
    .square {
      display: block;
      width: 15px;
      height: 15px;
      margin-right: 3px;
      &.type {
        &1 { // オンライン健康相談
          background-color: rgba(var(--orange-main-rgb), 0.5);
          border: 1px solid var(--orange-main);
        }
        &2 { // テキスト健康相談
          background-color: rgba(var(--blue-main-rgb), 0.5);
          border: 1px solid var(--blue-main);
        }
        &3 { // バーチャル健康相談
          background-color: rgba(var(--green-main-rgb), 0.5);
          border: 1px solid var(--green-main);
        }
        &4 { // ココロの健康診断
          background-color: rgba(100, 149, 237, 0.5);
          border: 1px solid rgb(100, 149, 237);
        }
      }
    }
  }
}
.flex_elem {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  column-gap: 5px;
  > li {
    border: 1px solid var(--gray-sub);
    border-radius: 5px;
    font-size: .9em;
    padding: 1px 1px 1px 10px;
    overflow-wrap: break-word;
    cursor: pointer;
    word-break: break-all;
    &:hover {
      opacity: .6;
    }

    flex-basis: calc(25% - 8px); /* 基本は4列表示 */
    @include md-view {
      flex-basis: calc(33.33% - 6px); /* 3列表示 */
    }
    @include sm-view {
      flex-basis: calc(50% - 4px); /* 2列表示 */
    }
    @include xs-view {
      flex-basis: calc(100% - 2px); /* 1列表示 */
    }

    &.invalid {
      background-color: var(--gray-sub);
    }
  }
}
.link {
  cursor: pointer;
  color: var(--black);
  &:hover {
    text-decoration: underline;
  }
  > i {
    margin-right: .5rem;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: var(--gray-main);
  border-radius: 50%;
  cursor: pointer;
  &::before, &::after {
    content: '';
    width: 3px;
    height: 30px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  @include sm-view {
    width: 20px;
    height: 20px;
    &::before, &::after {
      content: '';
      width: 2px;
      height: 13px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}

// 以下、/views/pages/Operator/tabs/UserList.vue からコピー
.wordsearch {
  display: flex;
  align-items: center;
  &_form {
    flex: 1;
  }
  &_btn {
    background-color: var(--orange-main);
    color: #fff;
    margin-left: 10px;
    padding: 15px 30px;
    border-radius: 30px;
    font-weight: bold;
    cursor: pointer;
  }
}
.icons {
  display: flex;
  align-items: center;

  @include sm-view {
    margin-top: 10px;
    display: block;
    text-align: right;
  }
}
.detail {
  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
    .icon {
      font-size: 20px;
      color: var(--orange-main);
      margin-right: 10px;
      &_polarity {
        padding: 3px 0 0 3px;
      }
      &.virtual {
        font-size: 18px;
        color: var(--green-main);
      }
      &.badge {
        font-size: 22px;
        margin: 0 12px 0 3px;
      }
    }
  }
}
.invalid_school {
  font-size: 14px;
  padding: 2px 12px;
  border-radius: 4px;
  background-color: var(--gray-main);
  mix-blend-mode: multiply;
}
.noflex {
  &_list {
    display: block !important;
  }
  &_table {
    padding-left: 30px;
    font-size: .8em;
    border-spacing: 0 8px;
    tr {

      td {
        width: 150px;
        border-bottom: 1px solid var(--gray-main);
      }
    }
  }
  &_nohistory {
    padding-left: 30px;
  }
}
.risk_table {
  &_next {
    padding-left: 2em;
  }
}
.only_pc {
  @include sm-view {
    display: none;
  }
}
.only_sp {
  display: none;
  @include sm-view {
    display: block;
  }
}
.birth {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 7em;
    i {
      margin-right: .5em;
    }
    &.school_year {
      width: 3em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 1em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        &:before {
          content: "~";
          margin-right: 1em;
        }
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}
.select {
  display: flex;
  > li {
    &:not(:first-child) {
      margin-left: 1em;
    }
  }
  &_box {
    padding: 13px;
    background-color: var(--gray-sub);
    border: none;
    outline: none;
    border-radius: 8px;
    appearance: none;

    &_wrap {
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
.label {
  display: flex;
  font-weight: bold;
  font-size: 20px;
  align-items: center;

  @include sm-view {
    font-size: 16px;
  }
  .large {
    margin-left: 1em;
    font-size: 24px;
    @include sm-view {
      font-size: 18px;
    }
  }
}
</style>

<style>
/* noUiSliderへのスタイルパッチ群 */
.noUi-tooltip {
  font-size: 12px;
}
.noUi-connect {
  background: var(--gray-main);
}
</style>
