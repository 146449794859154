<template>
  <div>
    <spacer :y="5"/>

    <stack-item>
      <contents-box>
        <div :class="$style.search">
          <div :class="$style.searchArea">
            <dl>
              <dt>表示対象</dt>
              <dd>
                <select
                  :class="$style.select_box"
                  v-model="selectedSchoolId"
                  v-on:change="changeSchool()"
                  :disabled="flag.getAllData"
                >
                  <option value="">選択してください</option>
                  <option
                    v-for="school in schools"
                    :key="school.id"
                    :value="school.id"
                  >{{ school.name }}</option>
                </select>
                <div>
                  <input
                    type="checkbox"
                    id="operator-cocomoni-alldata-check"
                    v-on:change="changeAllDataCheck"
                  >
                  <label for="operator-cocomoni-alldata-check">すべて</label>
                </div>
              </dd>
            </dl>

            <!-- 検証用 -->
            <!-- <spacer :y="1"/>
            <dl>
              <dt>ココモニチェック 集計対象</dt>
              <dd>
                <input
                  type="checkbox"
                  id="operator-cocomoni-who5answer-check"
                  v-on:change="changeWho5Check"
                >
                <label for="operator-cocomoni-who5answer-check">健康相談予約時の回答データを含める</label>
              </dd>
            </dl> -->

          </div>
        </div>
      </contents-box>
    </stack-item>

    <cocomoni-dashboard
      v-if="showDashboard"
      :key="componentKey"
      :school="selectedSchool"
      :getAllData="flag.getAllData"
      :getAllTypesOfWho5Answer="flag.getAllTypesOfWho5Answer"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex';

import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';

import CocomoniDashboard from '@/views/pages/Common/CocomoniDashboard.vue';

export default {
  name: 'operator-cocomoni',
  components: {
    StackItem,
    ContentsBox,
    Spacer,
    CocomoniDashboard,
  },
  data() {
    return {
      flag: {
        loader: true,
        getAllData: false,
        getAllTypesOfWho5Answer: false,
      },
      schools: [],
      selectedSchoolId: '',
      selectedSchool: null,
    };
  },
  created() {
    this.getSchools();
  },
  computed: {
    ...mapState(['helper', 'user']),
    componentKey() {
      // 表示条件を切り替えた際にコンポーネントを再描画するために設定
      return `${this.selectedSchoolId}-${this.flag.getAllData}-${this.flag.getAllTypesOfWho5Answer}`;
    },
    showDashboard() {
      return this.selectedSchool || this.flag.getAllData;
    },
  },
  methods: {
    async getSchools() {
      const params = {
        includeDeleted: 1,
        isMainUser: 1,
        isUsers: 0,
      };
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/school/get/list',
          params,
        });
        this.schools = response.data.data.data;
      } catch (e) {
        alert('学校情報の取得に失敗しました');
        throw e;
      }
    },
    changeSchool() {
      this.selectedSchool = null; // 再描画のため
      this.$nextTick(() => {
        this.selectedSchool = this.schools.find((school) => school.id === this.selectedSchoolId);
      });
    },
    changeWho5Check(e) {
      this.flag.getAllTypesOfWho5Answer = e.target.checked;
    },
    changeAllDataCheck(e) {
      const checked = e.target.checked;
      if (checked) {
        // 学校選択値をクリア
        this.selectedSchoolId = '';
        this.selectedSchool = null;
      }
      this.flag.getAllData = checked;
    },
  },
};
</script>

<style lang="scss" module>
.searchArea {
  flex: 1;
  dl {
    display: flex;
    align-items: center;
    margin: 0;
  }
  dt {
    width: 9em;
    i {
      margin-right: .5em;
    }
  }
  dd {
    flex: 1;
    display: flex;
    margin-left: 2em;
    > div {
      display: flex;
      align-items: center;
      &:nth-child(2) {
        margin-left: 1em;
        // &:before {
        //   content: "~";
        //   margin-right: 1em;
        // }
      }
    }
    .select {
      display: flex;
      > dt {
        width: 9em;
      }
      > dd {
        margin-inline-start: 0;
        align-items: center;
      }
      &_box {
        width: 7rem;
        padding: 13px;
        background-color: var(--gray-sub);
        border: none;
        outline: none;
        border-radius: 8px;
        appearance: none;

        &_wrap {
          display: flex;

          li {
            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }
      }
    }
    .input {
      outline: none;
      border: none;
      background-color: var(--gray-sub);
      min-width: 75%;
      padding: 15px;
      border-radius: 45px;
    }
    .search_wrap {
      display: flex;
      .search_word {
        flex: 8.5;
      }
      .search_button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1.5;
      }
    }
  }
  @include sm-view {
    dl {
      display: block;
      margin: 1em 0 .4em;
    }
    dt {
      font-size: 14px;
    }
    dd {
      margin: 0;
    }
  }
}

.select_box {
  width: inherit !important;
}
</style>
