<template>
  <div>
    <ul :class="$style.guide">
      <li>
        <div :class="$style.guide_power">ココモニボイス
          <span>
            <Baloon
              text="ココモニボイスで入力した音声データからストレス反応を解析。あなた自身のこころの状態の傾向値を見える化します。"
              icon="<i class='fa-regular fa-circle-question'></i>" /></span>
        </div>
      </li>
      <li>
        <div :class="$style.guide_who5">ココモニチェック
          <span>
            <Baloon
              text="ココロのアンケート。ココロの健康診断やカウンセリングサービスのご利用時に回答した5つの質問から算出。<br>※同日に複数のココモニチェックを行った場合、最初に回答したスコアが表示されます"
              icon="<i class='fa-regular fa-circle-question'></i>"
              size="lg" /></span>
        </div>
      </li>
      <!-- <li>
        <div :class="$style.guide_annotation">ココロライン
          <span>
            <Baloon
              text="パワーメータの限界値。このラインを下回るときは、こころが疲れているサイン。リフレッシュやセルフケアを大切に。"
              icon="<i class='fa-regular fa-circle-question'></i>" /></span>
        </div>
      </li> -->
    </ul>
    <!-- <div
      v-if="annotationHover"
      :class="$style.balloon_sp">
      パワーメータの限界値。このラインを下回るときは、こころが疲れているサイン。リフレッシュやセルフケアを大切に。</div> -->
    <div :class="$style.outerGraph">
      <div :class="fromWhere === 'cocomoni'? $style.gradientbar_cocomoni: $style.gradientbar_home">
      </div>
      <!-- <div v-if="annotationHover" :class="$style.balloon"></div> -->
      <Line
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { Line } from 'vue-chartjs';
import annotationPlugin from 'chartjs-plugin-annotation';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import Baloon from '@/views/components/Baloon.vue';
import moment from 'moment';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement, annotationPlugin);

export default {
  name: 'BarChart',
  components: {
    Line,
    Baloon,
  },
  props: {
    fromWhere: {
      type: String,
      default: null,
    },
    power: {
      type: Array,
      default: null,
    },
    datetime: {
      type: Array,
      default: null,
    },
    who5: {
      type: Array,
      default: null,
    },
    labels: {
      type: Array,
      default: null,
    },
    chartId: {
      type: String,
      default: 'line-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: 'graphStyle',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
    who5DateTimes: {
      type: Array,
      default: null,
    },
  },
  data() {
    // デフォルトのプロット色
    const defaultPointColors = [];
    for (let i = 0; i < this.power.length; i += 1) defaultPointColors.push('rgb(245, 151,187)');
    const helper = this.$store.state.helper;
    return {
      cocoroLine: helper.master.datas.cocoroLine[helper.env.name],
      heightsize: 200,
      chartData: {
        labels: this.labels,
        datasets: [
          {
            label: 'ココモニボイス',
            yAxisID: 'power',
            backgroundColor: defaultPointColors,
            borderColor: 'rgb(245, 151,187)',
            data: this.power,
            datetime: this.datetime,
            radius: 5,
          },
          {
            label: 'ココモニチェック',
            yAxisID: 'cocomoniCheck',
            backgroundColor: 'rgb(126, 218, 186)',
            // borderWidth: 0,
            pointStyle: 'rect', // プロットする点が四角になる
            radius: 6, // プロットの大きさ
            data: this.who5,
            datetime: this.whoDateTimes,
          },
        ],
      },
      chartOptions: {
        spanGaps: true, // データが欠損している場合の挙動: 線を繋げる
        responsive: true,
        scales: {
          y: {
            suggestedMin: 0,
            suggestedMax: 12,
            ticks: { // y軸のラベルを非表示
              display: false,
            },
            grid: {
              drawBorder: false,
              display: true,
              tickColor: 'rgba(255, 255,255, 0)',
              drawTicks: false, // 一番左の線を非表示にはできるが、キャンバスサイズが小さくなるので結果グラデーションバーに対して被ってしまう
            },
          },
          'power': { // eslint-disable-line
            position: 'left',
            max: 12, // パワーメータの最大値
            min: 0,
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
          'cocomoniCheck': { // eslint-disable-line
            position: 'right',
            max: 30, // ココモニチェックの最大値
            min: -0.35, // ポイントがグラフからはみ出さないようにするための調整値
            ticks: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          // annotation: { // 基準線を引く
          //   annotations: {
          //     annotation1: null,
          //   },
          // },
          tooltip: {
            callbacks: {
              title: (context) => {
                const ctx = context[0];
                const i = ctx.dataIndex;
                if (ctx.dataset.yAxisID === 'cocomoniCheck') {
                  // ココモニチェックのtooltip内タイトル（回答日）
                  return ctx.label // 'YY/M/D'形式（例:'24/12/01'）
                    ? moment(`20${ctx.label}`).format('YYYY年MM月DD日 ')
                    : null;
                }
                // パワーメーターのtooltip内タイトル（計測日時）
                const datetime = ctx.dataset.datetime[i];
                return datetime;
              },
            },
          },
        },
      },
      yearData: [],
      ctx: null,
      annotationHover: false,
    };
  },
  watch: {
    labels(values) {
      this.chartData.labels = values;
      this.chartData.labels[0] = '';
    },
    power(values) {
      this.chartData.datasets[0].data = values;
      // グラフ変更時のcocoroLine未満プロット色 // MEMO: ココロラインの表示は廃止->色分けもしない
      values.forEach((value, i) => {
        // if (value) {
        //   if (value >= this.cocoroLine) {
        this.chartData.datasets[0].backgroundColor[i] = 'rgb(245, 151,187)';
        // } else {
        //   this.chartData.datasets[0].backgroundColor[i] = 'rgb(133, 194, 249)';
        // }
        // }
      });
    },
    // グラフ変更時のtooltip内タイトル（計測日時）
    datetime(values) {
      this.chartData.datasets[0].datetime = values;
    },
    who5DateTimes(values) {
      this.chartData.datasets[1].datetime = values;
    },
    who5(values) {
      this.chartData.datasets[1].data = values;
    },
  },
  created() {
    /** x軸の始点にはラベルを表示しないための処理 */
    this.chartData.labels[0] = '';
  },
  mounted() {
    // MEMO: ココロラインの表示は廃止

    // // ココロラインの値は環境別に動的設定
    // this.ctx = document.getElementById('line-chart');
    // const that = this;
    // this.chartOptions.plugins.annotation.annotations.annotation1 = {
    //   type: 'line',
    //   yMin: this.cocoroLine,
    //   yMax: this.cocoroLine,
    //   borderColor: 'rgb(133, 194, 249)',
    //   borderWidth: 4,
    //   enter() {
    //     that.annotationHover = true;
    //     // element.label.options.display = true; // elementを使用する時はenter({ element })で読み込む
    //     return true;
    //   },
    //   leave() {
    //     that.annotationHover = false;
    //     // element.label.options.display = false;
    //     return true;
    //   },
    // };

    // // ココロラインを下回った場合のプロット色変更
    // this.chartData.datasets[0].data.forEach((value, i) => {
    //   if (value) {
    //     if (value < this.cocoroLine) {
    //       // ココロラインを下回る場合は青に変更
    //       this.chartData.datasets[0].backgroundColor[i] = 'rgb(133, 194, 249)';
    //     }
    //   }
    // });
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  methods: {
  },
};
</script>

<style lang="scss" module>
.cocomoni_intro {
  text-align: center;
  margin-bottom: 10px;
  @include sm-view {
    font-size: .8em;
    width: 110%;
    left: -5%;
    position: relative;
    margin-bottom: 10px;
  }
}
.balloon_sp {
  width: auto;
  padding: 16px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  @include md-view-only {
    display: none;
  }
  @include lg-view-only {
    display: none;
  }
  @media screen and (max-width: 350px) {
    width: auto;
    font-size: 13px;
  }
}
.guide {
  display: block;
  text-align: right;
  &_power {
    display: inline;
  }
  &_power:before {
    font-size: 1em;
    content: '●';
    color: rgb(245, 151,187);
    position: relative;
    left: -5px;
  }
  &_power_detail {
    display: none;
    width: 300px;
    position: absolute;
    top: -280%;
    right: 0;
    padding: 16px;
    border-radius: 5px;
    background: var(--primary-color);
    color: #fff;
    text-align: left;
  }
  &_power_detail::after {
    position: absolute;
    width: 0;
    height: 0;
    right: 20%;
    bottom: -19px;
    margin-left: 10px;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: var(--primary-color);
    border-width: 10px;
    pointer-events: none;
    content: " ";
  }
  &_power:hover + &_power_detail {
    display: block;
  }
  &_who5 {
    display: inline;
    left: -2.7em;
  }
  &_who5:before {
    font-size: 1em;
    content: '■';
    color: rgb(126, 218, 186);
    position: relative;
    left: -5px;
  }
  &_who5_detail {
    display: none;
    width: 300px;
    position: absolute;
    top: -225%;
    right: 0;
    padding: 16px;
    border-radius: 5px;
    background: var(--primary-color);
    color: #fff;
    text-align: left;
  }
  &_who5_detail::after {
    position: absolute;
    width: 0;
    height: 0;
    right: 20%;
    bottom: -19px;
    margin-left: 10px;
    border: solid transparent;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: var(--primary-color);
    border-width: 10px;
    pointer-events: none;
    content: " ";
  }
  &_who5:hover + &_who5_detail {
    display: block;
  }
  &_annotation::before {
    content: '';
    position: relative;
    top: -3px;
    left: -3px;
    display: inline-block;
    width: 1em;
    height: 4px;
    background-color: rgb(133, 194, 249);
  }

}
.outerGraph {
  position: relative;
  left: 2.3vw;
  width: 95%;
  height: 100%;
}
.gradientbar_cocomoni {
  position: absolute;
  top: 5px;
  left: -1.4vw;
  z-index: 1;
  width: 3.1vw;
  max-width: 50px;
  height: calc(calc(102% - 5px) - 36px);
  background: linear-gradient(to top, rgb(133, 194, 249), rgb(249, 255, 159), rgb(255, 149, 164));
  border-radius: 8px 0 0 8px;
  @media screen and (min-width: 1801px) {
    left: -1.78vw;
  }
  @media screen and (max-width: 1800px) {
    left: -1.8vw;
  }
  @media screen and (max-width: 1750px) {
    left: -1.9vw;
  }
  @media screen and (max-width: 1670px) {
    left: -2vw;
  }
  @media screen and (max-width: 1550px) {
    left: -1.9vw;
  }
  @media screen and (max-width: 1380px) {
    left: -1.8vw;
  }
  @media screen and (max-width: 1250px) {
    left: -1.55vw;
  }
  @media screen and (max-width: 1080px) {
    width: 3.1vw;
  }
  @media screen and (max-width: 1052px) {
    left: -1.5vw;
  }
  @media screen and (max-width: 1000px) {
    width: 3.15vw;
    left: -1.4vw;
    height: calc(102% - 5px - 35px);
  }
  @media screen and (max-width: 950px) {
    left: -1.35vw;
  }
  @media screen and (max-width: 915px) {
    left: -1.3vw;
  }
  @media screen and (max-width: 880px) {
    width: 3.2vw;
    left: -1.3vw;
    height: calc(102% - 5px - 51px);
  }
  @media screen and (max-width: 870px) {
    left: -1.25vw;
  }
  @media screen and (max-width: 835px) {
    left: -1.2vw;
  }
  @media screen and (max-width: 800px) {
    width: 3.2vw;
    left: -1.1vw;
  }
  @media screen and (max-width: 783px) {
    left: -1.08vw;
  }
  @media screen and (max-width: 757px) {
    left: -0.95vw;
  }
  @media screen and (max-width: 750px) {
    width: 3.4vw;
  }
  @media screen and (max-width: 700px) {
    width: 3.5vw;
    left: -1.1vw;
    height: calc(100% - 5px - 47px);
  }
  @media screen and (max-width: 691px) {
    left: -0.98vw;
  }
  @media screen and (max-width: 650px) {
    width: 3.5vw;
    left: -0.93vw;
    height: calc(100% - 5px - 49px);
  }
  @media screen and (max-width: 646px) {
    left: -0.9vw;
  }
  @media screen and (max-width: 646px) {
    left: -0.82vw;
  }
  @media screen and (max-width: 697px) {
    left: -0.78vw;
  }
  @media screen and (max-width: 590px) {
    width: 3.7vw;
    height: calc(100% - 55px);
  }
  @media screen and (max-width: 567px) {
    left: -0.76vw;
    height: calc(100% - 58px);
  }
  @media screen and (max-width: 558px) {
    left: -0.74vw;
  }
  @media screen and (max-width: 550px) {
    left: -0.6vw;
  }
  @media screen and (max-width: 533px) {
    height: calc(calc(100% - 5px) - 53px);
    left: -0.6vw;
  }
  @media screen and (max-width: 527px) {
    left: -0.5vw;
  }
  @media screen and (max-width: 518px) {
    left: -0.45vw;
  }
  @media screen and (max-width: 500px) {
    left: -0.4vw;
    height: calc(100% - 60px);
  }
  @media screen and (max-width: 495px) {
    left: -0.36vw;
  }
  @media screen and (max-width: 486px) {
    left: -.35vw;
  }
  @media screen and (max-width: 482px) {
    left: -0.3vw
  }
  @media screen and (max-width: 477px) {
    left: -0.27vw;
  }
  @media screen and (max-width: 477px) {
    left: -0.25vw;
  }
  @media screen and (max-width: 465px) {
    left: -0.21vw;
  }
  @media screen and (max-width: 461px) {
    left: -0.1vw;
  }
  @media screen and (max-width: 453px)  {
    height: calc(calc(100% - 5px) - 54px);
    left: 0.2vw;
  }
  @media screen and (max-width: 415px) {
    left: 0.3vw;
  }
  @media screen and (max-width: 408px) {
    left: 0.4vw;
  }
  @media screen and (max-width: 400px) {
    left: .5vw;
  }
  @media screen and (max-width: 400px) {
    left: .7vw;
  }
  @media screen and (max-width: 370px) {
    left: .85vw;
  }
  @media screen and (max-width: 360px) {
    left: .9vw;
  }
  @media screen and (max-width: 351px) {
    left: 1.2vw;
  }
  @media screen and (max-width: 330px) {
    left: 1.5vw;
  }
}
.gradientbar_home {
  position: absolute;
  top: 5px;
  left: -.7vw;
  z-index: 1;
  width: 1.8vw;
  height: calc(calc(102% - 5px) - 54px);
  background: linear-gradient(to top, rgb(133, 194, 249), rgb(249, 255, 159), rgb(255, 149, 164));
  border-radius: 8px 0 0 8px;
  @media screen and (min-width: 1801px) {
    left: -0.9vw;
  }
  @media screen and (max-width: 1800px) {
    left: -0.85vw;
  }
  @media screen and (max-width: 1750px) {
    left: -0.83vw;
  }
  @media screen and (max-width: 1700px) {
    left: -0.8vw;
  }
  @media screen and (max-width: 1620px) {
    left: -0.75vw;
  }
  @media screen and (max-width: 1510px) {
    left: -0.65vw;
  }
  @media screen and (max-width: 1470px) {
    left: -0.53vw;
  }
  @media screen and (max-width: 1450px) {
    left: -0.5vw;
  }
  @media screen and (max-width: 1270px) {
    left: -0.45vw;
  }
  @media screen and (max-width: 1230px) {
    left: -0.4vw;
  }
  @media screen and (max-width: 1165px) {
    left: -0.35vw;
  }
  @media screen and (max-width: 1100px) {
    left: -0.3vw;
  }
  @media screen and (max-width: 1080px) {
    width: 3.1vw;
    height: calc(102% - 42px);
    left: -1.4vw;
  }
  @media screen and (max-width: 1000px) {
    width: 3.15vw;
    left: -1.4vw;
    height: calc(102% - 5px - 35px);
  }
  @media screen and (max-width: 880px) {
    width: 3.2vw;
    left: -1.3vw;
    height: calc(102% - 5px - 35px);
  }
  @media screen and (max-width: 820px) {
    width: 3.2vw;
    height: calc(102% - 56px);
    left: -1.1vw;
  }
  @media screen and (max-width: 750px) {
    width: 3.4vw;
  }
  @media screen and (max-width: 700px) {
    width: 3.5vw;
    left: -0.95vw;
    height: calc(100% - 5px - 47px);
  }
  @media screen and (max-width: 650px) {
    width: 3.5vw;
    left: -0.93vw;
    height: calc(100% - 5px - 49px);
  }
  @media screen and (max-width: 650px) {
    left: -0.9vw;
  }
  @media screen and (max-width: 607px) {
    left: -0.8vw;
  }
  @media screen and (max-width: 600px) {
    left: -0.7vw;
  }
  @media screen and (max-width: 590px) {
    width: 3.7vw;
    height: calc(100% - 5px - 50px);
    left: -.7vw;
  }
  @media screen and (max-width: 541px) {
    left: -0.5vw;
  }
  @media screen and (max-width: 533px) {
    height: calc(calc(100% - 5px) - 53px);
    left: -0.3vw;
  }
  @media screen and (max-width: 479px) {
    left: -0.1vw;
  }
  @media screen and (max-width: 453px)  {
    height: calc(calc(100% - 5px) - 54px);
    left: .4vw;
  }
  @media screen and (max-width: 400px) {
    left: 1vw;
  }
  @media screen and (max-width: 360px) {
    left: 1.2vw;
  }
}
.balloon_cocomoni {
  width: 300px;
  position: absolute;
  z-index: 3;
  bottom: 39%;
  left: 30%;
  padding: 16px;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  text-align: left;
  @media screen and (max-width: 885px) {
    bottom: 45%;
  }
  @include sm-view {
    // display: none;
    left: -5%;
  }
}
.balloon_cocomoni::after {
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  right: 50%;
  margin-left: 10px;
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: var(--primary-color);
  border-width: 10px;
  pointer-events: none;
  content: " ";
}
.balloon_home {
  width: 300px;
  position: absolute;
  z-index: 3;
  bottom: 50%;
  left: 30%;
  padding: 16px;
  border-radius: 5px;
  background: var(--primary-color);
  color: #fff;
  text-align: left;
  @media screen and (max-width: 1080px) {
    bottom: 40%
  }
  @media screen and (max-width: 820px) {
    bottom: 45%
  }
  @include sm-view {
    // display: none;
    left: -5%;
  }
}
.balloon_home::after {
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  right: 50%;
  margin-left: 10px;
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-top-color: var(--primary-color);
  border-width: 10px;
  pointer-events: none;
  content: " ";
}
</style>

<style lang="scss">
.graphStyle {
  position: relative;
  left: 9px;
  z-index: 2;
  @media screen and (max-width: 700px) {
    left: 8px;
  }
  @media screen and (max-width: 700px) {
    left: 9px;
  }
}
</style>
