<template>
  <basic-box
    id="register-cocomoni-check-container"
    attention="true"
    borderColor="blue"
  >
    <div
      v-if="!isCompleteMonitoring"
      :class="$style.monitoringContainer"
    >
      <!-- 進捗を6ステップで表示し、各フェーズにクリックで移動もできるようにする -->
      <div :class="$style.phaseContainer">
        <div
          v-for="(phase, index) in phases"
          :key="phase.id"
          :class="[
            $style.phase,
            index === currentPhaseIndex ? $style.current : '',
            index !== currentPhaseIndex && (index < currentPhaseIndex || monitoringAnswers[index] !== undefined || (isPhaseActive && monitoringAnswers.length === phases.length - 1)) ? $style.past : '',
          ]"
          @click="clickPhase(index)"
        >
          <p>{{ phase.name }}</p>
        </div>
      </div>
      <spacer :y="3" />
      <div
        :class="[
          $style.monitoringBody,
          !isPhaseActive ? $style.inactive : '',
        ]"
      >
        <template
          v-if="currentPhaseIndex !== phases.length - 1"
        >
          <!-- 設問 -->
          <p>Q{{ currentPhaseIndex + 1 }}：{{ helper.master.who5.items[currentPhaseIndex].label }}</p>
          <spacer :y="3" />
          <div
            :key="currentPhaseIndex"
            :class="$style.choices"
          >
            <div
              v-for="(option, index) in helper.master.who5.options"
              :key="index"
              :class="[
                $style.clickable,
                $style[`option${index + 1}`],
                monitoringAnswers[currentPhaseIndex] === option.value ? $style.selected : null,
              ]"
              @click="clickMonitoringAnswer(option.value)"
            ><span>{{ option.label }}</span></div>
          </div>

        </template>
        <template v-else>
          <!-- 確認画面 -->
          <div :class="$style.monitoringConfirm">
            <div>
              <div
                v-for="(who5Items, index) in helper.master.who5.items"
                :key="index"
              >
                <p>Q{{ index + 1 }}：{{ who5Items.label }}</p>
                <p>{{ helper.master.who5.options.find((row) => row.value === monitoringAnswers[index]).label }}</p>
              </div>
            </div>
          </div>
          <spacer :y="3" />
          <btn-container>
            <basic-btn
              tag="button"
              @click="submitWho5answer()"
            >回答する</basic-btn>
          </btn-container>
        </template>
      </div>
    </div>

    <template v-else>
      <spacer :y="1" />
      <p :class="$style.text_after_register">ご回答ありがとうございました！</p>
      <spacer :y="3" />
      <btn-container>
        <a href="/?tab=cocomoni#graph">
          <basic-btn
            tag="button"
            @click="gotoCocomoni()"
          >回答データを見る</basic-btn>
        </a>
      </btn-container>
    </template>

    <spacer :y="1" />
  </basic-box>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBox from '@/views/components/BasicBox.vue';
import Spacer from '@/views/components/Spacer.vue';

export default {
  name: 'register-cocomoni-check',
  components: {
    BasicBtn,
    BtnContainer,
    BasicBox,
    Spacer,
  },
  props: {
    monitoringSchedule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      phases: [
        { id: 1, name: 'Q1' },
        { id: 2, name: 'Q2' },
        { id: 3, name: 'Q3' },
        { id: 4, name: 'Q4' },
        { id: 5, name: 'Q5' },
        { id: 6, name: '確認' },
      ],
      currentPhaseIndex: 0,
      isPhaseActive: true,
      isCompleteMonitoring: false,
      isProcessing: false,
      monitoringAnswers: [],
    };
  },
  computed: {
    ...mapState(['helper', 'user']),
  },
  methods: {
    clickMonitoringAnswer(val) {
      if (!this.isPhaseActive) {
        return;
      }
      this.isPhaseActive = false;
      this.monitoringAnswers[this.currentPhaseIndex] = val;
      // フェーズを切り替える
      setTimeout(() => {
        if (this.monitoringAnswers.length === this.phases.length - 1) {
          // すべての質問に回答済みの場合は確認画面に遷移
          this.currentPhaseIndex = this.phases.length - 1;
        } else {
          // 次の質問に進む
          this.currentPhaseIndex += 1;
        }
        this.$nextTick(() => {
          this.isPhaseActive = true;
        });
      }, 650);
    },
    clickPhase(index) {
      if (this.monitoringAnswers[index] !== undefined) {
        this.currentPhaseIndex = index;
      }
      if (this.isPhaseActive && this.monitoringAnswers.length === this.phases.length - 1) {
        this.currentPhaseIndex = index;
      }
    },
    /** ココモニ回答を登録する */
    async submitWho5answer() {
      if (this.isProcessing) {
        return;
      }
      this.isProcessing = true;
      this.showLoading();
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/who5answer/set/register',
          data: {
            type: 4, // モニタリング
            foreign_id: this.monitoringSchedule.id,
            user_id: this.user.id,
            answers: this.monitoringAnswers,
            school_year_at_answering: this.user.school_year,
          },
        });
        this.completeAnswerMonitoring();
      } catch (e) {
        alert('ココモニチェック回答の登録に失敗しました');
        this.hideLoading();
        throw e;
      } finally {
        this.isProcessing = false;
      }
    },
    completeAnswerMonitoring() {
      this.isPhaseActive = false;
      setTimeout(() => {
        this.isCompleteMonitoring = true;
        this.$nextTick(() => {
          this.hideLoading();
          // コンポーネントトップにスクロール
          const options = {
            duration: 200,
          };
          if (window.innerWidth <= 375) { // スマホだったらスクロール位置を少し上にする
            options.offset = -180;
          } else {
            options.offset = -210;
          }
          this.$scrollTo('#register-cocomoni-check-container', options);
        });
      }, 750);
    },
    gotoCocomoni() {
      this.$emit('register-complete'); // 親コンポーネントに通知
    },
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.monitoringContainer {
  background-color: white !important; // なぜか効かない

  .phaseContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .phase {
      width: 45px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--gray-main);
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      white-space: nowrap;
      &.done {
        background-color: var(--blue-main);
        cursor: pointer;
      }
      &.current {
        background-color: var(--orange-main);
      }
      &.past {
        background-color: var(--blue-main);
        cursor: pointer;
      }
    }
    .phase + .phase {
      margin-left: 20px;

      @include sm-view {
        margin-left: 12px;
      }
    }
  }

  .monitoringBody {
    animation: fadeIn .65s ease-out;
    text-align: center;
    &.inactive {
      // ふわっと消す
      animation: fadeOut .65s ease-out;
    }
    @keyframes fadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    @keyframes fadeOut {
      from { opacity: 1; }
      to { opacity: 0; }
    }

    >p {
      font-size: 20px;
      margin-top: 20px;
    }
    .choices {
      // アニメーション: viewに要素が登場したらふわっと表示
      @keyframes fadeIn {
        0% {
          opacity: 0;
          transform: translateY(50px);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      >div {
        margin: 0 auto;
        width: 80%;
        height: 50px;
        border: 1px solid var(--gray-main);
        border-radius: 10px;
        cursor: pointer;
        transition: all .4s;

        @media (hover: hover) { /* ホバーが有効なデバイス (マウス操作: スマホは対象外) */
          &:hover {
            transform: scale(1.1); // ホバー時に拡大する
            // 選択肢ごとに色を変える
            &.option {
              &1 {
                background-color: rgba(var(--orange-main-rgb), 9) !important;
              }
              &2 {
                background-color: rgba(var(--orange-main-rgb), .75) !important;
              }
              &3 {
                background-color: rgba(var(--orange-main-rgb), .6) !important;
              }
              &4 {
                background-color: rgba(var(--orange-main-rgb), .45) !important;
              }
              &5 {
                background-color: rgba(var(--orange-main-rgb), .3) !important;
              }
              &6 {
                background-color: rgba(var(--orange-main-rgb), .15) !important;
              }
            }
          }
        }

        &.clickable {
          animation: animScale 4s infinite ease-out;
          transform-origin: 50% 50%;
          animation-play-state: running;
        }
        &.selected {
          background-color: var(--blue-sub);
        }
        // 前の要素との間隔
        margin-top: 20px;
        >span {
          display: block;
          line-height: 50px;
          font-size: 18px;
        }
      }
    }
  }
  .monitoringConfirm {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // 子要素のdiv内は左詰め
    >div {
      max-width: 28rem;
      >div {
        text-align: left;
        margin-bottom: 20px;
        font-size: 20px;
        // 先頭pは小さく
        p:first-of-type {
          font-size: 14px;
        }
      }
    }
  }
}

.text_after_register {
  font-style: bold;
  font-size: 20px;
  text-align: center;
}
</style>
