<template>
  <v-date-picker
    v-model="date"
    timezone=""
    :mode="type"
    :masks="{
      title: 'YYYY年 MM月',
    }"
    :min-date="minDate"
    :max-date="maxDate"
    locale="ja-JP"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input
        :class="className"
        :value="inputValue"
        v-on="inputEvents"
      />
    </template>
  </v-date-picker>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    name: {
      type: String,
    },
    myDate: {
      type: String,
    },
    type: {
      type: String,
      default: 'date',
    },
    size: {
      type: String,
      default: 'md',
      validator: (value) => ['md', 'sm'].includes(value),
    },
    minDate: { // 選択可能な最小日の明示指定
      type: Object, // Date || String
      default: null,
    },
    maxDate: { // 選択可能な最大日の明示指定
      type: Object, // Date || String
      default: null,
    },
  },
  computed: {
    className() {
      return `${this.$style.text} ${this.$style[this.size]}`;
    },
  },
  data() {
    return {
      timezone: '',
      date: null,
    };
  },
  created() {
    if (this.myDate) this.date = this.myDate;
  },
  watch: {
    date() {
      const fmt = this.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss';
      this.$emit('sendDate', {
        name: this.name,
        date: moment(this.date).format(fmt),
      });
    },
  },
};
</script>

<style lang="scss" module>
.text {
  width: 100%;
  padding: 13px;
  border-radius: 8px;
  background-color: var(--gray-sub);
  border: none;
  outline: none;
  $background: var(--gray-sub);
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px $background inset;
  }
  &.plane {
    font-size: 14px;
    background-color: transparent;
  }
  &.sm {
    width: 9rem;
    padding: 6px;
    font-size: 14px;
  }
}

</style>
