<template>
  <div>
    <spacer :y="5"/>
    <ul>
      <stack-item>
        <contents-box title="予約一覧">
          <loader-simple
            :flag="flag.loader"
            v-if="flag.loader" />
          <div v-if="!flag.loader">
            <p v-if="uncheckedCount">未確認のオンライン・バーチャル健康相談が <span :class="$style.bold">{{ uncheckedCount }}件 </span>あります</p>
            <p v-if="!uncheckedCount">未確認のオンライン・バーチャル健康相談はありません。</p>

            <spacer :y="2" v-if="user.counseling.op.reserve.future.length"/>
            <ul>
              <stack-item
                v-for="row in user.counseling.op.reserve.future"
                :key="row.id"
                :class="$style.new_wrap"
                :id="`reserve_${row.id}`">
                <contents-box
                  type="inner" :title="formatTimestamp(row.schedule.date, `YYYY年MM月DD日（${row.weekday.label}）HH:00`)"
                  icon="<i class='fa-solid fa-user-doctor'/>">
                  <spacer :y="1"/>
                  <user-box
                    type="inner"
                    :user="row.fromUser"
                    :ownUser="user"
                    :showMessageBtn="true"
                    :showCocomoniBtn="true"
                    :showId="true"
                    :bg="userBoxColor(row.fromUser)"
                    :link="`/operator/history/${row.fromUser.id}`"
                    :targetBlank="true">
                    <template v-slot:btn>
                      <div :class="[$style.clickarea, $style.open]" v-on:click="changeOpenIds(row.id)">
                        <i
                          v-if="openIds.includes(row.id)"
                          :class="$style.icon"
                          class="fa-regular fa-minus"></i>
                        <i
                          v-if="!openIds.includes(row.id)"
                          :class="$style.icon"
                          class="fa-regular fa-plus"></i>
                      </div>
                    </template>
                    <template v-slot:main>
                      <div>
                        <spacer :y="2"/>
                        <ul :class="$style.detail">
                          <li>
                            <label-box label="担当" color="gray" size="sm" :class="$style.label"/>
                            <p :class="[$style.content, $style.counseling_wrap]">
                              {{ row.toUser.username }}（{{ helper.master.labels.users.account_type[row.toUser.account_type] }}）
                              <i
                                v-if="row._counselingType.counseling_type === 1"
                                class="fa-regular fa-laptop"
                                :class="[
                                  $style.counseling_icon,
                                  $style.online,
                                ]"
                              ><i :class="$style.counseling_icon_inner" class="fa-regular fa-user-headset"/></i>
                              <i
                                v-if="row._counselingType.counseling_type === 3"
                                class="fa-solid fa-vr-cardboard"
                                :class="[
                                  $style.counseling_icon,
                                  $style.virtual,
                                ]"
                              ></i>
                            </p>
                          </li>
                          <li>
                            <label-box label="申込日" color="gray" size="sm" :class="$style.label"/>
                            <p :class="$style.content">{{ formatTimestamp(row.created_at, 'YYYY/MM/DD HH:mm') }}</p>
                          </li>
                        </ul>
                      </div>
                      <div
                        :class="$style.drawer"
                        v-if="openIds.includes(row.id)">
                        <ul :class="$style.detail">
                        <spacer :y="1.5"/>
                          <li>
                            <label-box label="実施日" color="gray" size="sm" :class="$style.label"/>
                            <p :class="$style.content">{{ formatTimestamp(row.schedule.date, 'YYYY/MM/DD HH:00〜HH:45') }}</p>
                          </li>
                          <li v-if="isOnline(row) && row.meeting">
                            <label-box label="URL" color="gray" size="sm" :class="$style.label"/>
                            <p :class="$style.content"><a :href="row.meeting.join_url" target="_blank">{{ row.meeting.join_url }}</a></p>
                          </li>
                          <li v-if="isVirtual(row)">
                            <label-box label="URL" color="gray" size="sm" :class="$style.label"/>
                            <p :class="$style.content"><a :href="getVirtualCastMedicalRooomURL(row.toUser.virtualCastProp.virtual_cast_room_key)" target="_blank">{{ getVirtualCastMedicalRooomURL(row.toUser.virtualCastProp.virtual_cast_room_key) }}</a></p>
                          </li>
                          <li v-if="isVirtual(row)">
                            <label-box label="Virtual Cast ID" color="gray" size="sm" :class="$style.label"/>
                            <div>
                              <p :class="$style.content">{{ row.fromUser.virtualCastProp.virtual_cast_id }}</p>
                              <p :class="$style.virtualcast_url"><a :href="getVirtualCastUserURL(row.fromUser.virtualCastProp.virtual_cast_id)" target="_branc">{{ getVirtualCastUserURL(row.fromUser.virtualCastProp.virtual_cast_id) }}</a></p>
                            </div>
                          </li>
                          <li>
                            <label-box label="お悩み" color="gray" size="sm" :class="$style.label"/>
                            <div :class="[$style.value, $style.type]">
                              <p
                                v-for="rType in row.type"
                                :key="rType">{{ helper.master.labels.reserve.type[rType] }}<span v-if="row.other_text && rType === 6">（{{ row.other_text }}）</span></p>
                            </div>
                          </li>
                          <li v-if="row.memos.customer.length">
                            <label-box label="自由欄" color="gray" size="sm" :class="$style.label"/>
                            <p :class="$style.content">{{ row.memos.customer[0].memo }}</p>
                          </li>
                          <li>
                            <label-box label="ココモニ" color="gray" size="sm" :class="$style.label"/>
                            <div :class="$style.content">
                              <p :class="$style.who5_score"><span :class="$style.who5_score_label">ココモニチェック</span>{{ row.scoreWho5 }}点</p>
                              <ul :class="$style.who5">
                                <li v-for="(item, i) in helper.master.who5.items" :key="item">
                                  <p>{{ item.label }}</p>
                                  <p :class="$style.who5_answer">{{ helper.master.who5.labels[row.who5Answer.answers[i]] }}</p>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="n2br">
                            <label-box :label="isCompany(row) ? '企業コメント' : '学校コメント'" color="gray" size="sm" :class="$style.label"/>
                            <p
                              v-if="row.memos.teacher.length"
                              v-html="row.memos.teacher[0].memo"></p>
                            <p v-if="!row.memos.teacher.length">記入なし</p>
                          </li>
                          <li class="n2br">
                            <label-box label="事務局メモ" color="gray" size="sm" :class="$style.label"/>
                            <p
                              :class="$style.content"
                              v-if="row.memos.operator.length && editId !== row.id"
                              v-html="row.memos.operator[0].memo"/>
                            <p :class="$style.content" v-else-if="row.id !== editId">登録なし</p>
                            <div v-else :class="$style.textarea_wrap">
                              <textarea
                                name="memo"
                                id="memo"
                                rows="5"
                                :class="$style.textarea"
                                v-model="memo"/>
                              <spacer :y="1"/>
                              <btn-container>
                                <basic-btn
                                  tag="button"
                                  size="sm"
                                  v-on:click="registMemo(row)">保存</basic-btn>
                                  <spacer :x="2"/>
                                <basic-btn
                                  tag="button"
                                  size="sm"
                                  type="bdr"
                                  v-on:click="editStart(null)">やめる</basic-btn>
                              </btn-container>
                            </div>
                            <i
                              v-if="row.id !== editId"
                              class="fa-regular fa-pen-to-square"
                              :class="$style.editIcon"
                              v-on:click="editStart(row)"/>
                          </li>
                        </ul>
                      </div>
                    </template>
                  </user-box>
                </contents-box>
                <div
                  :class="$style.new"
                  v-if="user.readflags && !user.readflags.reserves.includes(row.id)">NEW</div>
              </stack-item>
            </ul>
          </div>
        </contents-box>
      </stack-item>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StackItem from '@/views/components/StackItem.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import UserBox from '@/views/components/UserBox.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import BtnContainer from '@/views/components/BtnContainer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import Spacer from '@/views/components/Spacer.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'operator-online-counseling',
  mixins: [cf],
  components: {
    StackItem,
    ContentsBox,
    UserBox,
    LabelBox,
    LoaderSimple,
    BtnContainer,
    BasicBtn,
    Spacer,
  },
  data() {
    return {
      flag: {
        loader: true,
      },
      // reserves: [],
      openIds: [],
      editId: null,
      memo: null,
      uncheckedCount: 0,
      reserveCounselingTypes: {}, // key: reserve_id, value: counseling_type
    };
  },
  created() {
    // Operator/Home.vueでユーザ情報取得の後
    // componentを呼び出しているためthis.userは
    // すぐに取得できる
    this.getReserves();
  },
  computed: {
    ...mapState(['helper', 'user']),
  },
  mounted() {
    // storeを監視して未確認予約件数をカウントし直し
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'user/update') {
        this.countUnchecked();
      }
    });
  },
  methods: {
    isCompany(row) {
      return Boolean(row && row.fromUser && row.fromUser.school.length && row.fromUser.school[0].is_company);
    },
    // 該当予約までスクロール
    scroll2OpenItem() {
      // クエリでidが渡ってきたとき（ホームのステータスから飛んだとき）
      // this.openIdsにidを格納しその予約までスクロール
      const query = this.$route.query;
      if (query && query.open) {
        const id = Number(query.open);
        this.changeOpenIds(id);
        this.$nextTick(() => {
          const elem = document.getElementById(`reserve_${id}`);
          const position = elem.getBoundingClientRect().top;
          scrollBy(0, position - 77);
        });
      }
    },
    getReserves() {
      if (this.user.counseling.op.reserve.future.length) {
        // すでにstoreへデータが登録されている場合は終了
        this.flag.loader = false;
        return;
      }

      const params = {
        flags: [1],
        isRisk: 1,
        counseling_type: -1, // オンライン・バーチャル
      };
      this.axios({
        method: 'GET',
        url: '/v1/reserve/get/list',
        // url: '/v2/reserve/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          const commitData = {
            type: 'reserve',
            division: 'future',
            values: cf.upSortReserves(res.reserves.data),
          };
          this.$store.commit('user/counseling/putOperatorDatas', commitData);
          this.countUnchecked();
          this.scroll2OpenItem();
          this.setReserveCounselingType();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    countUnchecked() {
      this.uncheckedCount = 0;
      this.user.counseling.op.reserve.future.forEach((row) => {
        if (this.user.readflags && !this.user.readflags.reserves.includes(row.id)) {
          this.uncheckedCount += 1;
        }
      });
      this.flag.loader = false;
    },
    changeOpenIds(n) {
      if (this.user.readflags && !this.user.readflags.reserves.includes(n)) this.registReadFlag(n);
      if (this.openIds.includes(n)) {
        const idx = this.openIds.indexOf(n);
        this.openIds.splice(idx, 1);
      } else {
        this.openIds.push(n);
      }
    },
    registReadFlag(reserveId) {
      const data = {
        model_type: 3, // オンライン健康相談予約
        user_id: this.user.id,
        foreign_id: reserveId,
      };
      this.axios({
        method: 'POST',
        url: '/v1/readflag/set/register',
        data,
      })
        .then(() => {
          this.$store.dispatch('user/update');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
    editStart(reserve) {
      if (reserve) {
        this.editId = reserve.id;
        if (reserve.memos.operator.length) this.memo = reserve.memos.operator[0].memo;
        else this.memo = null;
      } else {
        this.editId = null;
        this.memo = null;
      }
    },

    /** userBox背景色指定用 */
    userBoxColor(user) {
      let color;
      if ((user.flag !== 1 || user.school) && user.school.length && user.school[0].flag !== 1) {
        color = 'gray';
      } else if (user.flag !== 1) {
        color = 'gray';
      } else if (user.temporary) {
        color = 'silver';
      } else if (user.risk.length && user.risk[user.risk.length - 1].type !== 11) {
        color = 'red';
      } else {
        color = 'white';
      }
      return color;
    },

    registMemo(row) {
      const data = {
        id: row.memos.operator.length ? row.memos.operator[0].id : null,
        memo: this.memo,
        user_id: this.user.id,
        reserve_id: row.id,
      };
      const endpoint = data.id ? 'updater' : 'register';
      this.axios({
        method: 'POST',
        url: `/v1/reserve/memo/set/${endpoint}`,
        data,
      })
        .then(() => {
          this.editId = null;
          this.memo = null;
          this.getReserves();
          alert('メモを更新しました。');
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    isOnline(reserve) {
      return reserve._counselingType.counseling_type === 1;
    },
    isVirtual(reserve) {
      return reserve._counselingType.counseling_type === 3;
    },
    setReserveCounselingType() {
      this.reserves.forEach((reserve) => {
        this.reserveCounselingTypes[reserve.id] = reserve.type.counseling_type;
      });
    },
    cancelReserve(reserve) {
      const args = {
        modalName: 'reserveCancel',
        data: {
          reserve_id: reserve.id,
          counseling_type: this.reserveCounselingTypes[reserve.id],
          schedule: reserve.schedule,
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: null });
    },
  },
};
</script>

<style lang="scss" module>
.list {
  display: flex;
  align-items: center;
  cursor: pointer;

  &_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.label {
  width: 105px;
  margin-right: 20px;
  // .content {
  //   .who5 {
  //     .who5_answer {
  //       font-weight: bold;
  //     }
  //   }
  // }
}
.text_wrap {
  margin-left: 15px;
}
.id {
  font-size: 12px;
}
.clickarea {
  cursor: pointer;
}
.open {
  text-align: right;
  @include sm-view {
    margin-top: 10px;
  }
}
.icon {
  font-size: 20px;
  color: var(--gray-side);
}
.detail {
  li {
    display: flex;
    align-items: flex-start;

    @include sm-view {
      font-size: 13px;
      display: block;
    }
    .content {
      // font-size: 14px;
      margin-top: 3px;
      flex: 1;
      word-break: break-all;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
.who5 {
  margin-top: 16px;
  li {
    justify-content: space-between;
  }
  .who5_answer {
    font-weight: bold;
  }
  &_score {
    &_label {
      margin-right: 12px;
    }
  }
}
.types {
  margin-right: 20px;
}
.textarea {
  background-color: var(--gray-sub);
  padding: 13px;
  border: none;
  border-radius: 4px;
  width: 100%;
  outline: none;
  &_wrap {
    flex: 1;
  }
  @include sm-view {
    margin-top: 5px;
  }
}
.editIcon {
  margin-left: 6px;
  margin-top: 6px;
}
.new {
  position: absolute;
  background-color: var(--pink);
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 4px;
  top: -3px;
  right: -3px;
  &_wrap {
    position: relative;
  }
}
.bold {
  font-weight: bold;
}
.value {
  flex: 1;
  margin-top: 3px;
  &.type {
    display: flex;
    flex-wrap: wrap;
    p {
      white-space: nowrap;
      &:not(:last-child) {
        &::after {
          margin: 0 5px;
          content: '/';
        }
      }
    }
  }
}
.virtualcast_url {
  font-size: 10px;
}
.counseling {
  display: flex;
  align-items: center;
  &_wrap {
    display: flex;
    align-items: center;
  }
  &_icon {
    font-size: 25px !important;
    position: relative;
    &_inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      font-size: 10px !important;
    }
    &.online {
      color: var(--orange-main);
    }
    &.virtual {
      color: var(--green-main);
    }
  }
}
</style>
